import { Box, Dialog, Typography, SxProps, Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { LoadingButton } from '@mui/lab'

type Props = {
  title: string
  labels?: {
    cancel?: string
    confirm?: string
  }
  open: boolean
  sx?: {
    container?: SxProps
  }
  withCancel?: boolean
  loading?: boolean
  handleClose: () => void
  handleConfirm?: () => void
  children?: React.ReactNode | React.ReactNode[]
}

export default function CustomDialog(props: Props) {
  return (
    <Dialog 
      onClose={props.handleClose} 
      open={props.open} 
      sx={props.sx?.container ?? {
        '.MuiPaper-root': {
          minWidth: '40rem',
          maxWidth: '40rem'
        }
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        margin={'2rem'}
        gap={'1rem'}
      >
        <Box 
          display={'flex'} 
          sx={{ flexDirection: 'column' }}
        >
          <ClearIcon
            onClick={() => props.handleClose()}
            sx={{
              color: '#202020',
              opacity: '0.5',
              '&:hover': {
                cursor: 'pointer',
                borderRadius: '50%',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                animationDuration: '1s',
                animationTimingFunction: 'ease-in-out',
                animationName: 'rotateAnim'
              },
              marginLeft: 'auto'
            }}
          />
          <Typography 
            fontFamily={'AvenirHeavy'}
            fontSize={'1.125rem'}
            color={'rgb(32, 32, 32)'}
          >
            {props.title}
          </Typography>
        </Box>
        {props.children}
        {
          (props.withCancel || props.handleConfirm) &&
          <Box 
            display={'flex'}
            height={'2.25rem'}
            justifyContent={'flex-end'}
            gap={'.5rem'}
            marginTop={'.5rem'}
          >
            {
              props.withCancel && <Button
                variant={'outlined'}
                onClick={() => props.handleClose()}
                sx={{
                  color: 'rgb(2, 13, 50)',
                  borderColor: 'rgb(2, 13, 50)',
                  fontSize: '.875rem',
                  fontFamily: 'AvenirMedium',
                  letterSpacing: '0.26',
                  textTransform: 'none',
                  width: '10rem',
                  marginLeft: 'auto',
                  height: '100%'
                }}
              >
                {props.labels?.cancel}
              </Button>
            }
            {
              props.handleConfirm && <LoadingButton
                variant={'contained'}
                disableElevation
                loading={props.loading}
                onClick={() => props.handleConfirm?.()}
                sx={{
                  color: 'white',
                  fontSize: '.875rem',
                  fontFamily: 'AvenirHeavy',
                  letterSpacing: '0.26',
                  textTransform: 'none',
                  width: '10rem',
                  height: '100%'
                }}
              >
                {props.labels?.confirm}
              </LoadingButton>
            }
          </Box>
        }
        
      </Box>
    </Dialog>
  )
}