import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

type Props = {
  open: boolean
  url: string
  selected?: boolean
  icon: React.ReactNode
  text: string
}

export default function DrawerItem(props: Props) {
  const navigate = useNavigate()

  return (
    <ListItem
      disablePadding
      sx={{ 
        display: 'block', 
        marginInline: props.open ? '16px' : '8px',
        width: 'auto'
      }}
      onClick={() => navigate(props.url)}
    >
      <ListItemButton
        selected={props.selected}
        sx={{
          minHeight: 20,
          justifyContent: props.open ? 'initial' : 'center',
          px: 2.5,
          color: 'white',
          padding: props.open ? '.25rem 1rem' : '.25rem',
          '&.Mui-selected': {
            borderRadius: '0.25rem',
            backgroundColor: 'rgba(255, 255, 255, 0.15)'
          }
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.open ? '.5rem' : '0',
            justifyContent: 'center',
            color: 'white'
          }}
        >
          {props.icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            opacity: props.open ? 1 : 0,
            maxWidth: props.open ? 'auto' : '0px'
          }}
        >
          <Typography
            variant='body1'
            fontFamily={'AvenirBook'}
            sx={{ color: 'white', fontSize: '14px' }}
          >
            {props.text}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}