import AuthLayout from '../../../components/AuthLayout/AuthLayout'
import { Form, Formik } from 'formik'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { EmailDto } from '../../../api/auth/types'
import TextField from '../../../components/CustomMui/Fields/TextField'
import { Link } from 'react-router-dom'
import { resetPasswordRequestValidation } from '../../../utils/validations/resetPasswordRequestValidation'
import useResetPassword from '../../../hooks/auth/useResetPassword'
import AuthIcon from '../../../assets/images/AuthIcon.svg'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import useGaPageView from '../../../hooks/google/useGaPageView'

export default function ResetPasswordRequest() {
  const texts = useTranslation().t

  useGaPageView('ResetPasswordSendEmail')

  const { mutate, isLoading } = useResetPassword()

  const handleSubmit = (data: EmailDto) => {
    mutate(data)
  }

  return (
    <AuthLayout>
      <Grid
        container
        direction={'column'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        maxWidth={'30rem'}
        mx={'auto'}
      >
        <Box
          component={'img'}
          alt='logo'
          src={AuthIcon}
          style={{
            height: '32px',
            position: 'relative',
            marginBottom: '43px'
          }}
        />
        <Typography
          variant='h1'
          sx={{ marginBottom: '2.75rem', textAlign: 'center' }}
        >
          {texts('auth:reset_password_title')}
        </Typography>
        <Formik<EmailDto>
          initialValues={{ email: '' }}
          onSubmit={handleSubmit}
          validationSchema={resetPasswordRequestValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <Grid
                container
                width={'25rem'}
              >
                <TextField
                  name='email'
                  type='email'
                  label={texts('auth:email_address')}
                />
                <LoadingButton
                  disableElevation
                  fullWidth
                  type='submit'
                  variant={'contained'}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  sx={{
                    fontFamily: 'AvenirHeavy',
                    height: '36px',
                    minWidth: '12rem',
                    color: 'white',
                    textTransform: 'none'
                  }}
                  loading={isLoading}
                >
                  {texts('common:send')}
                </LoadingButton>
                <Link
                  to='/login'
                  style={{
                    textDecoration: 'none',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '1.25rem'
                  }}
                >
                  <Box
                    display={'flex'}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      '&:hover': {
                        animation: 'moveAside 300ms 1 ease-in-out forwards'
                      }
                    }}
                  >
                    <ArrowBackRoundedIcon
                      fontSize='small'
                      sx={{ color: 'rgb(32, 32, 32)' }}
                    />
                    <Typography
                      variant='body1'
                      textAlign={'center'}
                      lineHeight={'24px'}
                      color={'rgba(32, 32, 32, 0.87)'}
                    >
                      {texts('auth:login_redirect')}
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </AuthLayout>
  )
}