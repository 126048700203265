import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { GenerateReportFormData } from '../../api/data/types'
import { loadingReportIcon, loadingReportPointLabel } from '../../styles/generateReport'
import { sortAdditionalParams } from '../../utils/converters'

type Props = {
  isSuccess: boolean
  isError: boolean
  values: GenerateReportFormData & { fileUrl: string | null }
  onBackToFormClick: () => void
}

export default function LoadingReport(props: Props) {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const additionalParamsText = useMemo(() => {
    return (props.values.params.additionalParams.length ?? 0) > 0 
      ? sortAdditionalParams(props.values.params.additionalParams)
        .map(param => texts('report:additional_param_label', {param: param}))
        .join(', ') 
      : '-'
  }, [props.values, texts])

  return (
    <Box
      display={'flex'}
      gap={'.75rem'}
      flexDirection={'column'}
    >
      <Typography
        variant={'h1'}
        fontFamily={'AvenirHeavy'}
        fontSize={'1.125rem'}
        color={'rgb(32, 32, 32)'}
        mb={'.25rem'}
      >
        {texts('report:generate_report_title')}
      </Typography>
      <Typography
        variant={'h2'}
        fontSize={'.875rem'}
        color={'rgba(32, 32, 32, 0.75)'}
        mb={'.25rem'}
      >
        {texts('report:report_details_subtitle')}
      </Typography>
      <Box display={'flex'} alignItems={'center'}>
        <TaskAltIcon sx={loadingReportIcon} />
        <Typography
          variant={'h2'}
          sx={loadingReportPointLabel}
        >
          {`${texts('report:location_section')}: `}
          <Box component={'span'} fontFamily={'AvenirBook'}>
            {props.values.location!.town}
            {
              props.values.location!.street &&
              <Trans
                i18nKey={'report:tile_location_street'}
                values={{ street: props.values.location!.street}}
              />
            }
            {texts('report:tile_location_street_number', { streetNumber: props.values.location!.streetNumber })}
          </Box>
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <TaskAltIcon sx={loadingReportIcon} />
        <Typography
          variant={'h2'}
          sx={loadingReportPointLabel}
        >
          {`${texts('common:client')}: `}
          <Box component={'span'} fontFamily={'AvenirBook'}>
            { props.values.params.clientName ? props.values.params.clientName : '-' }
          </Box>
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <TaskAltIcon sx={{
          marginRight: '.5rem',
          color: 'primary.main',
          width: '1.5rem',
          height: '1.5rem'
        }} />
        <Typography
          variant={'h2'}
          sx={loadingReportPointLabel}
        >
          {`${texts('report:flat_section')}: `}
          <Box component={'span'} fontFamily={'AvenirBook'}>
            <Trans
              i18nKey={'report:details_params'}
              values={{
                area: props.values.params.area,
              }}
            />
            {
              props.values.params.rooms != null &&
                <Trans
                  i18nKey={'report:details_params_rooms'}
                  values={{
                    rooms: texts('report:full_room_labels', { rooms: props.values.params.rooms })
                  }}
                />
            }
            {
              (props.values.params.floor != null || props.values.params.maxFloor != null) &&
                <Trans
                  i18nKey={'report:details_params_floors'}
                  values={{
                    floor: props.values.params.floor != null ? texts('report:floor_labels', { floor: props.values.params.floor }) : '-',
                    floorMax: props.values.params.maxFloor != null ? texts('report:max_floor_labels', { floor: props.values.params.maxFloor }) : '-',
                  }}
                />
            }
            {
              props.values.params.marketType != null &&
                <Trans
                  i18nKey={'report:details_params_market'}
                  values={{
                    market: texts('report:market_type_label', { marketType: props.values.params.marketType })
                  }}
                />
            }
            {
              (props.values.params.minBuildYear != null && props.values.params.maxBuildYear == null) &&
                <Trans
                  i18nKey={'report:details_params_min_build_year'}
                  values={{
                    year: props.values.params.minBuildYear,
                  }}
                />
            }
            {
              (props.values.params.maxBuildYear != null && props.values.params.minBuildYear == null) &&
                <Trans
                  i18nKey={'report:details_params_max_build_year'}
                  values={{
                    year: props.values.params.maxBuildYear
                  }}
                />
            }
            {
              (props.values.params.minBuildYear != null && props.values.params.maxBuildYear != null) &&
                <Trans
                  i18nKey={'report:details_params_build_year'}
                  values={{
                    minBuildYear: props.values.params.minBuildYear ?? '',
                    maxBuildYear: props.values.params.maxBuildYear ?? ''
                  }}
                />
            }
            {
              props.values.params.buildingState != null &&
                <Trans
                  i18nKey={'report:details_params_building_state'}
                  values={{
                    state: texts('report:building_state_labels', { state: props.values.params.buildingState })
                  }}
                />
            }
          </Box>
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <TaskAltIcon sx={loadingReportIcon} />
        <Typography
          variant={'h2'}
          sx={loadingReportPointLabel}
        >
          {`${texts('report:additional_params_section')}: `}
          <Box component={'span'} fontFamily={'AvenirBook'}>
            {additionalParamsText}
          </Box>
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <TaskAltIcon sx={loadingReportIcon} />
        <Typography
          variant={'h2'}
          sx={loadingReportPointLabel}
        >
          {`${texts('report:additional_settings')}: `}
          <Box component={'span'} fontFamily={'AvenirBook'}>
            {
              texts(
                props.values.params.includePollution
                  ? 'report:with_pollution_data'
                  : 'report:without_pollution_data'
              )
            }
          </Box>
        </Typography>
      </Box>
      <Box
        display={'flex'}
        sx={{ justifyContent: 'flex-end' }}
      >
        {
          !props.isError && !props.isSuccess
            ? <Button
                disableElevation
                variant={'contained'}
                sx={{
                  pointerEvents: 'none',
                  fontFamily: 'AvenirHeavy',
                  fontSize: '.9375rem',
                  height: '2.25rem',
                  color: 'white',
                  textTransform: 'none',
                  width: '15.625rem',
                }}
              >
                <CircularProgress
                  size={'1.5rem'}
                  sx={{
                    color: 'white',
                    marginRight: '.5rem',
                  }}
                />
                {texts('report:generating_button')}
              </Button>
            : props.isError
              ? <Button
                  disableElevation
                  variant={'outlined'}
                  sx={{
                    fontFamily: 'AvenirMedium',
                    fontSize: '.9375rem',
                    textTransform: 'none',
                    height: '2.25rem',
                    width: '15.625rem',
                    color: 'rgb(2, 13, 50)',
                    borderColor: 'rgb(2, 13, 50)'
                  }}
                  onClick={props.onBackToFormClick}
                >
                  {texts('report:back_to_form')}
                </Button>
              : <Box 
                  display={'flex'} 
                  justifyContent={'flex-end'}
                  height={'36px'}
                  gap={'12px'}
                >
                  <Button
                    disableElevation
                    variant={'outlined'}
                    onClick={() => navigate('/')}
                    sx={{
                      fontFamily: 'AvenirMedium',
                      fontSize: '.9375rem',
                      border: '1px solid rgb(32, 32, 32)',
                      height: '2.25rem',
                      color: 'black',
                      textTransform: 'none',
                      width: '15.625rem',
                    }}
                  >
                    {texts('report:go_to_list')}
                  </Button>
                  <Button
                    disableElevation
                    variant={'contained'}
                    onClick={() => {
                      if (props.values.fileUrl) {
                        window.open(props.values.fileUrl)}
                      }
                    }
                    sx={{
                      color: 'white',
                      height: '2.25rem',
                      fontSize: '.9375rem',
                      textTransform: 'none',
                      width: '15.625rem',
                      fontFamily: 'AvenirHeavy',
                    }}
                  >
                    {texts('report:download_button')}
                  </Button>
                </Box>
        }
      </Box>
    </Box>
  )
}
