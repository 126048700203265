import { Grid, Typography, Box, InputAdornment, TextField } from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { DateRange } from '@mui/x-date-pickers-pro'
import DateRangeIcon from '@mui/icons-material/DateRange'

type Props = {
  value: DateRange<Date>
  mask?: string
  inputFormat?: string
  maxDate?: Date
  onChange: (date: DateRange<Date>) => void
  onClose?: () => void
  labels?: {
    before?: string
    middle?: string
  }
}

export default function CustomDateRangePicker(props: Props) {
  return (
    <DateRangePicker
      mask={props.mask}
      inputFormat={props.inputFormat}
      maxDate={props.maxDate}
      value={props.value}
      onClose={props.onClose}
      onChange={props.onChange}
      renderInput={(startProps, endProps) => (
        <Box>
          <Grid
            container
            flexWrap={'nowrap'}
            direction={'row'}
            gap={'.5rem'}
            alignItems={'center'}
          >
            <Typography
              fontSize={'.875rem'}
              color={'#202020'}
            >
              {props.labels?.before}
            </Typography>
            <TextField
              {...startProps}
              label={''}
              sx={{ 
                width: '160px',
                '.MuiInputBase-input': {
                  fontSize: '14px !important',
                  transform: 'translate(0, 1px) scale(1)'
                },
                '.MuiInputBase-root': {
                  height: '2rem'
                }
              }}
              InputProps={
                {
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <DateRangeIcon />
                    </InputAdornment>
                  )
                }
              }
            />
            <Typography
              fontSize={'14px'}
              color={'#202020'}
            >
              {props.labels?.middle}
            </Typography>
            <TextField
              size={'small'}
              sx={{ 
                width: '160px',
                '.MuiInputBase-input': {
                  fontSize: '14px !important',
                  transform: 'translate(0, 1px) scale(1)',
                },
                '.MuiInputBase-root': {
                  height: '2rem'
                }
              }}
              {...endProps}
              label={''}
              placeholder={'dd/mm/yyyy'}
              InputProps={
                {
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <DateRangeIcon />
                    </InputAdornment>
                  )
                }
              }
            />
          </Grid>
        </Box>
      )}
    />
  )
}