import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import { mapSelectedAdvertListDto } from '../../mapping/adverts'
import { SelectedAdvertList } from '../../types/data'

const dataApi = DataApi.getInstance()

type Options = {
  reportUuid: string
  enabled: boolean
  onSuccess: (data: SelectedAdvertList) => void
}

export default function useFetchSelectedAdverts(options: Options) {
  const texts = useTranslation().t
  const query = useQuery(
    [queryNames.fetchSelectedAdverts, options.reportUuid], 
    async () => mapSelectedAdvertListDto(await dataApi.getSelectedAdverts(options.reportUuid)), 
    {
      onError: (error: AxiosError<HttpError>) => {
        showErrorToast(error, texts)
      },
      staleTime: Infinity,
      enabled: options.enabled,
      onSuccess: options.onSuccess,
      refetchOnMount: 'always',
    }
  )
  return query
}