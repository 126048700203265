import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { getUserUuid } from '../../services/storage/StorageService'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import { Codes, DataSubCodes } from '../../services/errors/consts'

const dataApi = DataApi.getInstance()

export default function useDeleteReport() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((uuid: string) => dataApi.deleteReport(uuid), {
    onSuccess: (_data, uuid) => {
      queryClient.invalidateQueries([queryNames.fetchReportList, getUserUuid()])
      queryClient.invalidateQueries([queryNames.fetchReportDetails, uuid, getUserUuid()])
    },
    onError: (error: AxiosError<HttpError>) => {
      if (error.response?.status === 404 && error.response.data.code === Codes.DATA && error.response?.data.subcode === DataSubCodes.REPORT_NOT_FOUND) {
        queryClient.resetQueries(queryNames.fetchReportList)
        queryClient.resetQueries(queryNames.fetchReportAdminList)
      }
      showErrorToast(error, texts)
    },
  })
  return mutation
}