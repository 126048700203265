import { createTheme } from '@mui/material/styles'
import type { } from '@mui/lab/themeAugmentation'
import { plPL } from '@mui/x-data-grid'

const MainTheme = createTheme({
  transitions: {
    duration: {
      leavingScreen: 500,
      enteringScreen: 500
    }
  },
  typography: {
    fontFamily: 'AvenirBook',
    subtitle1: {
      fontFamily: 'RobotoCondensedBold',
      fontSize: '1.375rem',
      letterSpacing: '0.114rem',
      marginBottom: '1.25rem',
      borderBottom: 'solid 0.063rem #383838'
    },
    subtitle2: {
      fontFamily: 'AvenirMedium',
      fontSize: '1.125rem'
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '20px',
      fontFamily: 'AvenirBook'
    },
    body2: {
      fontSize: '1.25rem'
    },
    h1: {
      fontFamily: 'AvenirBook',
      fontSize: '2.125rem',
      color: 'rgba(46, 56, 77)'
    },
    h2: {
      fontFamily: 'AvenirHeavy',
      fontSize: '1rem',
    },
    h3: {
      fontFamily: 'AvenirMedium',
      fontSize: '1rem',
    },
    h4: {
      fontFamily: 'AvenirBook',
      fontSize: '1.125rem',
      letterSpacing: '0.063rem'
    }
  },
  palette: {
    primary: {
      main: '#FFA034'
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '.75rem',
          boxShadow: '0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '1rem !important'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '::placeholder': {
            color: 'rgba(32, 32, 32, 0.65)',
            opacity: 1
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: '24px',
          fontFamily: 'AvenirBook',
          transform: 'translate(14px, 8px) scale(1)',
          opacity: 1,
          color: 'rgba(32, 32, 32, 0.65)',
          '&.Mui-focused': {
            fontSize: '12px',
            transform: 'translate(14px, -13px) scale(1)'
          },
          '&.MuiFormLabel-filled' : {
            fontSize: '12px',
            transform: 'translate(14px, -13px) scale(1)'
          },
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          alignContent: 'center',
          color: 'rgba(32, 32, 32, 0.65)',
          '.MuiOutlinedInput-root': {
            borderRadius: '4px',
            alignContent: 'center',
            borderColor: 'rgba(0, 0, 0, 0.16)',
            '&:hover:not(.Mui-disabled,.Mui-focused) fieldset': {
              opacity: '25%'
            },
            '&.Mui-focused fieldset': {
              opacity: 1
            },
            '&.Mui-error fieldset': {
              opacity: 1
            },
            '.MuiOutlinedInput-input': {
              fontSize: '16px',
              fontFamily: 'AvenirBook',
              paddingTop: '8.5px',
              paddingBottom: '8.5px',
              color: '#202020'
            }
          },
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        }
      },
      variants: [
        {
          props: { size: 'large' },
          style: { minWidth: '12rem' }
        },
        {
          props: { size: 'medium' },
          style: {
            minWidth: 'fit-content'
          }
        }
      ]
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: () => ({
          color: 'white'
        }),
        root: {
          fontFamily: 'AvenirHeavy',
          '&.MuiLoadingButton-loading': {
            backgroundColor: '#FFA034'
          }
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          alignSelf: 'center'
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontSize: '.875rem',
          fontFamily: 'AvenirBook',
          '&.Mui-selected': {
            color: 'white',
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            borderRadius: '8px'
          }
        }
      }
    }
  }
}, plPL)

export default MainTheme
