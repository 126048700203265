import AddIcon from '@mui/icons-material/Add'
import CreateIcon from '@mui/icons-material/Create'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import { getGridSingleSelectOperators, GridColDef, GridFilterModel, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  NumberParam,
  StringParam, useQueryParams, withDefault
} from 'use-query-params'
import { AccountStatus, OrganizationMemberDto } from '../../api/data/types'
import DefaultLogo from '../../assets/images/AuthIcon.svg'
import NoPackageIcon from '../../assets/images/gold_packages.svg'
import { DataGrid } from '../../components/CustomMui/DataGrid/DataGrid'
import NoDataPlaceholder from '../../components/CustomMui/DataGrid/NoDataGridPlaceholder'
import Loading from '../../components/CustomMui/Loading'
import PercentageBar from '../../components/CustomMui/PercentageBar'
import CreateSubAccountDialog from '../../components/Dialog/CreateSubAccountDialog'
import UpdateAccountNameDialog from '../../components/Dialog/UpdateAccountNameDialog'
import UpdateAccountStatusDialog from '../../components/Dialog/UpdateAccountStatusDialog'
import ContactDetails from '../../components/MyAgency/ContactDetails'
import MenuDrawer from '../../components/MyAgency/MenuDrawer'
import SearchForm from '../../components/SearchForm/SearchForm'
import useFetchMembers from '../../hooks/data/useFetchMembers'
import useFetchOrganizationDetails from '../../hooks/data/useFetchOrg'
import { queryNames } from '../../hooks/queries'
import { getUserOrgUuid, getUserUuid, isPremiumMainAccount } from '../../services/storage/StorageService'
import { pageSizes } from '../../utils/const'
import { StatusToColor } from '../../utils/converters'
import { mapAccountListFilterModel, mapAccountListSortModel } from '../../utils/dataGrid'
import ConnectionError from '../Errors/ConnectionError'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function MyAgency() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  useGaPageView('MyAgency')

  const [searchParams, setSearchParams] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 10),
    search: withDefault(StringParam, undefined),
    order: withDefault(StringParam, ''),
    status: withDefault(NumberParam, undefined)
  })

  const fetchOrganization = useFetchOrganizationDetails()

  const fetchMembers = useFetchMembers({
    limit: searchParams.size,
    offset: searchParams.page * searchParams.size,
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    order: searchParams.order,
    status: searchParams.status != null && searchParams.status in AccountStatus ? searchParams.status : undefined
  })

  const handleSortChange = (model: GridSortModel) => {
    setSearchParams({
      order: model.length > 0
        ? model.map(entry => `${entry.field}:${entry.sort}`).join(',')
        : '',
      page: 0
    }, 'replaceIn')
  }

  const handleFilterChange = (model: GridFilterModel) => {
    setSearchParams({
      status: model.items.length > 0 && model.items[0].value != null && model.items[0].columnField === 'status'
        ? model.items[0].value
        : undefined,
      page: 0
    }, 'replaceIn')
  }

  const [openCreateAccount, setOpenCreateAccount] = useState<boolean>(false)
  const [openEditName, setOpenEditName] = useState<boolean>(false)
  const [openChangeActiveState, setOpenChangeActiveState] = useState<boolean>(false)
  const [selectedAccount, setSelectedAccount] = useState<OrganizationMemberDto | null>(null)

  const columns: { field: string, name: string }[] = texts('agency:account_list_columns', { returnObjects: true }) as { field: string, name: string }[]

  const accountListColumns: GridColDef<OrganizationMemberDto>[] = useMemo(() => [{
    field: columns[0].name,
    headerName: columns[0].field,
    flex: 3,
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (props) => props.row.name,
  }, {
    field: columns[1].name,
    headerName: columns[1].field,
    flex: 6,
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (props) => props.row.email
  }, {
    field: columns[2].name,
    headerName: columns[2].field,
    flex: 2,
    align: 'center',
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (props) => fetchOrganization.data?.currentPackage && fetchOrganization.data.currentPackage.reportsLeft && props.row.reportsCount > 0 ? props.row.reportsCount : '-'
  }, {
    field: columns[3].name,
    headerName: columns[3].field,
    type: 'date',
    flex: 3,
    align: 'center',
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (props) => props.row.lastActivity,
    valueFormatter: (params: GridValueFormatterParams<Date | null>) => params.value ? moment(params.value).format('DD/MM/YYYY HH:mm') : '-'
  }, {
    field: columns[4].name,
    headerName: columns[4].field,
    flex: 2,
    filterable: true,
    type: 'singleSelect',
    filterOperators: getGridSingleSelectOperators().filter(({ value }) => ['is'].includes(value)),
    valueOptions: [
      {
        value: AccountStatus.INACTIVE,
        label: texts('common:account_status', { status: AccountStatus.INACTIVE })
      },
      {
        value: AccountStatus.WAITING_FOR_ACTIVATION,
        label: texts('common:account_status', { status: AccountStatus.WAITING_FOR_ACTIVATION })
      },
      {
        value: AccountStatus.ACTIVE,
        label: texts('common:account_status', { status: AccountStatus.ACTIVE })
      }
    ],
    renderCell: (props) => {
      return (
        <Box
          sx={{
            border: `1px solid ${StatusToColor[props.row.status]}`,
            width: '100%',
            textAlign: 'center',
            borderRadius: '.75rem',
            overflow: 'hidden'
          }}
        >
          <Typography
            sx={{
              color: StatusToColor[props.row.status],
              fontSize: '.625rem',
              lineHeight: '1.5rem',
              fontFamily: 'AvenirMedium'
            }}
          >
            {texts('common:account_status', { status: props.row.status })}
          </Typography>
        </Box>
      )
    }
  }, {
    field: 'actions',
    headerName: '\u00A0',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (props) => <MenuDrawer 
      row={props.row} 
      onEditName={(row: OrganizationMemberDto) => {
        setOpenEditName(true)
        setSelectedAccount(row)
      }} 
      onChangeActiveState={(row: OrganizationMemberDto) => {
        setOpenChangeActiveState(true)
        setSelectedAccount(row)
      }}
    />
  }], [columns, texts, fetchOrganization.data])

  useEffect(() => {
    const shouldRedirect = 
      !Number.isInteger(searchParams.page)
      || !Number.isInteger(searchParams.size) 
      || (!fetchMembers.isFetching  && searchParams.page > 0 && searchParams.page * searchParams.size >= (fetchMembers.data?.totalCount ?? 0))
      || !pageSizes.includes(searchParams.size) 
      || searchParams.page < 0
      || (searchParams.order && (accountListColumns.find(c => c.field === searchParams.order.split(':')[0]) == null
      || accountListColumns.find(c => c.field === searchParams.order.split(':')[0])?.sortable === false
      || !['asc', 'desc'].includes(searchParams.order.split(':')[1]?.toLowerCase())))
      || (searchParams.status != null && !(searchParams.status in AccountStatus))
    
    if (shouldRedirect) {
      setSearchParams({
        page: 0,
        size: 10,
        order: '',
        search: undefined,
        status: undefined
      }, 'replaceIn')
    }
  }, [navigate, fetchMembers.data?.totalCount, searchParams, fetchMembers.isFetching, setSearchParams, accountListColumns])

  if (fetchMembers.isError || fetchOrganization.isError) {
    return <ConnectionError />
  }

  if (fetchOrganization.isFetching) {
    return <Loading />
  }

  return (
    <>
      <CreateSubAccountDialog 
        open={openCreateAccount} 
        onClose={() => setOpenCreateAccount(false)} 
        onSuccess={() => {
          setSearchParams({
            page: 0,
            size: 10,
            order: '',
            search: undefined,
            status: undefined
          }, 'replaceIn')
        }}
      />
      <UpdateAccountNameDialog 
        open={openEditName}
        accountData={selectedAccount} 
        onSuccess={() => {
          toast.success(texts('successes:changes_saved'))
          queryClient.invalidateQueries([queryNames.fetchMembersList, getUserOrgUuid(), getUserUuid()])
        }}
        onClose={() => {
          setOpenEditName(false)
          setSelectedAccount(null)
        }} 
      />
      <UpdateAccountStatusDialog 
        open={openChangeActiveState}
        accountData={selectedAccount}
        i18={{
          activate: {
            title: 'agency:activate_account_title',
            description: 'agency:activate_account_warning'
          },
          deactivate: {
            title: 'agency:deactivate_account_title',
            description: 'agency:deactivate_account_warning'
          },
          toast: {
            activated: 'successes:access_activated',
            deactivated: 'successes:access_deactivated'
          }
        }}
        onClose={() => {
          setOpenChangeActiveState(false)
          setSelectedAccount(null)
        }} 
        onSuccess={() => {
          queryClient.invalidateQueries([queryNames.fetchMembersList, getUserOrgUuid(), getUserUuid()])
        }}
      />
      <Box 
        display={'flex'}
        flexDirection={'column'}
        gap={'1rem'}
      >
        <Typography
          variant={'h1'}
          color={'#202020'}
          fontFamily={'AvenirHeavy'}
          fontSize={'1.125rem'}
        >
          {texts('agency:my_agency')}
        </Typography>
        <Typography
          variant={'h2'}
          color={'rgba(32, 32, 32, 0.75)'}
          fontSize={'.875rem'}
        >
          {texts('agency:agency_info')}
        </Typography>
        <Card>
          <CardContent>
            <Box 
              display={'flex'}
              gap={'1rem'}
              alignItems={'flex-start'}
            >
              <Box 
                height={'100%'}
                maxWidth={'40%'}
                overflow={'hidden'}
              >
                <Box
                  component={'img'}
                  alt={'logo'}
                  src={fetchOrganization.data!.info.logoUrl ?? DefaultLogo} 
                  maxWidth={'100%'}
                  maxHeight={'2.25rem'}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                height={'100%'}
                flexGrow={1}
              >
                {
                  fetchOrganization.data!.info.contactDetails && 
                    <ContactDetails 
                      name={fetchOrganization.data!.info.props?.report.name ?? ''}
                      contactDetails={fetchOrganization.data!.info.contactDetails}
                    />
                }
                {
                  !fetchOrganization.data!.info.contactDetails &&
                    <Typography 
                      fontSize={'.875rem'}
                      lineHeight={'1.875rem'}
                      color={'rgba(32, 32, 32, 0.65)'}
                    >
                      {
                        isPremiumMainAccount() 
                        ? texts('agency:agency_no_details_can_personalize')
                        : texts('agency:agency_no_details_cannot_personalize')
                      }
                    </Typography>
                } 
              </Box>
              <Button
                variant={'outlined'}
                disabled={!isPremiumMainAccount()}
                onClick={() => { navigate('/agency/edit') }}
                sx={{
                  color: '#020D32',
                  borderColor: '#020D32',
                  fontSize: '.75rem',
                  fontFamily: 'AvenirMedium',
                  letterSpacing: '0.26',
                  textTransform: 'none',
                  width: '10rem',
                  height: '1.875rem',
                }}
              >
                <CreateIcon sx={{ marginRight: '.5rem' }} />
                {texts("agency:edit_data")}
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Typography
          variant={'h2'}
          color={'rgba(32, 32, 32, 0.75)'}
          fontSize={'.875rem'}
        >
          {texts('agency:current_packet')}
        </Typography>
        <Card>
          <CardContent>
            {
              fetchOrganization.data?.currentPackage && fetchOrganization.data.currentPackage.reportsLeft
                ? <Box>
                    <Typography
                      color={'#020D32'}
                      fontFamily={'AvenirHeavy'}
                      fontSize={'.875rem'}
                    >
                      <Trans
                        i18nKey={'agency:packet_of'}
                        values={{ name: texts('packages:simple_package_label_label', { name: fetchOrganization.data!.currentPackage.name }) }
                        }
                      />
                    </Typography>
                    <Typography
                      color={'rgba(32, 32, 32, 0.87)'}
                      fontSize={'.875rem'}
                      mb={'1rem'}
                    >
                      <Trans
                        i18nKey={'agency:expires_at'}
                        values={{
                          date: moment(fetchOrganization.data!.currentPackage.availableUntil).format('DD/MM/YYYY')
                        }}
                      />
                    </Typography>
                    <Box 
                      display={'flex'}
                      alignItems={'center'}
                      ml={'auto'}
                      mb={'.5rem'}
                      width={'fit-content'}
                    >
                      <Typography
                        color={'rgba(32, 32, 32, 0.87)'}
                        fontSize={'.875rem'}
                        letterSpacing={'0.13'}
                        lineHeight={'.875rem'}
                      >
                        {`${texts('agency:left')}:\u00A0`}
                      </Typography>
                      <Typography
                        color={'rgba(32, 32, 32, 0.87)'}
                        fontFamily={'AvenirHeavy'}
                        fontSize={'1.25rem'}
                        letterSpacing={'0.13'}
                      >
                        <Trans
                          i18nKey={'agency:fraction'}
                          values={{
                            left: fetchOrganization.data!.currentPackage.reportsLeft,
                            right: fetchOrganization.data!.currentPackage.maxReportsCount
                          }}
                        />
                      </Typography>
                    </Box>
                    <PercentageBar
                      count={fetchOrganization.data!.currentPackage.reportsLeft}
                      max={fetchOrganization.data!.currentPackage.maxReportsCount}
                      showText={false}
                    />
                  </Box>
                : <Box
                    display='flex'
                    sx={{
                      flexDirection: 'column'
                    }}
                  >
                    <Box
                      component={'img'}
                      src={NoPackageIcon}
                      sx={{
                        width: '2.25rem',
                        height: '2.25rem',
                        alignSelf: 'center',
                        marginBottom: '1rem',
                        marginTop: '.625rem'
                      }}
                    />
                    <Typography
                      fontFamily={'AvenirHeavy'}
                      fontSize={'.75rem'}
                      lineHeight={'1.125rem'}
                      sx={{
                        textAlign: 'center',
                        color: 'rgba(32, 32, 32, 0.65)'
                      }}
                    >
                      {texts('agency:no_packets_main')}
                    </Typography>
                    <Typography
                      fontSize={'.75rem'}
                      lineHeight={'1.125rem'}
                      sx={{
                        textAlign: 'center',
                        color: 'rgba(32, 32, 32, 0.65)'
                      }}
                    >
                      {texts('agency:no_packets_sub')}
                    </Typography>
                </Box>
            }
          </CardContent>
        </Card>

        <Box
          display={'flex'}
          sx={{
            justifyContent: 'space-between',
            marginBlock: '1rem'
          }}
        >
          <SearchForm
            placeholder={texts('agency:search_access_by_name')}
            onSubmit={(data) => {
              setSearchParams({ search: data.search ? encodeURI(data.search) : '', page: 0 }, 'replaceIn')
              queryClient.invalidateQueries([queryNames.fetchMembersList])
            }}
            searchValue={searchParams.search ? decodeURI(searchParams.search) : undefined}
          />
          <Button
            disableElevation
            variant={'contained'}
            onClick={() => setOpenCreateAccount(true)}
            sx={{ width: '250px', height: '36px' }}
          >
            <AddIcon sx={{ color: 'white' }} />
            <Typography
              fontSize={'15px'}
              fontFamily={'AvenirHeavy'}
              color={'white'}
              textTransform={'none'}
              lineHeight={'1.4'}
            >
              {texts('agency:add_access')}
            </Typography>
          </Button>
        </Box>

        <Typography
          variant={'h2'}
          color={'rgba(32, 32, 32, 0.75)'}
          fontSize={'.875rem'}
        >
          {texts('agency:access_list')}
        </Typography>

        <Box display={'flex'} minHeight={'200px'}>
          <DataGrid
            rows={fetchMembers.data?.accounts ?? []}
            columns={accountListColumns}
            noRowsOverlay={NoDataPlaceholder}
            autoHeight={true}
            rowHeight={40}
            pageSize={searchParams.size}
            rowsPerPageOptions={pageSizes}
            onPageSizeChange={(num) => setSearchParams({ size: num }, 'replaceIn')}
            onPageChange={(page) => setSearchParams({ page: page }, 'replaceIn')}
            paginationMode={'server'}
            rowCount={fetchMembers.data?.totalCount ?? 0}
            page={searchParams.page}
            loading={fetchMembers.isFetching}
            sortingMode={'server'}
            filterMode={'server'}
            getRowId={(row) => row.uuid}
            sortModel={mapAccountListSortModel(searchParams.order)}
            filterModel={mapAccountListFilterModel(searchParams.status)}
            onSortModelChange={handleSortChange}
            onFilterModelChange={handleFilterChange}
            initialState={{
              sorting: {
                sortModel: [{ field: 'last_active_at', sort: 'desc' }]
              }
            }}
            pagination
            disableColumnSelector 
            disableColumnPinning
          />
        </Box>
      </Box>
    </>
  )
}