import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { ReportListQueryDto } from '../../api/data/types'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useFetchAdminReportList(organizationUuid: string, data: ReportListQueryDto) {
  const texts = useTranslation().t
  const query = useQuery(
    [queryNames.fetchReportAdminList, organizationUuid, data.search, data.offset, data.limit, data.from, data.to],
    () => dataApi.getOrganizationReportList(organizationUuid ?? '', data),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onError: (error: AxiosError<HttpError>) => {
        showErrorToast(error, texts)
      }
    }
  )
  return query
}