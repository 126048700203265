import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './i18n'
import App, { ROUTER_BASE_NAME } from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import './assets/fonts/Avenir-Book.ttf'
import './assets/fonts/Avenir-Heavy.ttf'
import './assets/fonts/Avenir-Medium.ttf'
import './assets/fonts/RobotoCondensed-Bold.ttf'

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_VERSION
  })
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Number.parseInt(process.env.REACT_APP_STALE_TIME_MS!)
    },
  },
})

root.render(
  <React.StrictMode>
    <BrowserRouter basename={ROUTER_BASE_NAME}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
