import * as Yup from 'yup'
import { passwordRules, englishCharactersAllowedPassword } from './validationRegexps'
import { TFunction, Namespace } from 'i18next'

export function passwordValidation(t: TFunction<Namespace>) {
  return Yup.object({
    password: Yup.string()
      .required(t('validations:field_required'))
      .matches(englishCharactersAllowedPassword, t('validations:special_characters_password_validation'))
      .min(8, t('validations:pwd_min_8_signs_validation'))
      .max(32, t('validations:pwd_max_32_signs_validation'))
      .matches(passwordRules, t('validations:password_rules_validation'))
  })
}