import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useDeleteReport from '../../hooks/data/useDeleteReport'
import CustomDialog from '../CustomMui/CustomDialog/CustomDialog'

type Props = {
  onClose: () => void
  reportUuid?: string
}

export default function ConfirmDeleteDialog(props: Props) {
  const texts = useTranslation().t

  const { mutate, isLoading } = useDeleteReport()

  const handleClose = () => props.onClose()

  const handleDelete = async () => {
    mutate(props.reportUuid ?? '', { 
      onSuccess: handleClose,
      onError: handleClose
    })
  }

  return (
    <CustomDialog
      title={texts('report:confirm_raport_deletion')}
      handleClose={handleClose}
      handleConfirm={() => handleDelete()}
      loading={isLoading}
      withCancel
      labels={{
        cancel: texts('report:dont_delete'),
        confirm: texts('report:do_delete')
      }}
      open={Boolean(props.reportUuid)} 
    >
      <Typography
        fontSize={'.875rem'}
        color={'rgb(32, 32, 32)'}
      >
        {texts('report:confirm_raport_deletion_warning')}
      </Typography>
    </CustomDialog>
  )
}