import { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import { OrganizationMemberDto } from '../../api/data/types'

type Props = {
  row: OrganizationMemberDto
  onEditName: (row: OrganizationMemberDto) => void
  onChangeActiveState: (row: OrganizationMemberDto) => void
}

function MenuDrawer(props: Props) {
  const texts = useTranslation().t
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'center'}
        width={'100%'}
      >
        <IconButton
          aria-label={'more'}
          id={'long-button'}
          aria-haspopup={'true'}
          onClick={handleClick}
          sx={{
            padding: 0
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: '190px'
          },
        }}
      >
        <MenuItem 
          onClick={() => { 
            handleClose()
            props.onEditName(props.row)
          }}
          disableRipple
        >
          <Typography fontSize={'.75rem'} color={'rgb(32, 32, 32)'}>
            {texts('agency:edit_name')}
          </Typography>
        </MenuItem>
        {
          props.row.status !== 0
            ? <MenuItem 
                onClick={() => { 
                  handleClose()
                  props.onChangeActiveState(props.row)
                }}
                disableRipple
              >
                <Typography fontSize={'.75rem'} color={'rgb(224, 32, 32)'}>
                  {texts('agency:deactivate')}
                </Typography>
              </MenuItem>
            : <MenuItem 
                onClick={() => { 
                  handleClose()
                  props.onChangeActiveState(props.row)
                }}
                disableRipple
              >
                <Typography fontSize={'.75rem'} color={'#008000'}>
                  {texts('agency:activate')}
                </Typography>
              </MenuItem>
        }
      </Menu>
    </>
  )
}

export default MenuDrawer