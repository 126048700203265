import AuthLayout from '../../../components/AuthLayout/AuthLayout'
import { Form, Formik } from 'formik'
import { Box, Grid, Typography, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Password } from '../../../types/auth'
import PasswordField from '../../../components/CustomMui/Fields/PasswordField'
import { LoadingButton } from '@mui/lab'
import { passwordValidation } from '../../../utils/validations/resetPasswordValidation'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import AuthIcon from '../../../assets/images/AuthIcon.svg'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import useActivateUser from '../../../hooks/auth/useActivateUser'
import useCheckToken from '../../../hooks/auth/useCheckActivateToken'
import {
  StringParam,
  useQueryParam,
  withDefault
} from 'use-query-params'
import useGaPageView from '../../../hooks/google/useGaPageView'

export default function AccountActivation() {
  const texts = useTranslation().t

  const [token] = useQueryParam('token', withDefault(StringParam, null))
  const activateUser = useActivateUser()
  const {mutate: checkToken, isLoading} = useCheckToken()

  useGaPageView('ActivateAccount')

  useEffect(() => {
    checkToken({ token })
  }, [])

  const handleSubmit = (data: Password) => {
    activateUser.mutate({
      password: data.password,
      token: token
    })
  }

  return (
    <AuthLayout>
      <Grid
        container
        direction={'column'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        maxWidth={'30rem'}
        mx={'auto'}
      >
        {
          isLoading
            ? <CircularProgress />
            : <>
                <Box
                  component={'img'}
                  alt='logo'
                  src={AuthIcon}
                  style={{
                    height: '32px',
                    position: 'relative',
                    marginBottom: '43px'
                  }}
                />
                <Typography
                  variant='h1'
                  sx={{ textAlign: 'center' }}
                >
                  {texts('auth:set_password_title')}
                </Typography>
                <Typography
                  color={'rgba(46, 56, 77)'}
                  fontSize={'1.75rem'}
                  lineHeight={'32px'}
                  fontFamily={'AvenirBook'}
                  marginBottom={'0.75rem'}
                  marginTop={'0.25rem'}
                >
                  {texts('auth:set_password_subtitle')}
                </Typography>
                <Typography
                  variant='body1'
                  lineHeight={'32px'}
                  marginBottom={'2.75rem'}
                  color={'rgba(32, 32, 32, 0.67)'}
                >
                  {texts('auth:set_password_description')}
                </Typography>
                <Formik<Password>
                  initialValues={{
                    password: ''
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={passwordValidation(texts)}
                >
                  {(formikProps) => (
                    <Form>
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'25rem'}
                      >
                          <PasswordField
                            name='password'
                            autoComplete={'new-password'}
                            label={texts('auth:password')}
                            fullWidth
                          />
                          <LoadingButton
                            disableElevation
                            fullWidth
                            type='submit'
                            variant={'contained'}
                            disabled={!formikProps.isValid || !formikProps.dirty}
                            sx={{
                              fontFamily: 'AvenirHeavy',
                              height: '36px',
                              color: '#fff',
                              textTransform: 'none'
                            }}
                            loading={activateUser.isLoading}
                          >
                            {texts('common:save')}
                          </LoadingButton>
                          <Link
                            to='/login'
                            style={{
                              textDecoration: 'none',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              marginTop: '1.25rem'
                            }}
                          >
                            <Box
                              display={'flex'}
                              sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '8px',
                                '&:hover': {
                                  animation: 'moveAside 300ms 1 ease-in-out forwards'
                                }
                              }}
                            >
                              <ArrowBackRoundedIcon
                                fontSize='small'
                                sx={{ color: 'rgb(32, 32, 32)' }}
                              />
                              <Typography
                                variant='body1'
                                textAlign={'center'}
                                lineHeight={'24px'}
                                color={'rgba(32, 32, 32, 0.87)'}
                              >
                                {texts('auth:login_redirect')}
                              </Typography>
                            </Box>
                          </Link>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </>
        }
      </Grid>
    </AuthLayout >
  )
}
