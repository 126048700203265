import BusinessIcon from '@mui/icons-material/Business'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import LaunchIcon from '@mui/icons-material/Launch'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReportDto } from '../../api/data/types'
import { RoleName } from '../../utils/const'
import { sortAdditionalParams } from '../../utils/converters'

type Props = {
  data: ReportDto
  adminView?: boolean
  onDelete?: (val: string) => void
}

export default function ReportsTile(props: Props) {
  const texts = useTranslation().t

  const additionalParamsText = useMemo(() => {
    return (props.data.inputParams.additionalParams.length ?? 0) > 0 
      ? sortAdditionalParams(props.data.inputParams.additionalParams)
          .map(param => texts('report:additional_param_label', {param: param}))
          .join(', ') 
      : ''
  }, [props.data.inputParams.additionalParams, texts])

  return (
    <Card 
      sx={{ 
        '.MuiCardContent-root': {
          padding: '1rem 1.5rem !important',
        },
        width: '100%'
      }}
    >
      <CardContent>
        <Box
          display={'flex'}
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'.5rem'}
            width={'max-content'}
            sx={{
              opacity: props.data.removed ? 0.5 : 1
            }}
          >
            <Typography
              fontFamily={'AvenirHeavy'}
              lineHeight={'.875rem'}
              fontSize={'.625rem'}
              sx={{
                color: 'rgba(32, 32, 32, 0.67)',
                backgroundColor: '#E8E8E8',
                padding: '1px 10px',
                maxWidth: 'fit-content'
              }}
            >
              {
                props.adminView
                ? props.data.user.roles.find(e => e.name === RoleName.SUB_ACCOUNT)
                  ? texts('report:sub_account_admin', { email: props.data.user.email })
                  : texts('report:main_account_admin', { email: props.data.user.email })
                : props.data.user.roles.find(e => e.name === RoleName.SUB_ACCOUNT)
                  ? props.data.user.email
                  : texts('report:main_account')
              }
            </Typography>
            
            <Box 
              display={'flex'}
              alignItems={'center'}
            >
              <Typography
                variant={'h2'}
                lineHeight={'1rem'}
                fontSize={'.875rem'}
                color={'rgb(2, 13, 50)'}
              >
                {props.data.location.town}
                {props.data.location.street && `,\u00A0${props.data.location.street}`}
                {props.data.location.streetNumber && `\u00A0${props.data.location.streetNumber}`}
              </Typography>
              {
                props.data.inputParams.clientName != null && props.data.inputParams.clientName !== '' &&
                <Typography
                  fontFamily={'AvenirMedium'}
                  lineHeight={'1rem'}
                  fontSize={'.875rem'}
                  ml={'.5rem'}
                  color={'rgb(2, 13, 50)'}
                >
                  {texts('report:tile_for_label', { for: props.data.inputParams.clientName })}
                </Typography>
              }
            </Box>
            
            <Box 
              display={'flex'}
              alignItems={'center'}
              mt={'-.25rem'}
            >
              <BusinessIcon fontSize='small' sx={{ width: '1rem', height: '1rem' }}/>
              <Typography
                fontSize={'.75rem'}
                fontFamily={'AvenirMedium'}
                color={'rgba(32, 32, 32, 0.87)'}
                ml={'.5rem'}
                mt={'.125rem'}
                lineHeight={'1rem'}
              >
                <Trans i18nKey={'report:tile_params_label'} />
              </Typography>
              <Typography
                fontSize={'.75rem'}
                color={'rgba(32, 32, 32, 0.87)'}
                lineHeight={'1rem'}
              >
                <Trans
                  i18nKey={'report:details_params'}
                  values={{
                    area: props.data.inputParams.area,
                  }}
                />
                {
                  props.data.inputParams.rooms != null &&
                    <Trans
                      i18nKey={'report:details_params_rooms'}
                      values={{
                        rooms: texts('report:full_room_labels', { rooms: props.data.inputParams.rooms })
                      }}
                    />
                }
                {
                  (props.data.inputParams.floor != null || props.data.inputParams.maxFloor != null) &&
                    <Trans
                      i18nKey={'report:details_params_floors'}
                      values={{
                        floor: props.data.inputParams.floor != null ? texts('report:floor_labels', { floor: props.data.inputParams.floor }) : '-',
                        floorMax: props.data.inputParams.maxFloor != null ? texts('report:max_floor_labels', { floor: props.data.inputParams.maxFloor }) : '-',
                      }}
                    />
                }
                {
                  props.data.inputParams.marketType != null &&
                    <Trans
                      i18nKey={'report:details_params_market'}
                      values={{
                        market: texts('report:market_type_label', { marketType: props.data.inputParams.marketType })
                      }}
                    />
                }
                {
                  (props.data.inputParams.minBuildYear != null && props.data.inputParams.maxBuildYear == null) &&
                    <Trans
                      i18nKey={'report:details_params_min_build_year'}
                      values={{
                        year: props.data.inputParams.minBuildYear,
                      }}
                    />
                }
                {
                  (props.data.inputParams.maxBuildYear != null && props.data.inputParams.minBuildYear == null) &&
                    <Trans
                      i18nKey={'report:details_params_max_build_year'}
                      values={{
                        year: props.data.inputParams.maxBuildYear
                      }}
                    />
                }
                {
                  (props.data.inputParams.minBuildYear != null && props.data.inputParams.maxBuildYear != null) &&
                    <Trans
                      i18nKey={'report:details_params_build_year'}
                      values={{
                        minBuildYear: props.data.inputParams.minBuildYear ?? '',
                        maxBuildYear: props.data.inputParams.maxBuildYear ?? ''
                      }}
                    />
                }
                {
                  props.data.inputParams.buildingState != null &&
                    <Trans
                      i18nKey={'report:details_params_building_state'}
                      values={{
                        state: texts('report:building_state_labels', { state: props.data.inputParams.buildingState })
                      }}
                    />
                }
                {
                  props.data.inputParams.additionalParams.length > 0 &&
                    <Trans
                      i18nKey={'report:details_additional_params'}
                      values={{
                        params: additionalParamsText
                      }}
                    />
                }
              </Typography>
            </Box>
            <Typography
              fontFamily={'AvenirMedium'}
              fontSize={'.75rem'}
              color={'rgba(32, 32, 32, 0.5)'}
            >
              {texts('report:tile_generated_time_label', { time: moment(props.data.generatedAt).format('DD.MM.YYYY') })}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '8px'
            }}
          >
            {
              !props.data.removed
              ? <Button
                  variant='outlined'
                  onClick={() => window.open(props.data.fileUrl!)}
                  sx={{
                    fontFamily: 'AvenirMedium',
                    color: 'rgb(2, 13, 50)',
                    borderColor: 'rgb(2, 13, 50)',
                    fontSize: '.75rem',
                    letterSpacing: '0.26',
                    textTransform: 'none',
                    width: '11.25rem',
                    height: '1.875rem'
                  }}
                >
                  <LaunchIcon sx={{ marginRight: '.5rem', width: '1rem', height: '1rem' }} />
                  {texts('report:see_report_button')}
                </Button>
              : null
            }
            {
              props.adminView || props.data.removed
                ? <Box height={'1.875rem'}></Box>
                : <Button
                    variant='outlined'
                    onClick={() => props.onDelete?.(props.data.uuid)}
                    sx={{
                      fontFamily: 'AvenirMedium',
                      color: 'rgb(224, 32, 32)',
                      borderColor: 'rgb(224, 32, 32)',
                      fontSize: '.75rem',
                      letterSpacing: '0.26',
                      textTransform: 'none',
                      width: '11.25rem',
                      height: '1.875rem'
                    }}
                  >
                    <DeleteOutlineIcon sx={{ marginRight: '.5rem', width: '1rem', height: '1rem' }} />
                    {texts("report:delete_report_button")}
                  </Button>
            }
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}