import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../CustomMui/CustomDialog/CustomDialog'
import { isSubAccount } from '../../services/storage/StorageService'

type Props = {
  onClose: () => void
  open: boolean
}

export default function NoReportsLeftDialog(props: Props) {
  const texts = useTranslation().t

  const handleClose = () => props.onClose()

  return (
    <CustomDialog
      title={texts('report:no_reports_left_title')}
      labels={{
        confirm: texts('report:ok_got_it')
      }}
      handleClose={handleClose}
      handleConfirm={() => handleClose()}
      open={props.open} 
    >
      <Typography
        fontSize={'.875rem'}
        color={'rgb(32, 32, 32)'}
      >
        {texts(isSubAccount() ? 'report:no_report_left_sub_account_message' : 'report:no_report_left_message')}
      </Typography>
    </CustomDialog>
  )
}
