import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { useEffect, useRef } from 'react'

function ignoreWheel(e: Event) {
  e.preventDefault()
}

export default function TextField(props: TextFieldProps) {
  const [field, meta] = useField(props.name!)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    ...(props.onChange ? {
      onChange: (event) => {
        props.onChange?.(event)
        field.onChange(event)
      }
    } : {}),
    fullWidth: true,
    variant: 'outlined'
  }

  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('wheel', ignoreWheel)
    }
  }, [ref])

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  } else {
    configTextField.helperText = ' '
  }

  return (
    <MuiTextField 
      {...configTextField} 
      ref={ref}
      sx={{
        marginBottom: '.5rem',
        ...props.sx
      }}
    />
  )
}
