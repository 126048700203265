import { ListItem, ListItemText, Typography } from '@mui/material'

type Props = { 
  text: string
  open: boolean 
}

export default function DrawerListSection(props: Props) {
  return (
    <ListItem disablePadding sx={{ display: 'block', marginBlock: '.5rem' }}>
      <ListItemText
        sx={{
          opacity: props.open ? 1 : 0,
          ml: '16px'
        }}
      >
        <Typography
          fontSize={'12px'}
          color={'rgba(255, 255, 255, 0.45)'}
          fontFamily={'AvenirHeavy'}
        >
          {props.text}
        </Typography>
      </ListItemText>
    </ListItem>
  )
}