import { ThemeProvider } from '@mui/material'
import { Navigate, Route, Routes, useNavigate, Outlet } from 'react-router-dom'
import './App.css'
import Login from './pages/Auth/Login/LogIn'
import MainTheme from './components/themes/MainTheme'
import PasswordReset from './pages/Auth/PasswordReset/PasswordReset'
import FinishPasswordReset from './pages/Auth/FinishPasswordReset/FinishPasswordReset'
import ResendActivationEmail from './pages/Auth/ResendActivationEmail/ResendActivationEmail'
import AccountActivation from './pages/Auth/AccountActivation/AccountActivation'
import toast, { Toaster, ToastBar } from 'react-hot-toast'
import store from './redux/store'
import { forceLogOut, LoginState } from './redux/storageToolkit'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import Dashboard from './pages/Dashboard/Dashboard'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { LicenseInfo } from '@mui/x-license-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { pl } from 'date-fns/locale'
import { getUserUuid } from './services/storage/StorageService'
import useSessionCheck from './hooks/auth/useSessionCheck'
import { queryNames } from './hooks/queries'
import ReactGA from 'react-ga4'

export const ROUTER_BASE_NAME = '/'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '')

if (process.env.REACT_APP_GA_ENABLED === 'true' && process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize([{ trackingId: process.env.REACT_APP_GA_TRACKING_ID, gtagOptions: { send_page_view: false }}])
}

function App() {
  const navigate = useNavigate()
  const texts = useTranslation().t
  const queryClient = useQueryClient()
  const loginStateRef = useRef<LoginState | null>(null)

  useSessionCheck({
    refetchInterval: Number.parseInt(process.env.REACT_APP_SESSION_RECHECK_DELAY_MS!),
    enabled: store.getState().storage.loginState === LoginState.LOGGED
  })

  const handleLoginStateChange = () => {
    const previousValue = loginStateRef.current
    loginStateRef.current = store.getState().storage.loginState

    if (previousValue === loginStateRef.current) { // stan dla nas sie nie zmienił
      return
    }

    switch (store.getState().storage.loginState) {
      case LoginState.FORCED_LOGGED_OUT:
        toast.success(texts('successes:automatically_logged_out'))
        queryClient.cancelQueries([queryNames.sessionCheck])
        navigate('/login')
        break
      case LoginState.LOGGED_OUT:
        queryClient.cancelQueries([queryNames.sessionCheck])
        navigate('/login')
        break
    }
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      handleLoginStateChange()
    })

    const handleCrossTabLogout = () => {
      if (store.getState().storage.loginState === LoginState.LOGGED && !getUserUuid()) {
        store.dispatch(forceLogOut())
      }
    }
    window.addEventListener('storage', handleCrossTabLogout) 

    return () => {
      window.removeEventListener('storage', handleCrossTabLogout)
      unsubscribe()
    }
  }, [navigate, texts])

  // rozwiazuje problem wylogowania z innej karty na widokach nie wywołujących requestów na serwer
  useEffect(() => {
    console.log(process.env.REACT_APP_VERSION)
    const handleCrossTabLogOut = () => {
      if (store.getState().storage.loginState === LoginState.LOGGED && !getUserUuid()) {
        store.dispatch(forceLogOut())
      }
    }

    window.addEventListener('click', handleCrossTabLogOut)
    window.addEventListener('keydown', handleCrossTabLogOut)

    return () => {
      window.removeEventListener('click', handleCrossTabLogOut)
      window.removeEventListener('keydown', handleCrossTabLogOut)
    }
  }, [])

  const loggedRoutes = () => {
    return (
      <>
        <Route path={'/*'} element={<Dashboard />} />

        <Route path={'/login'} element={<Navigate to={ROUTER_BASE_NAME} />} />
        <Route path='/password/reset' element={<Navigate to={ROUTER_BASE_NAME} />}>
          <Route path='request' element={<Navigate to={ROUTER_BASE_NAME} />} />
          <Route path='finish' element={<Navigate to={ROUTER_BASE_NAME} />} />
        </Route>
        <Route path='/account' element={<Navigate to={ROUTER_BASE_NAME} />}>
          <Route path='activation/resend' element={<Navigate to={ROUTER_BASE_NAME} />} />
          <Route path='activate' element={<Navigate to={ROUTER_BASE_NAME} />} />
        </Route>
      </>
    )
  }

  const unLoggedRoutes = () => {
    return (
      <>
        <Route path='/' element={<Outlet />}>
          <Route index element={<Navigate to={'/login'} />} />
          <Route path={'/login'} element={<Login />} />
          <Route path='/password/reset'>
            <Route path='request' element={<PasswordReset />} />
            <Route path='finish' element={<FinishPasswordReset />} />
          </Route>
          <Route path='/account'>
            <Route path='activation/resend' element={<ResendActivationEmail />} />
            <Route path='activate' element={<AccountActivation />} />
          </Route>
          <Route path={'*'} element={<Navigate to={'/login'} />}
          />
        </Route>
      </>
    )
  }

  return (
    <>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
          <ThemeProvider theme={MainTheme}>
            <Toaster
              position={store.getState().storage.loginState === LoginState.LOGGED ? 'top-right' : 'top-left'}
              containerStyle={{
                fontFamily: 'AvenirBook',
                top: '2rem',
                inset: '4rem 1rem 1rem'
              }}
              toastOptions={{
                success: {
                  duration: 4000,
                  style: {
                    background: '#558b11',
                    color: 'white'
                  },
                  icon: (<TaskAltIcon sx={{ color: 'white' }} />)
                },
                error: {
                  duration: 4000,
                  style: {
                    background: 'rgba(211, 47, 47, 0.9)',
                    color: 'white'
                  },
                  icon: (<ErrorOutlineIcon sx={{ color: 'white' }} />)
                }
              }}
            >
              {(t) => (
                <ToastBar
                  toast={t}
                  style={{
                    ...t.style,
                    animation: t.visible
                      ? `toast-enter-${store.getState().storage.loginState === LoginState.LOGGED ? 'right' : 'left'} 1s ease`
                      : `toast-exit-${store.getState().storage.loginState === LoginState.LOGGED ? 'right' : 'left'} 1s ease`,
                  }}
                />
              )}
            </Toaster>
            <Routes>
              {
                store.getState().storage.loginState === LoginState.LOGGED
                  ? loggedRoutes()
                  : unLoggedRoutes()
              }
            </Routes>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryParamProvider>
    </>
  )
}

export default App
