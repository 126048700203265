import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import AuthApi from '../../api/auth/AuthApi'
import { LoginDto } from '../../api/auth/types'
import { logIn } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export default function useLogin() {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const mutation = useMutation((data: LoginDto) => authApi.login(data), {
    onSuccess: (data) => {
      store.dispatch(logIn(data))
      navigate('/', { replace: true })
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    },
  })
  return mutation
}