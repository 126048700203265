import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { AccountListQueryDto } from '../../api/data/types'
import { getUserOrgUuid, getUserUuid } from '../../services/storage/StorageService'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useFetchMembers(data: AccountListQueryDto) {
  const texts = useTranslation().t
  const query = useQuery(
    [queryNames.fetchMembersList, getUserOrgUuid(), getUserUuid(), data.order, data.limit, data.offset, data.search, data.status],
    () => dataApi.getOrganizationMembers(getUserOrgUuid() ?? '', data),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onError: (error: AxiosError<HttpError>) => {
        showErrorToast(error, texts)
      }
    })
  return query
}