import { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography, Divider, Box } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router'
import { AccountDto } from '../../api/data/types'

type Props = {
  row: AccountDto
  onChangeActiveStateSelected: (row: AccountDto) => void
  onAddPackage: (row: AccountDto) => void
  onEditAccountNameSelected: (row: AccountDto) => void
  onChangeTypeSelected: (row: AccountDto) => void
}

function MenuDrawer(props: Props) {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'center'}
        width={'100%'}
      >
        <IconButton
          aria-label={'more'}
          id={'long-button'}
          aria-haspopup={'true'}
          onClick={handleClick}
          sx={{
            padding: 0
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: '190px'
          },
        }}
      >
        <MenuItem 
          onClick={() => { 
            handleClose()
            props.onAddPackage(props.row)
          }} 
          disableRipple
        >
          <AddIcon sx={{ marginRight: '.5rem', width: '1rem' }} />
          <Typography 
            fontFamily={'AvenirHeavy'}
            fontSize={'.75rem'}
            color={'rgb(32, 32, 32)'}
            lineHeight={'1rem'}
          >
            {texts('account:add_packet')}
          </Typography>
        </MenuItem>
        <Divider sx={{ mx: '.5rem !important', my: '0.25rem !important' }} />
        <MenuItem 
          onClick={() => { 
            handleClose()
            navigate(`/reports/admin/${props.row.organization.uuid}`)
          }} 
          disableRipple
        >
          <Typography fontSize={'.75rem'} color={'rgb(32, 32, 32)'}>
            {texts('account:check_reports')}
          </Typography>
        </MenuItem>
        <MenuItem 
          onClick={() => { 
            handleClose()
            props.onEditAccountNameSelected(props.row)
          }} 
          disableRipple
        >
          <Typography fontSize={'.75rem'} color={'rgb(32, 32, 32)'}>
            {texts('account:edit_name')}
          </Typography>
        </MenuItem>
        <MenuItem 
          onClick={() => { 
            handleClose()
            props.onChangeTypeSelected(props.row)
          }} 
          disableRipple
        >
          <Typography fontSize={'.75rem'} color={'rgb(32, 32, 32)'}>
            {texts('account:change_account_type')}
          </Typography>
        </MenuItem>
        {
          props.row.status !== 0
            ? <MenuItem 
                onClick={() => {
                  handleClose()
                  props.onChangeActiveStateSelected(props.row)
                }}
                disableRipple
              >
                <Typography fontSize={'.75rem'} color={'rgb(224, 32, 32)'}>
                  {texts('account:deactivate')}
                </Typography>
              </MenuItem>
            : <MenuItem 
                onClick={() => {
                  handleClose()
                  props.onChangeActiveStateSelected(props.row)
                }}
                disableRipple
              >
                <Typography fontSize={'.75rem'} color={'#008000'}>
                  {texts('account:activate')}
                </Typography>
              </MenuItem>
        }
      </Menu>
    </>
  )
}

export default MenuDrawer