import { LoadingButton } from '@mui/lab'
import { Box, Grid, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LoginDto } from '../../../api/auth/types'
import PasswordField from '../../../components/CustomMui/Fields/PasswordField'
import TextField from '../../../components/CustomMui/Fields/TextField'
import useLogin from '../../../hooks/auth/useLogin'
import { logInValidation } from '../../../utils/validations/logInValidation'
import AuthLayout from '../../../components/AuthLayout/AuthLayout'
import AuthIcon from '../../../assets/images/AuthIcon.svg'
import useGaPageView from '../../../hooks/google/useGaPageView'

export default function Login() {
  const texts = useTranslation().t

  const { mutate, isLoading } = useLogin()

  useGaPageView('Login')

  const handleSubmitLogin = async (data: LoginDto, formikHelpers: FormikHelpers<LoginDto>) => {
    mutate(data, {
      onError: () => {
        formikHelpers.setFieldValue('password', '')
      }
    })
  }

  return (
    <AuthLayout>
      <Grid
        container
        direction={'column'}
        height={'100%'}
        width={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        maxWidth={'40rem'}
        mx={'auto'}
      >
        <Box
          component={'img'}
          alt='logo'
          src={AuthIcon}
          style={{
            height: '32px',
            position: 'relative',
            marginBottom: '43px'
          }}
        />
        <Typography
          variant='h1'
          sx={{ marginBottom: '2.75rem', textAlign: 'center' }}
        >
          {texts('auth:sign_in_title')}
        </Typography>
        <Formik<LoginDto>
          initialValues={{ login: '', password: '' }}
          onSubmit={handleSubmitLogin}
          validationSchema={logInValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <Grid
                container
                width={'25rem'}
              >
                <TextField
                  name='login'
                  type='email'
                  label={texts('auth:email_address')}
                />
                <PasswordField
                  name='password'
                  label={texts('auth:password')}
                  fullWidth
                />
                <Link
                  to='/password/reset/request'
                  style={{
                    width: '100%',
                    textDecoration: 'none',
                    textAlign: 'right',
                    marginTop: '-0.75rem'
                  }}
                >
                  <Typography
                    variant='body1'
                    color={'rgba(32, 32, 32, 0.87)'}
                  >
                    {texts('auth:forgot_password')}
                  </Typography>
                </Link>
                <LoadingButton
                  disableElevation
                  fullWidth
                  type='submit'
                  variant={'contained'}
                  loading={isLoading}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  sx={{
                    fontFamily: 'AvenirHeavy',
                    height: '36px',
                    color: 'white',
                    textTransform: 'none',
                    marginTop: '1rem'
                  }}
                >
                  {texts('auth:sign_in')}
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
        <Typography
          variant='body1'
          color='primary'
          mt={'2rem'}
          textAlign={'center'}
          lineHeight={'24px'}
        >
          <Box
            component={'span'}
            color={'rgba(32, 32, 32, 0.87)'}
          >
            {texts('auth:resend_activation_email_title')}
          </Box>
          <Link
            to='/account/activation/resend'
            style={{ 
              textDecoration: 'none',
              fontFamily: 'AvenirHeavy'
            }}
          >
            <Box
              component={'span'}
              sx={{ color: 'primary.main' }}
            >
              {texts('auth:resend_activation_email_label')}
            </Box>
          </Link>
        </Typography>
      </Grid>
    </AuthLayout>
  )
}