import * as Yup from 'yup'
import { emailRegexp } from './validationRegexps'
import { TFunction, Namespace } from 'i18next'

export function resetPasswordRequestValidation(t: TFunction<Namespace>) {
  return Yup.object({
    email: Yup.string()
      .required(t('validations:field_required'))
      .matches(emailRegexp, t('validations:invalid_email_validation')),
  })
}

