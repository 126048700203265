import { CircularProgress, Grid } from '@mui/material'

type Props = {
  height?: string
  width?: string
  justifyContent?: string
  alignItems?: string
}

function Loading(props: Props) {
  return (
    <Grid
      container
      height={props.height ?? 'calc(100vh - 220px)'}
      width={props.width ?? '100%'}
      justifyContent={props.justifyContent ?? 'center'}
      alignItems={props.alignItems ?? 'center'}
    >
      <CircularProgress />
    </Grid> 
  )
}

export default Loading