import AuthLayout from '../../../components/AuthLayout/AuthLayout'
import { Form, Formik } from 'formik'
import { Box, Grid, Typography, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Password } from '../../../types/auth'
import PasswordField from '../../../components/CustomMui/Fields/PasswordField'
import { LoadingButton } from '@mui/lab'
import { passwordValidation } from '../../../utils/validations/resetPasswordValidation'
import { Link } from 'react-router-dom'
import useCheckToken from '../../../hooks/auth/useCheckPasswordToken'
import useFinishPasswordReset from '../../../hooks/auth/useFinishPasswordReset'
import { useEffect } from 'react'
import AuthIcon from '../../../assets/images/AuthIcon.svg'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import useGaPageView from '../../../hooks/google/useGaPageView'
import {
  StringParam,
  useQueryParam,
  withDefault
} from 'use-query-params'

export default function ResetPassword() {
  const texts = useTranslation().t

  useGaPageView('ResetPasswordFinalize')

  const [token] = useQueryParam('token', withDefault(StringParam, null))
  const resetPassword = useFinishPasswordReset()
  const {mutate: checkToken, isLoading} = useCheckToken()

  useEffect(() => {
    checkToken({ token })
  }, [])

  const handleSubmit = (data: Password) => {
    resetPassword.mutate({
      password: data.password,
      token: token
    })
  }

  return (
    <AuthLayout>
      <Grid
        container
        direction={'column'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        maxWidth={'30rem'}
        mx={'auto'}
      >
        {
          isLoading
            ? <CircularProgress />
            : <>
                <Box
                  component={'img'}
                  alt='logo'
                  src={AuthIcon}
                  style={{
                    height: '32px',
                    position: 'relative',
                    marginBottom: '43px'
                  }}
                />
                <Typography
                  variant='h1'
                  sx={{ marginBottom: '2.75rem', textAlign: 'center' }}
                >
                  {texts('auth:create_new_password_title')}
                </Typography>
                <Formik<Password>
                  initialValues={{
                    password: ''
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={passwordValidation(texts)}
                >
                  {(formikProps) => (
                    <Form>
                      <Grid
                        container
                        justifyContent={'flex-end'}
                        width={'25rem'}
                      >
                        <PasswordField
                          fullWidth
                          name='password'
                          autoComplete='new-password'
                          label={texts('auth:password')}
                        />
                        <LoadingButton
                          fullWidth
                          disableElevation
                          type='submit'
                          variant={'contained'}
                          disabled={!formikProps.isValid || !formikProps.dirty}
                          sx={{
                            fontFamily: 'AvenirHeavy',
                            height: '36px',
                            color: 'white',
                            minWidth: '12rem',
                            textTransformation: 'none',
                            textTransform: 'none'
                          }}
                          loading={resetPassword.isLoading}
                        >
                          {texts('common:save')}
                        </LoadingButton>
                        <Link
                          to='/login'
                          style={{
                            textDecoration: 'none',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '1.25rem'
                          }}
                        >
                          <Box
                            display={'flex'}
                            sx={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '8px',
                              '&:hover': {
                                animation: 'moveAside 300ms 1 ease-in-out forwards'
                              }
                            }}
                          >
                            <ArrowBackRoundedIcon
                              fontSize='small'
                              sx={{ color: 'rgb(32, 32, 32)' }}
                            />
                            <Typography
                              variant='body1'
                              textAlign={'center'}
                              lineHeight={'24px'}
                              color={'rgba(32, 32, 32, 0.87)'}
                            >
                              {texts('auth:login_redirect')}
                            </Typography>
                          </Box>
                        </Link>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </>
        }
      </Grid>
    </AuthLayout>
  )
}
