import { AuthDto, ContactData, PackageData } from '../../api/auth/types'
import { PermissionName, RoleName } from '../../utils/const'

export const USER_UUID = 'user_uuid'
export const USER_EMAIL = 'user_email'
export const USER_NAME = 'user_name'
export const USER_ORG_CUSTOMIZED = 'user_org_customized'
export const USER_ORG_UUID = 'user_org_uuid'
export const USER_ROLE = 'user_role'
export const USER_PERMISSIONS = 'user_permissions'
export const USER_CONTACT = 'user_contact'
export const USER_PACKAGE = 'user_package'

export function setUserData(data: AuthDto) {
  localStorage.setItem(USER_UUID, data.uuid)
  localStorage.setItem(USER_EMAIL, data.email)
  localStorage.setItem(USER_NAME, data.name)
  localStorage.setItem(USER_ORG_CUSTOMIZED, data.organization.isCustomized.toString())
  localStorage.setItem(USER_ROLE, JSON.stringify(data.roles))
  localStorage.setItem(USER_PERMISSIONS, JSON.stringify(data.permissions))
  localStorage.setItem(USER_CONTACT, JSON.stringify(data.contactDetails))
  localStorage.setItem(USER_PACKAGE, JSON.stringify(data.package))
  localStorage.setItem(USER_ORG_UUID, data.organization.uuid)
}

export function setUserSessionCheckData(data: AuthDto) {
  localStorage.setItem(USER_UUID, data.uuid)
  localStorage.setItem(USER_EMAIL, data.email)
  localStorage.setItem(USER_NAME, data.name)
  localStorage.setItem(USER_ROLE, JSON.stringify(data.roles))
  localStorage.setItem(USER_PERMISSIONS, JSON.stringify(data.permissions))
  localStorage.setItem(USER_CONTACT, JSON.stringify(data.contactDetails))
  localStorage.setItem(USER_PACKAGE, JSON.stringify(data.package))
  localStorage.setItem(USER_ORG_UUID, data.organization.uuid)
}

export function clearUserData() {
  localStorage.removeItem(USER_UUID)
  localStorage.removeItem(USER_EMAIL)
  localStorage.removeItem(USER_NAME)
  localStorage.removeItem(USER_ORG_CUSTOMIZED)
  localStorage.removeItem(USER_ORG_UUID)
  localStorage.removeItem(USER_ROLE)
  localStorage.removeItem(USER_PERMISSIONS)
  localStorage.removeItem(USER_CONTACT)
  localStorage.removeItem(USER_PACKAGE)
}

export function setUserEmail(email: string) {
  localStorage.setItem(USER_EMAIL, email)
}

export function clearUserEmail() {
  localStorage.removeItem(USER_EMAIL)
}

export function getUserName(): string | null {
  return localStorage.getItem(USER_NAME)
}

export function getUserEmail(): string | null {
  return localStorage.getItem(USER_EMAIL)
}

export function setUserName(name: string) {
  localStorage.setItem(USER_NAME, name)
}

export function getUserUuid(): string | null {
  return localStorage.getItem(USER_UUID)
}

export function getUserOrgCustomized(): boolean {
  return localStorage.getItem(USER_ORG_CUSTOMIZED) === 'true'
}

export function setUserConfiguredOrg(newState: boolean) {
  localStorage.setItem(USER_ORG_CUSTOMIZED, JSON.stringify(newState))
}

export function getUserContactDetails(): ContactData | null {
  const data = localStorage.getItem(USER_CONTACT)
  return data ? JSON.parse(data) : null
}

export function getUserPackageData(): PackageData | null {
  const data = localStorage.getItem(USER_PACKAGE)
  return data ? JSON.parse(data) : null
}

export function setUserContactDetails(data: ContactData) {
  localStorage.setItem(USER_CONTACT, JSON.stringify(data))
}

export function setUserPackageData(data: PackageData) {
  localStorage.setItem(USER_PACKAGE, JSON.stringify(data))
}

export function getUserRoles(): string[] | null {

  const data = localStorage.getItem(USER_ROLE)

  if (data === null) {
    return null
  }

  return JSON.parse(data)
}

export function getUserPermissions(): string[] | null {

  const data = localStorage.getItem(USER_PERMISSIONS)

  if (data === null) {
    return null
  }

  return JSON.parse(data)
}

export function getUserOrgUuid(): string | null {
  return localStorage.getItem(USER_ORG_UUID)
}

export function clearUserCache() {
  clearUserData()
}

export function isPremiumAccount(): boolean {
  const data = localStorage.getItem(USER_ROLE)
  if (data === null) {
    return false
  }

  const roles: string[] = JSON.parse(data)
  return roles.includes(RoleName.PREMIUM_MAIN_ACCOUNT) || roles.includes(RoleName.PREMIUM_SUB_ACCOUNT)
}

export function isPremiumMainAccount(): boolean {
  const data = localStorage.getItem(USER_ROLE)
  if (data === null) {
    return false
  }

  const roles: string[] = JSON.parse(data)
  return roles.includes(RoleName.PREMIUM_MAIN_ACCOUNT)
}

export function isSubAccount(): boolean {
  const data = localStorage.getItem(USER_ROLE)
  if (data === null) {
    return false
  }

  const roles: string[] = JSON.parse(data)
  return roles.includes(RoleName.SUB_ACCOUNT)
}

export function isMainAccount(): boolean {
  const data = localStorage.getItem(USER_ROLE)
  if (data === null) {
    return false
  }

  const roles: string[] = JSON.parse(data)
  return roles.includes(RoleName.MAIN_ACCOUNT) || roles.includes(RoleName.PREMIUM_MAIN_ACCOUNT)
}

export function isSystemAdmin(): boolean {
  const data = localStorage.getItem(USER_ROLE)
  if (data === null) {
    return false
  }

  const roles: string[] = JSON.parse(data)
  return roles.includes(RoleName.SYSTEM_ADMIN)
}

export function isReportCreator(): boolean {
  const data = localStorage.getItem(USER_ROLE)
  if (data === null) {
    return false
  }

  const roles: string[] = JSON.parse(data)
  return roles.includes(RoleName.REPORT_CREATOR)
}

export function canEditOrganization(): boolean {
  const data = localStorage.getItem(USER_PERMISSIONS)
  if (data === null) {
    return false
  }

  const roles: string[] = JSON.parse(data)
  return roles.includes(PermissionName.EDIT_ORGANIZATION)
}