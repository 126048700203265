import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { ReportListQueryDto } from '../../api/data/types'
import { getUserUuid } from '../../services/storage/StorageService'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useFetchReportList(data: ReportListQueryDto) {
  const texts = useTranslation().t
  const query = useQuery(
    [queryNames.fetchReportList, getUserUuid(), data.search, data.offset, data.limit, data.from, data.to],
    () => dataApi.getReportList(data),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onError: (error: AxiosError<HttpError>) => {
        showErrorToast(error, texts)
      },
      enabled: Boolean(getUserUuid())
    }
  )
  return query
}