import { Autocomplete as MuiAutocomplete, AutocompleteProps, SxProps } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { SelectOption } from '../../../types/common'
import TextField from './TextField'

type AdditionalProps = {
  width?: number
  margin?: string
  name: string
  label: string
  inputSx?: SxProps
}

export default function SelectField(
  props: Omit<AutocompleteProps<SelectOption, boolean | undefined, boolean | undefined, boolean | undefined>, 'sx' | 'renderInput'> & AdditionalProps
) {
  const texts = useTranslation().t
  const [field] = useField(props.name!)
  const { setFieldTouched, setFieldValue } = useFormikContext()

  return (
    <MuiAutocomplete 
      {...field}
      {...props}
      sx={{
        width: props.width
      }}
      value={props.options.find(e => e.value === field.value) ?? null}
      onChange={(e, val, reason, details) => {
        setFieldTouched(props.name, true)
        setFieldValue(props.name, (val as SelectOption)?.value)
        props.onChange?.(e, val, reason, details)
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          label={props.label}
          sx={props.inputSx}
          onKeyPress={(e) => {e.preventDefault()}}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        } else {
          return option.i18 ? texts(option.i18!.text, option.i18!.params) as string : option.label ?? ''
        }
      }}
    />
  )
}