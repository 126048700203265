import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { AccountListQueryDto } from '../../api/data/types'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useFetchAccounts(data: AccountListQueryDto) {
  if(!data.search) data.search = undefined 

  const texts = useTranslation().t
  const query = useQuery(
    [queryNames.fetchAdminAccountList, data.order, data.limit, data.offset, data.search, data.status],
    () => dataApi.getAdminAccountList(data),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      onError: (error: AxiosError<HttpError>) => {
        showErrorToast(error, texts)
      },
    })
  return query
}