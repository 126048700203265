import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { OrganizationMemberDto, AccountDto } from '../../api/data/types'
import useUpdateAccount from '../../hooks/data/useUpdateAccount'
import { UpdateAccountNameFormData } from '../../types/form'
import { editAccessNameValidation } from '../../utils/validations/editAccessNameValidation'
import CustomDialog from '../CustomMui/CustomDialog/CustomDialog'
import TextField from '../CustomMui/Fields/TextField'

type Props = {
  open: boolean
  accountData: OrganizationMemberDto | AccountDto | null
  onClose: () => void
  onSuccess: () => void
}

export default function UpdateAccountNameDialog(props: Props) {
  const texts = useTranslation().t

  const handleClose = () => props.onClose()

  const { mutate, isLoading } = useUpdateAccount(props.accountData?.uuid ?? '')

  const handleSubmit = (data: UpdateAccountNameFormData) => {
    mutate(data, {
      onSuccess: () => { 
        props.onSuccess()
        handleClose()
      }
    })
  }

  return (
    <CustomDialog
      title={texts('agency:update_name_title')}
      handleClose={handleClose}
      open={props.open} 
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
      >
        <Formik<UpdateAccountNameFormData>
          initialValues={{ name: props.accountData?.name ?? '' }}
          onSubmit={handleSubmit}
          validationSchema={editAccessNameValidation(texts)}
        >
          {(formikProps) => (
            <Form 
              style={{ 
                width: '25.5rem',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem'
              }}
            >
              <TextField
                name={'name'}
                label={texts('agency:name_input')}
              />
              <Box 
                display={'flex'}
                height={'2.25rem'}
                gap={'.5rem'}
                justifyContent={'flex-end'}
              >
                <Button
                  variant={'outlined'}
                  onClick={() => handleClose()}
                  sx={{
                    color: 'rgb(2, 13, 50)',
                    borderColor: 'rgb(2, 13, 50)',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirMedium',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('agency:cancel')}
                </Button>
                <LoadingButton
                  variant={'contained'}
                  type={'submit'}
                  loading={isLoading}
                  disableElevation
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  sx={{
                    color: 'white',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirHeavy',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('agency:save')}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CustomDialog>
  )
}