import { Success } from '../../types/common'
import { Api, BaseResponse } from '../Api'
import { AccountListDto, EditMyAccountDto, GenerateReportDto, OrganizationInfoDto, ReportProceedDto, SelectedAdvertListDto, UpdateAccountDto, UpdateAccountTypeDto, UpdateOrgPackageDto } from './types'
import {
  CreateAccountDto,
  EditAgencyDto, GenerateReportResponseDto, GenerateReportFormData, OrderedListQueryDto, OrganizationDetailsDto,
  OrganizationMemberDto,
  OrganizationMemberListDto, PackagesListDto, ReportDto, ReportListDto, ReportListQueryDto, RoleDto
} from './types'

class DataApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_DATA}/api/v1/`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
  private static instance: DataApi

  static getInstance(): DataApi {
    if (DataApi.instance) {
      return this.instance
    }
    return this.instance = new DataApi()
  }

  public getReportDetails(uuid: string): Promise<ReportDto> {
    return this.get<ReportDto>(`/report/details/${uuid}`)
  }

  public getReportList(data: ReportListQueryDto): Promise<ReportListDto> {
    return this.get<ReportListDto>(`/report/list`, { params: data })
  }

  public reportProceed(data: GenerateReportFormData): Promise<ReportProceedDto> {
    return this.post<ReportProceedDto>(`/report/flat/proceed`, data)
  }

  public generateReport(data: GenerateReportDto): Promise<GenerateReportResponseDto> {
    return this.post<GenerateReportResponseDto>(`/report/flat/generate`, data)
  }

  public deleteReport(uuid: string): Promise<BaseResponse> {
    return this.delete<BaseResponse>(`/report/${uuid}`)
  }

  public getOrganizationReportList(organizationUuid: string, data: ReportListQueryDto): Promise<ReportListDto> {
    return this.get<ReportListDto>(`/organization/${organizationUuid}/reports`, { params: data })
  }

  public getOrganizationDetails(uuid: string): Promise<OrganizationDetailsDto> {
    return this.get<OrganizationDetailsDto>(`/organization/${uuid}`)
  }

  public getOrganizationMembers(uuid: string, data: OrderedListQueryDto): Promise<OrganizationMemberListDto> {
    return this.get<OrganizationMemberListDto>(`/organization/${uuid}/accounts`, { params: data })
  }

  public updateOrganization(uuid: string, data: EditAgencyDto): Promise<OrganizationInfoDto> {
    const formData = new FormData()
    if (data.logo != null) {
      formData.append('logo', data.logo)
    }
    formData.append('data', JSON.stringify({
      contactDetails: data.contactDetails, 
      report: data.report, 
      removeLogo: data.removeLogo
    }))
    return this.patch<OrganizationInfoDto>(`/organization/${uuid}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  public getMainRoles(): Promise<RoleDto[]> {
    return this.get<RoleDto[]>(`/role/list`)
  }

  public getActivePackages(): Promise<PackagesListDto> {
    return this.get<PackagesListDto>(`/package/list`)
  }

  public updateOrganizationPackage(uuid: string, data: UpdateOrgPackageDto): Promise<Success> {
    return this.put<Success>(`/organization/${uuid}/package`, JSON.stringify(data))
  }

  public removeOrganizationPackage(uuid: string): Promise<Success> {
    return this.delete<Success>(`/organization/${uuid}/package`)
  }

  public createAccount(data: CreateAccountDto): Promise<OrganizationMemberDto> {
    return this.post<OrganizationMemberDto>('/account/create', JSON.stringify(data))
  }

  public updateSubAccount(uuid: string, data: UpdateAccountDto): Promise<Success> {
    return this.patch<Success>(`/account/${uuid}/edit`, JSON.stringify(data))
  }

  public updateMyAccount(data: EditMyAccountDto): Promise<Success> {
    return this.patch<Success>('/account/edit', JSON.stringify(data))
  }

  public getAdminAccountList(data: OrderedListQueryDto): Promise<AccountListDto> {
    return this.get<AccountListDto>('/account/list', { params: data })
  }

  public updateAccountRole(uuid: string, data: UpdateAccountTypeDto): Promise<Success> {
    return this.patch<Success>(`/account/${uuid}/role/change`, JSON.stringify(data))
  }

  public getSelectedAdverts(reportUuid: string): Promise<SelectedAdvertListDto> {
    return this.get<SelectedAdvertListDto>(`/report/flat/${reportUuid}/selected-adverts`)
  }
}

export default DataApi