import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { AccountDto } from '../../api/data/types'
import useFetchPackages from '../../hooks/data/useFetchPackages'
import useRemoveOrganizationPackage from '../../hooks/data/useRemoveOrganizationPackage'
import useUpdateOrganizationPackage from '../../hooks/data/useUpdateOrganizationPackage'
import { UpdateOrgPackageFormData } from '../../types/form'
import { noPackageOption } from '../../utils/const'
import { mapPackageDtoIntoSelectOptions } from '../../utils/converters'
import { addPackageValidation } from '../../utils/validations/addPackageValidation'
import CustomDialog from '../CustomMui/CustomDialog/CustomDialog'
import FormikSelectField from '../CustomMui/Fields/FormikSelectField'
import Loading from '../CustomMui/Loading'
import toast from 'react-hot-toast'

type Props = {
  open: boolean
  accountData?: AccountDto | null
  onClose: () => void
}

export default function ChangePackageDialog(props: Props) {
  const texts = useTranslation().t

  const handleClose = () => props.onClose()

  const { data: packages, isError: isPackagesError, isLoading: isPackagesLoading } = useFetchPackages()
  const updateOrgPackageMutation = useUpdateOrganizationPackage(props.accountData?.organization.uuid ?? '')
  const removeOrgPackageMutation = useRemoveOrganizationPackage(props.accountData?.organization.uuid ?? '')

  const handleSubmit = (data: UpdateOrgPackageFormData) => {
    if (data.packageUuid !== noPackageOption.value) {
      updateOrgPackageMutation.mutate({
        packageUuid: data.packageUuid,
      }, {
        onSuccess: () => {
          toast.success(texts('successes:package_added'))
          handleClose()
        }
      })
    } else {
      removeOrgPackageMutation.mutate(undefined, {
        onSuccess: () => {
          toast.success(texts('successes:package_added'))
          handleClose()
        }
      })
    }
    
  }

  if (isPackagesLoading) {
    return <Loading />
  }

  if (isPackagesError) {
    return null
  }


  return (
    <CustomDialog
      title={texts('account:add_packet_title')}
      handleClose={handleClose}
      open={props.open} 
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
      >
        <Formik<Pick<UpdateOrgPackageFormData, 'packageUuid'>>
          initialValues={{ packageUuid: '' }}
          onSubmit={handleSubmit}
          validationSchema={addPackageValidation(texts)}
        >
          {(formikProps) => (
            <Form 
              style={{ 
                width: '25.5rem',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem'
              }}
            >
              <FormikSelectField
                name={'packageUuid'}
                label={texts('account:packet')}
                options={mapPackageDtoIntoSelectOptions(packages?.packages ?? [])}
                inputSx={{
                  '.MuiInputBase-input': {
                    fontSize: '.875rem !important',
                    transform: 'translate(0, 1px) scale(1)'
                  },
                  '.MuiInputBase-root': {
                    height: '2.5rem'
                  },
                  '.MuiInputLabel-root': {
                    fontSize: '1rem !important',
                    lineHeight: '1.5rem'
                  },
                  '.MuiInputLabel-shrink': {
                    fontSize: '.75rem !important',
                    lineHeight: '1.75rem'
                  }
                }}
              />
              <Box 
                display={'flex'}
                height={'2.25rem'}
                gap={'.5rem'}
                marginTop={'.5rem'}
                justifyContent={'flex-end'}
              >
                <Button
                  variant={'outlined'}
                  onClick={() => handleClose()}
                  sx={{
                    color: 'rgb(2, 13, 50)',
                    borderColor: 'rgb(2, 13, 50)',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirMedium',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('agency:cancel')}
                </Button>
                <LoadingButton
                  variant={'contained'}
                  disableElevation
                  loading={updateOrgPackageMutation.isLoading}
                  type={'submit'}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  sx={{
                    color: 'white',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirHeavy',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('agency:add')}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CustomDialog>
  )
}