import AssessmentIcon from '@mui/icons-material/Assessment'
import SettingsIcon from '@mui/icons-material/Settings'
import {
  Box, CssBaseline, CSSObject, Drawer as MuiDrawer, IconButton, List, styled, Theme
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from '../../../assets/images/Icon.svg'
import Logo from '../../../assets/images/Logo.svg'
import MyAgencyIcon from '../../../assets/images/MyAgencyIcon.svg'
import { drawerSelectedPaths } from '../../../utils/const'
import { isCurrentLocationInPaths } from '../../../utils/location'
import DrawerItem from './DrawerItem'
import DrawerListSection from './DrawerListSection'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: 'rgb(1, 13, 50)'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '56px',
  [theme.breakpoints.up('sm')]: {
    width: '56px',
  },
  backgroundColor: 'rgb(1, 13, 50)'
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    color: 'white',
    ...(open ? {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    } : {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

type Props = {
  open: boolean
  visible: boolean
  hideAdminRoutes: boolean
  hideAgencyRoutes: boolean
}

export default function MiniDrawer(props: Props) {
  const open = props.open
  const texts = useTranslation().t
  const navigate = useNavigate()
  const location = useLocation()
  
  return (
    <Box sx={{ display: props.visible ? 'none' : 'flex' }}>
      <CssBaseline />
      <Drawer
        variant='permanent'
        sx={{ backgroundColor: 'rgb(1, 13, 50)' }}
        open={open}
      >
        <IconButton
          onClick={() => navigate('/')}
          sx={{ 
            position: 'relative',
            justifyContent: open ? 'initial' : 'center',
          }}
        >
          <Box
            component={'img'}
            alt='logo'
            src={open ? Logo : Icon}
            style={{
              width: open ? '116px' : '32px',
              height: open ? '32px' : '32px',
              marginLeft: open ? '5.5px' : '4px',
              marginRight: 'auto',
              paddingTop: open ? 0 : '1px',
              paddingBottom: open ? 0 : '1px',
            }}
          />
        </IconButton>
        <List>
          <DrawerListSection
            open={open}
            text={texts('common:report_section')}
          />
          <DrawerItem 
            open={open}
            url={'/'}
            selected={isCurrentLocationInPaths(drawerSelectedPaths.reports.list, location)}
            icon={<AssessmentIcon style={{ fontSize: '26px' }}/>}
            text={texts('common:report_list')}
          />
          {
            props.hideAgencyRoutes 
              ? null
              : <>
                  <DrawerListSection
                    open={open}
                    text={texts('common:agency_section')}
                  />
                  <DrawerItem 
                    open={open}
                    url={'/agency'}
                    selected={isCurrentLocationInPaths(drawerSelectedPaths.agency.my, location)}
                    icon={
                      <Box
                        component={'img'}
                        src={MyAgencyIcon}
                        width={'20px'}
                        height={'20px'}
                      />    
                    }
                    text={texts('common:my_agency')}
                  />
                </>
          }
          {
            props.hideAdminRoutes ? null
              :
              <>
                <DrawerListSection
                  open={open}
                  text={texts('common:administration_section')}
                />
                <DrawerItem 
                  open={open}
                  url={'/accounts'}
                  selected={isCurrentLocationInPaths(drawerSelectedPaths.admin.accounts, location)}
                  icon={<SettingsIcon style={{ fontSize: '25px' }}/>}
                  text={texts('common:accounts')}
                />
              </>
          }
        </List>
      </Drawer>
    </Box >
  )
}
