import { Namespace, TFunction } from 'i18next'
import moment from 'moment-timezone'

export function mapExpirationDate(dateStr: string, translations: TFunction<Namespace>) {
  const date = moment(dateStr)
  const hoursRemaining = date.diff(moment(), 'hours')
  return translations('common:day_time_count', {
    days: Math.ceil(hoursRemaining/24),
    date: date.format('DD/MM/YYYY')
  })
}