import { AuthSubCodes, Codes, DataSubCodes, HttpStatus, Page } from './consts'
import { AxiosError } from 'axios'
import { HttpError } from '../../types/common'
import { PermissionName } from '../../utils/const'
import { TFuncKey, Namespace } from 'i18next'

export function mapHttpErrorMessage(e: AxiosError<HttpError>, page?: Page): TFuncKey<Namespace> {
  if (e.response?.status === HttpStatus.GATEWAY_TIMEOUT) {
    return 'errors:report_timeout'
  } else if (e.response?.status === HttpStatus.PAYLOAD_TOO_LARGE) {
    return 'errors:file_too_large'
  } else if (e.response?.data?.code === Codes.AUTH) {
    return handleAuthErrors(e, page)
  } else if (e.response?.data?.code === Codes.DATA) {
    return handleDataErrors(e)
  }

  return 'errors:something_went_wrong'
}

function handleAuthErrors(e: AxiosError<HttpError>, page?: Page): TFuncKey<Namespace> {
  let errorKey: TFuncKey<Namespace> = 'errors:something_went_wrong'

  switch (true) {
    case e.response?.data?.subcode === AuthSubCodes.INVALID_CREDENTIALS:
      errorKey = 'errors:invalid_credentials'
      break
    case e.response?.data?.subcode === AuthSubCodes.UNAUTHORIZED:
      errorKey = 'errors:unauthorized'
      break
    case e.response?.data?.subcode === AuthSubCodes.USER_INACTIVE:
      errorKey = 'errors:user_inactive'
      break
    case e.response?.data?.subcode === AuthSubCodes.ACCOUNT_REMOVED:
      errorKey = 'errors:account_removed'
      break
    case e.response?.data?.subcode === AuthSubCodes.INVALID_TOKEN:
      errorKey = 'errors:invalid_token'
      break
    case e.response?.data?.subcode === AuthSubCodes.INCORRECT_PASSWORD:
      errorKey = 'errors:invalid_password'
      break
    case e.response?.data?.subcode === AuthSubCodes.SAME_PASSWORD_AS_PREVIOUS_ONE:
      errorKey = 'errors:same_password_as_previous_one'
      break
    case e.response?.data?.subcode === AuthSubCodes.TOKEN_ALREADY_USED:
      errorKey = 'errors:token_already_used'
      break
    case e.response?.data?.subcode === AuthSubCodes.FORBIDDEN && (e.response.data.missingPermissions ?? []).includes(PermissionName.ADD_CLIENT_NAME):
      errorKey = 'errors:personalization_client_name_not_available'
      break
    case e.response?.data?.subcode === AuthSubCodes.FORBIDDEN && (e.response.data.missingPermissions ?? []).includes(PermissionName.EDIT_ACCOUNT_CONTACT_DETAILS):
      errorKey = 'errors:edit_account_details_personalization_not_available'
      break
    case e.response?.data?.subcode === AuthSubCodes.FORBIDDEN 
        && (e.response.data.missingPermissions ?? []).includes(PermissionName.EDIT_ORGANIZATION)
        && page === Page.EDIT_AGENCY:
      errorKey = 'errors:edit_agency_personalization_not_available' 
      break
    case e.response?.data?.subcode === AuthSubCodes.FORBIDDEN 
        && (e.response.data.missingPermissions ?? []).includes(PermissionName.EDIT_ORGANIZATION)
        && page === Page.INITIAL_ORGANIZATION_CONFIGURATION:
      errorKey = 'errors:initial_org_conf_personalization_not_available' 
      break
    case e.response?.data?.subcode === AuthSubCodes.FORBIDDEN :
      if (page === Page.GENERATE_REPORT) {
        errorKey = 'errors:forbidden_generate_report_page'
      } else {
        errorKey = 'errors:forbidden'
      }
      break
    case e.response?.data?.subcode === AuthSubCodes.EMAIL_TAKEN:
      errorKey = 'errors:email_taken'
      break
  }

  return errorKey
}

function handleDataErrors(e: AxiosError<HttpError>): TFuncKey<Namespace> {
  let errorKey: TFuncKey<Namespace> = 'errors:something_went_wrong'

  switch (e.response?.data?.subcode) {
    case DataSubCodes.BAD_REQUEST:
      errorKey = 'errors:something_went_wrong'
      break
    case DataSubCodes.ROLE_NOT_FOUND:
      errorKey = 'errors:role_not_found'
      break
    case DataSubCodes.ORG_NOT_FOUND:
      errorKey = 'errors:org_not_found'
      break
    case DataSubCodes.REPORT_NOT_FOUND:
      errorKey = 'errors:report_not_found'
      break
    case DataSubCodes.NO_ACTIVE_PACKAGE:
      errorKey = 'errors:no_active_package'
      break
    case DataSubCodes.INVALID_FILE:
      errorKey = 'errors:invalid_file'
      break
    case DataSubCodes.INVALID_FILE_FORMAT:
      errorKey = 'errors:invalid_file_format'
      break
    case DataSubCodes.INVALID_IMAGE_DIMENSIONS:
      errorKey = 'errors:invalid_image_dimensions'
      break
    case DataSubCodes.FILE_TOO_LARGE:
      errorKey = 'errors:file_too_large'
      break
    case DataSubCodes.NAME_ALREADY_EXIST:
      errorKey = 'errors:name_already_exists'
      break
    case DataSubCodes.USER_NOT_FOUND:
      errorKey = 'errors:user_not_found'
      break
    case DataSubCodes.PACKAGE_NOT_FOUND:
      errorKey = 'errors:package_not_found'
      break
    case DataSubCodes.NO_OFFERS_FOUND:
      errorKey = 'errors:no_offers_found'
      break
    case DataSubCodes.TOWN_NOT_FOUND:
      errorKey = 'errors:town_not_found'
      break
    case DataSubCodes.NO_SIMILAR_OFFERS_FOUND:
      errorKey = 'errors:report_no_similar_offers_found'
      break
    case DataSubCodes.NOT_ENOUGH_SIMILAR_OFFERS_FOUND:
      errorKey = 'errors:not_enough_similar_offers_found'
      break
  }

  return errorKey
}