import { useTranslation } from 'react-i18next'
import ErrorPage from './ErrorPage'
import useGaPageView from '../../hooks/google/useGaPageView'
import { InternetErrorBase64Src } from '../../utils/const'

export default function ConnectionError() {
  const texts = useTranslation().t

  useGaPageView('Error')

  return (
    <ErrorPage
      imageSrc={InternetErrorBase64Src}
      title={texts('errors:internet_error_title')}
      description={texts('errors:internet_error_subtitle')}
      button={{
        label: texts('common:refresh'),
        onClick: () => window.location.reload()
      }}
      sx={{
        img: {
          marginBottom: '2rem',
          width: '15.625rem',
          height: '15rem'
        }
      }}
    />
  )
}
