import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import AuthApi from '../../api/auth/AuthApi'
import { TokenDto } from '../../api/auth/types'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export default function useCheckActivateToken() {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const mutation = useMutation((data: TokenDto) => authApi.checkUserActivationToken(data), {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
      navigate('/login', { replace: true })
    },
  })
  return mutation
}