import { styled, SxProps } from '@mui/material'

const Root = styled('div')(({ theme }) => ({
  outline: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '2rem',
  borderRadius: '.25rem'
}))

const Value = styled('div')({
  position: 'absolute',
  lineHeight: '1.5rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
})

const Bar = styled('div')({
  height: '100%',
  backgroundColor: 'rgb(255, 160, 52)'
})

type Props = {
  count: number
  max: number
  showText?: boolean
  styles?: {
    root?: SxProps
  }
}

export default function PercentageBar(props: Props) {
  const valueInPercent = Math.ceil((props.count / props.max) * 100)

  return (
    <Root sx={props.styles?.root}>
      {
        props.showText
          ? <Value>{props.count}/{props.max}</Value>
          : null
      }
      <Bar style={{ maxWidth: `${valueInPercent}%` }} />
    </Root>
  )
}