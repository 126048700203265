import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import DataApi from '../../api/data/DataApi'
import { EditMyAccountDto } from '../../api/data/types'

const dataApi = DataApi.getInstance()

export default function useUpdateMyAccount() {
  const texts = useTranslation().t
  const mutation = useMutation((data: EditMyAccountDto) => dataApi.updateMyAccount(data), {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}