import { AuthDto, EmailDto, LoginDto, PasswordDto, TokenDto } from './types'
import { Success } from '../../types/common'
import { Api } from '../Api'

class AuthApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_AUTH}/api/v1/auth`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
  private static instance: AuthApi

  static getInstance(): AuthApi {
    if (AuthApi.instance) {
      return this.instance
    }
    return this.instance = new AuthApi()
  }

  public login(data: LoginDto): Promise<AuthDto>{
    return this.post<AuthDto>(`/account/login`, JSON.stringify(data))
  }

  public logout(): Promise<AuthDto>{
    return this.post<AuthDto>(`/account/logout`)
  }

  public sessionCheck(options?: { abortSignal?: AbortSignal }): Promise<AuthDto>{
    return this.post<AuthDto>(`/account/session/check`, {
      signal: options?.abortSignal,
      timeout: Number.parseInt(process.env.REACT_APP_SESSION_RECHECK_TIMEOUT_MS!)
    })
  }

  public activateUser(data: PasswordDto): Promise<Success> {
    return this.patch<Success>(`/account/activate?token=${data.token}`, JSON.stringify({ password: data.password }))
  }

  public checkUserActivationToken(data: TokenDto): Promise<Success> {
    return this.post<Success>(`/account/token/check?token=${data.token}`)
  }

  public resendActivationEmail(data: EmailDto): Promise<Success> {
    return this.post<Success>(`/account/email/resend`, JSON.stringify({ email: data.email }))
  }

  public sendResetPasswordRequest(data: EmailDto): Promise<Success> {
    return this.post<Success>('/account/password/reset/start', JSON.stringify({ email: data.email }))
  }

  public checkResetPasswordToken(data: TokenDto): Promise<Success> {
    return this.post<Success>(`/account/password/reset/check?token=${data.token}`)
  }

  public finishPasswordReset(data: PasswordDto): Promise<Success> {
    return this.patch<Success>(`/account/password/reset/finish?token=${data.token}`, JSON.stringify({ password: data.password }))
  }
}

export default AuthApi