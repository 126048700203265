import { Autocomplete, Grid, List, ListItem, TextField, SxProps } from '@mui/material'
import { Box } from '@mui/system'
import { useField } from 'formik'
import { SyntheticEvent, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import poweredByGoogleLogo from './powered.png'
import { useTranslation } from 'react-i18next'

type Props = {
  value?: google.maps.places.AutocompletePrediction | null
  size?: 'small' | 'medium'
  debounce?: number
  name: string
  label: string
  sx?: SxProps
  inputSx?: SxProps
  withSessionToken?: boolean
  onChange?: (event: SyntheticEvent<Element, Event>, details: google.maps.places.AutocompletePrediction | null) => void
}

export default function GoogleAutocomplete(props: Props) {
  const texts = useTranslation().t
  const [field, meta, helpers] = useField(props.name!)
  const [requestNewSessionToken, setRequestNewSessionToken] = useState(props.withSessionToken ?? true)
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()

  const getSessionToken = () => sessionToken
  const configTextField = {
    error: false,
    helperText: ' '
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  } else {
    configTextField.helperText = ' '
  }

  useEffect(() => {
    if (!requestNewSessionToken) {
      return
    }

    if (props.withSessionToken) {
      setSessionToken(new google.maps.places.AutocompleteSessionToken())
    }

    setRequestNewSessionToken(false)
  }, [props.withSessionToken, requestNewSessionToken])

  const placesAutocomplete = usePlacesAutocomplete({
    requestOptions: {
      language: 'pl',
      componentRestrictions: { country: 'pl' },
      ...(props.withSessionToken ? { sessionToken: getSessionToken() } : {})
    },
    debounce: props.debounce ?? 1000
  })

  const onSelect = async (_event: SyntheticEvent<Element, Event>, value: google.maps.places.AutocompletePrediction | null) => {
    if (!value) {
      return
    }
    try {
      const realVal = Array.isArray(value) ? value[0] : value
      placesAutocomplete.setValue(realVal.description, false)
      placesAutocomplete.clearSuggestions()
  
      const details = await getDetails({
        ...(props.withSessionToken ? { sessionToken: getSessionToken() } : {}),
        placeId: realVal.place_id,
        fields: ['address_component', 'formatted_address', 'type', 'icon', 'geometry', 'plus_code'],
        language: 'pl'
      }) as google.maps.places.PlaceResult
  
      const obj = {
        point: {
          latitude: details.geometry!.location!.lat(),
          longitude: details.geometry!.location!.lng()
        },
        town: details.address_components!.find(comp => comp.types.includes('locality'))!.long_name,
        street: details.address_components!.find(comp => comp.types.includes('route'))?.long_name ?? '',
        streetNumber: details.address_components!.find(comp => comp.types.includes('street_number'))?.long_name ?? '',
        region: details.address_components!.find(comp => comp.types.includes('administrative_area_level_1'))?.long_name ?? '',
        subRegion: details.address_components!.find(comp => comp.types.includes('administrative_area_level_2'))?.long_name ?? null,
        district: details.address_components!.find(comp => comp.types.includes('sublocality_level_1'))?.long_name ?? details.address_components!.find(comp => comp.types.includes('neighborhood'))?.long_name,
        plusCode: details.plus_code?.compound_code
      }
  
      helpers.setValue(obj)
      setRequestNewSessionToken(true)
      props.onChange?.(_event, value)
    } catch (error) {
      toast.error(texts('errors:something_went_wrong_google_details'))
    }
  }

  return (
    <Autocomplete<google.maps.places.AutocompletePrediction | null, false, true, false>
      size={props.size}
      sx={props.sx}
      popupIcon={null}
      onChange={onSelect}
      options={placesAutocomplete.suggestions.data}
      isOptionEqualToValue={(option, value) => option?.place_id === value?.place_id}
      onBlur={() => placesAutocomplete.clearSuggestions()}
      open={placesAutocomplete.suggestions.data.length > 0}
      disableClearable
      value={props.value as any} //any potrzebne bo inaczej wymaga undefined zamiast nulla, ale undefined niszczy resetowanie
      filterOptions={(options) => options} //filterOptions fixuje zachowanie inputu
      loading={placesAutocomplete.suggestions.loading}
      ListboxComponent={(props) => (
        <>
          <List {...props} />
          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              marginTop: '-.5rem',
              marginBottom: '0.5rem'
            }}
          >
            <Box component={'img'} src={poweredByGoogleLogo} />
          </Grid>
        </>
      )}
      renderOption={(props, option) => { // Potrzebne ze względu na to, że Google może zwrócić kilka places o różnych place_id ale tym samym tekście
        return (
          <ListItem {...props} key={option?.place_id}>
            {
              option?.description
                .replace(', Poland', '')
                .replace(', Polska', '')
            }
          </ListItem>
        )
      }}
      getOptionLabel={(option: google.maps.places.AutocompletePrediction | null) => option?.description.replace(', Poland', '').replace(', Polska', '') ?? ''}
      renderInput={(params) => (
        <TextField
          {...field}
          {...params}
          {...configTextField}
          label={props.label}
          sx={{
            marginBottom: '.5rem',
            ...props.inputSx
          }}
          variant='outlined'
          onChange={(e) => placesAutocomplete.setValue(e.target.value)}
        />
      )}
    />
  )
}
