import { TextField as MuiTextField, TextFieldProps, InputAdornment, IconButton } from '@mui/material'
import { useField } from 'formik'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import * as React from 'react'

type Props = {
  name: string
} & TextFieldProps

export default function PasswordField(props: Props) {
  const [field, meta] = useField(props.name)
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const configTextField: TextFieldProps = {
    ...field,
    ...props,
    variant: 'outlined',
    ...(props.autoComplete === 'off' ? {
      inputProps: {
        form: {
          autocomplete: 'off'
        }
      }
    } : {})
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  } else {
    configTextField.helperText = ' '
  }

  return (
    <MuiTextField 
      {...configTextField}
      type={showPassword ? 'text' : 'password'}
      sx={{
        marginBottom: '.5rem',
        ...props.sx
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              sx={{ 
                '.MuiSvgIcon-root': {
                  color: '#020202', 
                  opacity: '0.65'
                }
              }}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    /> 
  )
}
