import { Box, Grid } from '@mui/material'
import MainAuthImage from '../../assets/images/MainAuthImage.webp'

type Props = {
  children?: React.ReactNode
}

const AuthLayout = (props: Props) => {
  return (
    <Grid
      container
      width={'100%'}
      height={'100vh'}
      direction={'row'}
      sx={{
        overflowY: 'hidden'
      }}
    >
      <Grid 
        container 
        item 
        width={'53%'}
      > 
        {props.children}
      </Grid>
      <Grid 
        item 
        width={'47%'}
        height={'100%'}
      >
       <Box
         component={'img'}
         alt='Ofertodom reports main image'
         src={MainAuthImage}
         height={'100%'}
         width={'100%'}
         minWidth={'calc(1vw * (900 / 677))'}
       />
      </Grid>
    </Grid>
  )
}

export default AuthLayout