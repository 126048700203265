import { GenerateReportFormData } from '../api/data/types'

export function mapGenerateReportFormData(data: GenerateReportFormData) {
  return {
    location: data.location,
    params: {
      area: data.params.area,
      clientName: data.params.clientName,
      includePollution: data.params.includePollution,
      additionalParams: data.params.additionalParams,
      floor: data.params.floor,
      rooms: data.params.rooms,
      maxFloor: data.params.maxFloor,
      marketType: data.params.marketType,
      minBuildYear: data.params.minBuildYear ? data.params.minBuildYear : undefined,
      maxBuildYear: data.params.maxBuildYear ? data.params.maxBuildYear : undefined,
      buildingState: data.params.buildingState,
    }
  }
}