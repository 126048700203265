import { DeleteOutline, UploadRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Paper, Typography } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFilePicker } from 'use-file-picker'
import DefaultLogo from '../../assets/images/AuthIcon.svg'
import CustomColorPicker from '../../components/CustomColorPicker/CustomColorPicker'
import TextField from '../../components/CustomMui/Fields/TextField'
import Loading from '../../components/CustomMui/Loading'
import useFetchOrgDetails from '../../hooks/data/useFetchOrg'
import useUpdateOrganization from '../../hooks/data/useUpdateOrganization'
import { Page } from '../../services/errors/consts'
import { setUserConfiguredOrg } from '../../services/storage/StorageService'
import { EditAgencyFormData } from '../../types/form'
import { defaultReportColors } from '../../utils/const'
import { showImageSelectErrorToast } from '../../utils/error'
import { editAgencyValidation } from '../../utils/validations/editAgencyValidation'
import ConnectionError from '../Errors/ConnectionError'

const initialValues: EditAgencyFormData = {
  reportName: '',
  contactPhone: '',
  address: '',
  contactEmail: '',
  town: '',
  postalCode: '',
  colors: defaultReportColors
}

export default function InitialOrganizationConfiguration() {
  const texts = useTranslation().t
  const navigate = useNavigate()
  const formRef = useRef<FormikProps<EditAgencyFormData>>(null)

  const { data, isLoading, isError } = useFetchOrgDetails((data) => {
    if (data?.info?.props.report.colors) {
      formRef?.current?.setFieldValue('colors', data.info.props.report.colors)
    }
  })
  const updateMutation = useUpdateOrganization(Page.INITIAL_ORGANIZATION_CONFIGURATION)

  const handleSubmit = (data: EditAgencyFormData) => {
    updateMutation.mutate({
      contactDetails: {
        email: data.contactEmail,
        phone: data.contactPhone,
        address: {
          firstAddressLine: data.address,
          secondAddressLine: null,
          town: data.town,
          postalCode: data.postalCode
        }
      },
      report: {
        name: data.reportName,
        colors: data.colors
      },
      removeLogo: false,
      ...(plainFiles.length > 0 ? {
        logo: plainFiles[0]
      } : {})
    }, {
      onSuccess: () => {
        setUserConfiguredOrg(true)
        navigate('/')
      }
    })
  }

  const [openFileSelector, { filesContent, plainFiles, errors, clear }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['.png', '.jpg', '.jpeg', '.webp'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    imageSizeRestrictions: {
      minHeight: 30,
      minWidth: 115,
    },
    maxFileSize: Number.parseInt(process.env.REACT_APP_LOGO_FILE_MAX_SIZE_MB!)
  })

  const logo = useMemo(() => {
    if (filesContent[0]) {
      return `${filesContent[0].content}`
    }

    return DefaultLogo
  }, [filesContent])

  // handles file uploaded "event" which filePicker does not fire
  useEffect(() => {
    showImageSelectErrorToast(errors, texts)
  }, [errors, texts])
  
  if (isError) {
    return <ConnectionError/>
  }
  if (isLoading) {
    return <Loading/>
  }

  return (
    <Box width={'100%'}>
      <Typography
        color={'rgb(32, 32, 32)'}
        fontSize={'18px'}
        fontFamily={'AvenirHeavy'}
        mb={'2rem'}
      >
        {texts('account:config_report')}
      </Typography>
      <Formik<EditAgencyFormData>
        initialValues={initialValues}
        innerRef={formRef}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={editAgencyValidation(texts)}
      >
        {(formikProps) => (
          <Form>
            <Box 
              display={'flex'}
              sx={{ flexDirection: 'column' }}
            >
              <Box 
                display={'flex'} 
                gap={'.5rem'} 
                mb={'1rem'}
                sx={{ flexDirection: 'column' }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: 'white',
                    backgroundColor: 'rgb(2, 13, 50)',
                    padding: '4px 20px',
                    maxWidth: 'fit-content',
                    borderRadius: '8px',
                    fontFamily: 'AvenirHeavy',
                  }}
                >
                  {texts('account:x_step', { x: 1 })}
                </Typography>
                <Typography
                  fontFamily={'AvenirHeavy'}
                  color={'rgba(32, 32, 32, 0.75)'}
                  fontSize={'14px'}
                >
                  {texts('account:your_agency_data')}
                </Typography>
                <Typography
                  color={'rgba(32, 32, 32, 0.75)'}
                  fontSize={'14px'}
                  sx={{ letterSpacing: .13}}
                >
                  {texts('account:first_step_info')}
                </Typography>
              </Box>
              <Box
                display={'flex'}
                width={'30%'}
                minWidth={'500px'}
                flexDirection={'column'}
              >
                <TextField
                  size='small'
                  name='reportName'
                  fullWidth
                  label={texts('agency:contact_name')}
                />
                <TextField
                  size='small'
                  name='address'
                  label={texts('agency:contact_address')}
                />
                <Box>
                  <TextField
                    size='small'
                    name='postalCode'
                    label={texts('agency:postal_code')}
                    sx={{ width: '39.5%' }}
                  />
                  <TextField
                    size='small'
                    name='town'
                    label={texts('agency:town')}
                    sx={{ width: '59.5%', marginLeft: '1%' }}
                  />
                </Box>
                <TextField
                  size='small'
                  name='contactPhone'
                  label={texts('agency:contact_number')}
                />
                <TextField
                  size='small'
                  name='contactEmail'
                  label={texts('agency:contact_email')}
                />
              </Box>
              <Box 
                display={'flex'} 
                gap={'.5rem'} 
                mb={'1rem'}
                sx={{ flexDirection: 'column' }}
              >
                <Typography
                  variant={'h2'}
                  sx={{
                    fontSize: '14px',
                    color: 'white',
                    backgroundColor: 'rgb(2, 13, 50)',
                    padding: '4px 20px',
                    maxWidth: 'fit-content',
                    borderRadius: '8px'
                  }}
                >
                  {texts('account:x_step', { x: 2 })}
                </Typography>
                <Typography
                  fontFamily={'AvenirHeavy'}
                  color={'rgba(32, 32, 32, 0.75)'}
                  fontSize={'14px'}
                >
                  {texts('account:logo')}
                </Typography>
                <Typography
                  color={'rgba(32, 32, 32, 0.75)'}
                  fontSize={'14px'}
                  sx={{ letterSpacing: .13}}
                >
                  {texts('account:second_step_info')}
                </Typography>
              </Box>
              <Box
                gap={'16px'}
                display={'flex'}
                mb={'1rem'}
              >
                <Paper
                  sx={{
                    display: 'flex',
                    width: 'fit-content',
                    maxWidth: '31%',
                    height: 'fit-content',
                    position: 'relative',
                    borderRadius: '0.25rem'
                  }}
                >
                  <Box
                    component={'img'}
                    alt={'logo'}
                    src={logo}
                    maxWidth={'100%'}
                    maxHeight={plainFiles.length === 0 ? '36px' : '56px'}
                    sx={{
                      borderRadius: '0.25rem',
                      margin: plainFiles.length === 0 ? '10px 33px' : '0'
                    }}
                  />
                </Paper>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'0.25rem'}
                >
                  <Button
                    variant={'outlined'}
                    sx={{
                      color: '#020D32',
                      borderColor: '#020D32',
                      width: '150px',
                      fontSize: '0.75rem',
                      padding: '3px 15px',
                      textTransform: 'unset',
                      fontFamily: 'AvenirMedium'
                    }}
                    onClick={() => openFileSelector()}
                  >
                    <UploadRounded 
                      fontSize={'small'} 
                      sx={{ marginRight: '0.5rem' }}
                    />
                    {texts('common:add_file')}
                  </Button>
                  <Button
                    variant={'outlined'}
                    sx={{
                      color: '#E02020',
                      borderColor: '#E02020',
                      width: '150px',
                      fontSize: '0.75rem',
                      padding: '3px 15px',
                      textTransform: 'unset',
                      display: data?.info.logoUrl == null && plainFiles.length === 0 ? 'none' : 'auto'
                    }}
                    onClick={() => {
                      if (plainFiles.length > 0) {
                        clear()
                      }
                    }}
                  >
                    <DeleteOutline 
                      fontSize={'small'}
                      sx={{
                        marginRight: '0.5rem'
                      }}
                    />
                    {texts('common:remove_logo')}
                  </Button>
                </Box>
              </Box>
              <Box 
                display={'flex'} 
                gap={'.5rem'} 
                sx={{ flexDirection: 'column' }}
                mb={'1rem'}
              >
                <Typography
                  variant={'h2'}
                  sx={{
                    fontSize: '14px',
                    color: 'white',
                    backgroundColor: 'rgb(2, 13, 50)',
                    padding: '4px 20px',
                    maxWidth: 'fit-content',
                    borderRadius: '8px'
                  }}
                >
                  {texts('account:x_step', { x: 3 })}
                </Typography>
                <Typography
                  fontFamily={'AvenirHeavy'}
                  color={'rgba(32, 32, 32, 0.75)'}
                  fontSize={'14px'}
                >
                  {texts('account:colors')}
                </Typography>
                <Typography
                  color={'rgba(32, 32, 32, 0.75)'}
                  fontSize={'14px'}
                  sx={{ letterSpacing: .13}}
                >
                  {texts('account:third_step_info')}
                </Typography>
              </Box>
              <Box 
                display={'flex'}
                gap={'12px'}
                mb={'1rem'}
              >
                <CustomColorPicker name={'colors[0]'}/>
                <CustomColorPicker name={'colors[1]'}/>
                <CustomColorPicker name={'colors[2]'}/>
                <CustomColorPicker name={'colors[3]'}/>
                <CustomColorPicker name={'colors[4]'}/>
                <CustomColorPicker name={'colors[5]'}/>
              </Box>
              <LoadingButton
                loading={updateMutation.isLoading}
                disableElevation
                variant={'contained'}
                type={'submit'}
                disabled={!formikProps.isValid || !formikProps.dirty}
                sx={{
                  width: '250px',
                  height: '40px',
                  marginLeft: 'auto',
                  fontSize: '15px',
                  color: 'white',
                  textTransform: 'none',
                  lineHeight: '1.4'
                }}
              >
                {texts('account:save_config')}
              </LoadingButton>
            </Box>
          </Form >
        )}
      </Formik>
    </Box>
  )
}