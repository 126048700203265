import AddIcon from '@mui/icons-material/Add'
import {
  Box, Button, Typography
} from '@mui/material'
import { getGridSingleSelectOperators, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import {
  NumberParam,
  StringParam, useQueryParams, withDefault
} from 'use-query-params'
import { AccountDto, AccountStatus } from '../../api/data/types'
import MenuDrawer from '../../components/AccountList/MenuDrawer'
import { DataGrid } from '../../components/CustomMui/DataGrid/DataGrid'
import NoDataPlaceholder from '../../components/CustomMui/DataGrid/NoDataGridPlaceholder'
import PercentageBar from '../../components/CustomMui/PercentageBar'
import AddPackageDialog from '../../components/Dialog/ChangePackageDialog'
import { CreateAccountDialog } from '../../components/Dialog/CreateAccountDialog'
import UpdateAccountNameDialog from '../../components/Dialog/UpdateAccountNameDialog'
import UpdateAccountStatusDialog from '../../components/Dialog/UpdateAccountStatusDialog'
import UpdateAccountTypeDialog from '../../components/Dialog/UpdateAccountTypeDialog'
import SearchForm from '../../components/SearchForm/SearchForm'
import useFetchAccounts from '../../hooks/data/useFetchAccounts'
import { queryNames } from '../../hooks/queries'
import { pageSizes, RoleName } from '../../utils/const'
import { AccountTypeToColor, StatusToColor } from '../../utils/converters'
import { mapAccountListFilterModel, mapAccountListSortModel } from '../../utils/dataGrid'
import { mapExpirationDate } from '../../utils/time'
import ConnectionError from '../Errors/ConnectionError'
import Loading from '../../components/Loading/Loading'
import useGaPageView from '../../hooks/google/useGaPageView'

const ReportsList = () => {
  const texts = useTranslation().t
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  useGaPageView('AdminAccountsList')

  const [searchParams, setSearchParams] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 10),
    search: withDefault(StringParam, undefined),
    order: withDefault(StringParam, ''),
    status: withDefault(NumberParam, undefined)
  })

  const { data, isFetching, isLoading, isError } = useFetchAccounts({
    limit: searchParams.size,
    offset: searchParams.page * searchParams.size,
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    order: searchParams.order,
    status: searchParams.status != null && searchParams.status in AccountStatus ? searchParams.status : undefined
  })

  const handleSortChange = (model: GridSortModel) => {
    setSearchParams(
      {
        order: model.length > 0
          ? model.map(entry => `${entry.field}:${entry.sort}`).join(',')
          : '',
          page: 0
      }, 'replaceIn'
    )
  }

  const handleFilterChange = (model: GridFilterModel) => {
    setSearchParams({
      status: model.items.length > 0 && model.items[0].value != null && model.items[0].columnField === 'status'
        ? model.items[0].value
        : undefined,
      page: 0
    }, 'replaceIn')
  }

  const [openAddAccount, setOpenAddAccount] = useState(false)
  const [openEditName, setOpenEditName] = useState<boolean>(false)
  const [openChangeType, setOpenChangeType] = useState<boolean>(false)
  const [openChangeActiveState, setOpenChangeActiveState] = useState<boolean>(false)
  const [openAddPackage, setOpenAddPackage] = useState<boolean>(false)

  const [selectedAccount, setSelectedAccount] = useState<AccountDto | null>(null)

  const columns: { field: string, name: string }[] = texts('account:account_list_columns', { returnObjects: true }) as { field: string, name: string }[]

  const accountListColumns: GridColDef<AccountDto>[] = useMemo(() => [{
    field: columns[0].name,
    headerName: columns[0].field,
    flex: 3,
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (props) => props.row.name
  }, {
    field: columns[1].name,
    headerName: columns[1].field,
    flex: 3,
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (props) => props.row.email
  }, {
    field: columns[2].name,
    headerName: columns[2].field,
    flex: 2,
    align: 'center',
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (props) => props.row.organization.subAccountsCount > 0 ? props.row.organization.subAccountsCount : '-'
  }, {
    field: columns[3].name,
    headerName: columns[3].field,
    flex: 3,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderCell: (props) => {
      return (
        <Box
          sx={{
            backgroundColor: AccountTypeToColor[props.row.roles.find(e => e.name === RoleName.MAIN_ACCOUNT) ? 1 : 0],
            width: '100%',
            textAlign: 'center',
            borderRadius: '.75rem',
            overflow: 'hidden'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontSize: '.625rem',
              lineHeight: '1.5rem',
              fontFamily: 'AvenirMedium'
            }}
          >
            {texts('common:account_type', { type: props.row.roles.find(e => e.name === RoleName.MAIN_ACCOUNT) ? 1 : 0 })}
          </Typography>
        </Box>
      )
    }
  }, {
    field: columns[4].name,
    headerName: columns[4].field,
    align: 'center',
    flex: 3,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (props) => {
      if (props.row.package === null) {
        return '-'
      }
      return (
        <PercentageBar
          count={props.row.package.reportsCount}
          max={props.row.package.maxReportsCount}
          showText={true}
          styles={{
            root: {
              height: '1.5rem'
            }
          }}
        />
      )
    }
  }, {
    field: columns[5].name,
    headerName: columns[5].field,
    type: 'date',
    flex: 4,
    align: 'center',
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (props) => props.row.package?.expiredAt ? mapExpirationDate(props.row.package?.expiredAt, texts) : '-'
  }, {
    field: columns[6].name,
    headerName: columns[6].field,
    type: 'date',
    flex: 4,
    align: 'center',
    disableColumnMenu: true,
    filterable: false,
    valueGetter: (props) => props.row.lastActivity ? moment(props.row.lastActivity).format('DD/MM/YYYY HH:mm') : '-'
  }, {
    field: columns[7].name,
    headerName: columns[7].field,
    flex: 3,
    filterable: true,
    type: 'singleSelect',
    filterOperators: getGridSingleSelectOperators().filter(({ value }) => ['is'].includes(value)),
    valueOptions: [
      {
        value: AccountStatus.INACTIVE,
        label: texts('common:account_status', { status: AccountStatus.INACTIVE })
      },
      {
        value: AccountStatus.WAITING_FOR_ACTIVATION,
        label: texts('common:account_status', { status: AccountStatus.WAITING_FOR_ACTIVATION })
      },
      {
        value: AccountStatus.ACTIVE,
        label: texts('common:account_status', { status: AccountStatus.ACTIVE })
      }
    ],
    renderCell: (props) => {
      return (
        <Box
          sx={{
            border: `1px solid ${StatusToColor[props.row.status]}`,
            width: '100%',
            textAlign: 'center',
            borderRadius: '.75rem',
            overflow: 'hidden'
          }}
        >
          <Typography
            sx={{
              color: StatusToColor[props.row.status],
              fontSize: '.625rem',
              lineHeight: '1.5rem',
              fontFamily: 'AvenirMedium'
            }}
          >
            {texts('common:account_status', { status: props.row.status })}
          </Typography>
        </Box>
      )
    }
  }, {
    field: 'actions',
    headerName: '\u00A0',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (props) => 
      <MenuDrawer 
        row={props.row}
        onAddPackage={(row: AccountDto) => {
          setOpenAddPackage(true)
          setSelectedAccount(row)
        }}
        onEditAccountNameSelected={(row: AccountDto) => {
          setOpenEditName(true)
          setSelectedAccount(row)
        }}
        onChangeActiveStateSelected={(row: AccountDto) => {
          setOpenChangeActiveState(true)
          setSelectedAccount(row)
        }}
        onChangeTypeSelected={(row: AccountDto) => {
          setOpenChangeType(true)
          setSelectedAccount(row)
        }}
      />
  }], [columns, texts])

  useEffect(() => {
    const shouldRedirect =  
      !Number.isInteger(searchParams.page)
      || !Number.isInteger(searchParams.size) 
      || (!isFetching  && searchParams.page > 0 && searchParams.page * searchParams.size >= (data?.totalCount ?? 0))
      || !pageSizes.includes(searchParams.size) 
      || searchParams.page < 0
      || (searchParams.order && (accountListColumns.find(c => c.field === searchParams.order.split(':')[0]) == null
      || accountListColumns.find(c => c.field === searchParams.order.split(':')[0])?.sortable === false
      || !['asc', 'desc'].includes(searchParams.order.split(':')[1]?.toLowerCase())))
      || (searchParams.status != null && !(searchParams.status in AccountStatus))

    if (shouldRedirect) {
      setSearchParams({
        page: 0,
        size: 10,
        search: undefined,
        order: '',
        status: undefined
      }, 'replaceIn')
    }

    window.scrollTo(0,0)
  }, [navigate, data?.totalCount, searchParams, isFetching, setSearchParams, accountListColumns])

  if (isError && !isFetching) {
    return <ConnectionError />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <CreateAccountDialog 
        open={openAddAccount}
        onClose={() => setOpenAddAccount(false)} 
        onSuccess={() => {
          setSearchParams({
            page: 0,
            size: 10,
            search: undefined,
            order: '',
            status: undefined
          }, 'replaceIn')
        }}
      />
      <UpdateAccountNameDialog 
        open={openEditName}
        accountData={selectedAccount}
        onSuccess={() => {
          toast.success(texts('successes:admin_account_changes_saved'))
          queryClient.invalidateQueries([queryNames.fetchAdminAccountList])
        }}
        onClose={() => {
          setOpenEditName(false)
          setSelectedAccount(null)
        }} 
      />
      <UpdateAccountStatusDialog 
        open={openChangeActiveState}
        accountData={selectedAccount}  
        i18={{
          activate: {
            title: 'account:activate_account_title',
            description: 'account:activate_account_warning'
          },
          deactivate: {
            title: 'account:deactivate_account_title',
            description: 'account:deactivate_account_warning'
          },
          toast: {
            activated: 'successes:access_activated_as_admin',
            deactivated: 'successes:access_deactivated_as_admin'
          }
        }}
        onClose={() => {
          setOpenChangeActiveState(false)
          setSelectedAccount(null)
        }} 
        onSuccess={() => {
          queryClient.invalidateQueries([queryNames.fetchAdminAccountList])
        }}
      />
      <UpdateAccountTypeDialog 
        open={openChangeType}
        accountData={selectedAccount} 
        onClose={() => {
          setOpenChangeType(false)
          setSelectedAccount(null)
        }}
      />

      <AddPackageDialog 
        open={openAddPackage}
        accountData={selectedAccount} 
        onClose={() => {
          setOpenAddPackage(false)
          setSelectedAccount(null)
        }}
      />

      <Box
        display={'flex'}
        sx={{
          justifyContent: 'space-between',
          marginBottom: '2rem'
        }}
      >
        <SearchForm
          placeholder={texts('account:search_acc_by_name')}
          onSubmit={(data) => {
            setSearchParams({ search: data.search ? encodeURI(data.search) : '', page: 0 }, 'replaceIn')
            queryClient.invalidateQueries([queryNames.fetchAdminAccountList])
          }}
          searchValue={searchParams.search ? decodeURI(searchParams.search) : undefined}
        />
          <Button
            disableElevation
            variant={'contained'}
            onClick={() => setOpenAddAccount(true)}
            sx={{ width: '250px', height: '2.25rem' }}
          >
          <AddIcon sx={{ color: 'white' }} />
          <Typography
            fontSize={'15px'}
            fontFamily={'AvenirHeavy'}
            color={'white'}
            textTransform={'none'}
            lineHeight={'1.4'}
          >
            {texts('account:create_acc')}
          </Typography>
        </Button>
      </Box>
      <Typography
        variant={'h1'}
        fontFamily={'AvenirHeavy'}
        color={'RGB(32, 32, 32)'}
        fontSize={'1.125rem'}
        mb={'1rem'}
      >
        {texts('account:account_list')}
      </Typography>
      <DataGrid
        rows={data?.accounts ?? []}
        columns={accountListColumns}
        noRowsOverlay={NoDataPlaceholder}
        autoHeight={true}
        rowHeight={40}
        pageSize={searchParams.size}
        rowsPerPageOptions={pageSizes}
        onPageSizeChange={(num) => { setSearchParams({ size: num }, 'replaceIn') }}
        onPageChange={(page) => { setSearchParams({ page: page }, 'replaceIn') }}
        paginationMode={'server'}
        rowCount={data?.totalCount ?? 0}
        page={searchParams.page}
        loading={isFetching}
        sortingMode={'server'}
        filterMode={'server'}
        getRowId={(row) => row.uuid}
        sortModel={mapAccountListSortModel(searchParams.order)}
        filterModel={mapAccountListFilterModel(searchParams.status)}
        onSortModelChange={handleSortChange}
        onFilterModelChange={handleFilterChange}
        pagination
        disableColumnSelector 
        disableColumnPinning
      />
    </>
  )
}

export default ReportsList