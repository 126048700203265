import { Typography } from '@mui/material'
import { TFuncKey, Namespace } from 'i18next'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { AccountStatus, OrganizationMemberDto, AccountDto } from '../../api/data/types'
import useUpdateAccount from '../../hooks/data/useUpdateAccount'
import CustomDialog from '../CustomMui/CustomDialog/CustomDialog'

type Props = {
  open: boolean
  accountData: OrganizationMemberDto | AccountDto | null
  i18: {
    activate: {
      title: TFuncKey<Namespace>
      description: TFuncKey<Namespace>
    }
    deactivate: {
      title: TFuncKey<Namespace>
      description: TFuncKey<Namespace>
    }
    toast: {
      activated: TFuncKey<Namespace>
      deactivated: TFuncKey<Namespace>
    }
  }
  onClose: () => void
  onSuccess: () => void
}

export default function UpdateAccountStatusDialog(props: Props) {
  const texts = useTranslation().t

  const handleClose = () => props.onClose()

  const { mutate, isLoading } = useUpdateAccount(props.accountData?.uuid ?? '')

  const isActivated = props.accountData?.status !== AccountStatus.INACTIVE

  const handleChange = () => {
    mutate({ active: !isActivated }, {
      onSuccess: () => { 
        props.onSuccess()
        toast.success(texts((isActivated ? props.i18.toast.deactivated : props.i18.toast.activated)) as string)
        handleClose()
      }
    })
  }

  return (
    <CustomDialog
      title={texts(isActivated ? props.i18.deactivate.title : props.i18.activate.title) as string}
      handleClose={handleClose}
      handleConfirm={() => handleChange()}
      withCancel
      loading={isLoading}
      labels={{
        cancel: texts('agency:do_cancel'),
        confirm: texts(isActivated ? 'agency:do_deactivate' : 'agency:do_activate')
      }}
      open={props.open} 
    >
      <Typography 
        fontSize={'.875rem'}
        color={'rgb(32, 32, 32)'}
      >
        {texts(isActivated ? props.i18.deactivate.description : props.i18.activate.description) as string}
      </Typography>
    </CustomDialog>  )
}