import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Grid, IconButton, useTheme } from '@mui/material'
import { useState } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import AppBar from '../../components/CustomMui/AppBar'
import MiniDrawer from '../../components/CustomMui/MiniDrawer/MiniDrawer'
import useLogout from '../../hooks/auth/useLogout'
import { canEditOrganization, getUserEmail, getUserName, getUserOrgCustomized, isMainAccount, isSystemAdmin } from '../../services/storage/StorageService'
import AccountSettings from '../AccountSettings/AccountSettings'
import AccountList from '../AccountsList/AccountsList'
import AdminReportList from '../AdminReportList/AdminReportList'
import EditOrganization from '../EditOrganization/EditOrganization'
import NotFoundError from '../Errors/NotFoundError'
import GenerateReport from '../GenerateReport/GenerateReport'
import InitialOrganizationConfiguration from '../InitialOrganizationConfiguration/InitialOrganizationConfiguration'
import MyAgency from '../MyAgency/MyAgency'
import Home from '../ReportsList/ReportsList'

const Dashboard = () => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [buttonVisible, setButtonVisible] = useState(true)

  const handleDrawerSwitch = () => {
    setButtonVisible(false)
    setOpen(!open)
    setTimeout(() => setButtonVisible(true), open ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen)
  }

  const logOut = useLogout()

  const shouldShowInitOrgConf = !getUserOrgCustomized() && canEditOrganization()

  return (
    <Grid 
      container 
    >
      <IconButton sx={{
        backgroundColor: 'rgb(255, 160, 52)',
        '&:hover': {
          backgroundColor: '#f57120'
        },
        opacity: buttonVisible ? 1 : 0,
        width: '32px',
        height: '32px',
        left: open ? '224px' : '64px',
        position: 'absolute',
        zIndex: '1299',
        marginTop: '8px',
        display: shouldShowInitOrgConf ? 'none' : undefined
      }}
        onClick={handleDrawerSwitch}
      >
        {
          open
            ? <ChevronLeftIcon sx={{ color: 'white' }} />
            : <ChevronRightIcon sx={{ color: 'white' }} />
        }
      </IconButton>
      <AppBar
        onlyLogo={shouldShowInitOrgConf}
        onLogoutClick={() => logOut.mutate()}
        userName={getUserName()!}
        userEmail={getUserEmail()!}
      />
      <MiniDrawer
        open={open}
        visible={shouldShowInitOrgConf}
        hideAdminRoutes={!isSystemAdmin()}
        hideAgencyRoutes={!(isMainAccount() || isSystemAdmin())}
      />
      <Grid
        container item
        display={'flex'}
        width={`calc(100% - ${open ? 240 : 56}px)`}
        flexDirection={'column'}
        flexGrow={1}
        padding={'1.875rem'}
        sx={{
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: open ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Routes>
          {
            shouldShowInitOrgConf 
              ? <>
                  <Route path={'/'} element={<Outlet />}>
                    <Route index element={<InitialOrganizationConfiguration />} />
                  </Route>
                  <Route path={'*'} element={<Navigate to={'/'} />} />
                </>
              : <>
                  <Route path='/' element={<Outlet />}>
                    <Route index element={<Home />} />
                    <Route path={'reports'} element={<Outlet />}>
                      <Route index element={<Navigate to={'/'} />} />
                      {
                        isSystemAdmin()
                          ? <Route path={'admin/:id'} element={<AdminReportList />} />
                          : null
                      }
                      <Route path={'create'} element={<GenerateReport />} />
                      <Route path={'*'} element={<NotFoundError />} />
                    </Route>
                  </Route>
                  {
                    isSystemAdmin() ?
                      <Route path={'accounts'} element={<Outlet />}>
                        <Route index element={<AccountList />} />
                        <Route path={'*'} element={<NotFoundError />} />
                      </Route>
                      : null
                  }
                  {
                    (isMainAccount() || isSystemAdmin()) ?
                      <Route path={'agency'} element={<Outlet />}>
                        <Route index element={<MyAgency />} />
                        <Route path={'edit'} element={<EditOrganization />} />
                        <Route path={'*'} element={<NotFoundError />} />
                      </Route>
                      : null
                  }
                  <Route path={'settings'} element={<Outlet />}>
                    <Route index element={<AccountSettings />} />
                  </Route>
                  <Route path={'*'} element={<NotFoundError />} />
                </>
          }
        </Routes>
      </Grid>
    </Grid >
  )
}

export default Dashboard