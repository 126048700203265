import * as Yup from 'yup'
import { emailRegexp } from './validationRegexps'
import { TFunction, Namespace } from 'i18next'

export function logInValidation(t: TFunction<Namespace>) {
  return Yup.object({
    login: Yup.string()
      .required(t('validations:field_required'))
      .matches(emailRegexp, t('validations:invalid_email_validation')),
    password: Yup.string().required(t('validations:field_required')),
  })
}

