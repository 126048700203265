import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { UpdateOrgPackageDto } from '../../api/data/types'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useUpdateOrganizationPackage(uuid: string) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((data: UpdateOrgPackageDto) => dataApi.updateOrganizationPackage(uuid, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries([queryNames.fetchAdminAccountList])
      queryClient.invalidateQueries([queryNames.fetchOrgDetails, uuid])
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    },
  })
  
  return mutation
}