export enum HttpStatus {
  PAYLOAD_TOO_LARGE = 413,
  GATEWAY_TIMEOUT = 504
}

export enum Codes {
  AUTH = 100,
  DATA = 200,
  INTERNAL = 999
}

export enum AuthSubCodes {
  INVALID_CREDENTIALS = 100,
  UNAUTHORIZED = 101,
  USER_INACTIVE = 102,
  ACCOUNT_REMOVED = 103,
  INVALID_TOKEN = 104,
  INCORRECT_PASSWORD = 105,
  SAME_PASSWORD_AS_PREVIOUS_ONE = 106,
  TOKEN_ALREADY_USED = 107,
  FORBIDDEN = 108,
  EMAIL_TAKEN = 109,
}

export enum DataSubCodes {
  BAD_REQUEST = 200,
  ROLE_NOT_FOUND = 201,
  ORG_NOT_FOUND = 202,
  REPORT_NOT_FOUND = 203,
  INVALID_FILE = 204,
  NAME_ALREADY_EXIST = 205,
  USER_NOT_FOUND = 206,
  PACKAGE_NOT_FOUND = 207,
  NO_ACTIVE_PACKAGE = 208,
  TOWN_NOT_FOUND = 209,
  NO_SIMILAR_OFFERS_FOUND = 210,
  NO_OFFERS_FOUND = 211,
  INVALID_FILE_FORMAT = 213,
  FILE_TOO_LARGE = 214,
  INVALID_IMAGE_DIMENSIONS = 215,
  NOT_ENOUGH_SIMILAR_OFFERS_FOUND = 216
}

export enum Page {
  INITIAL_ORGANIZATION_CONFIGURATION,
  EDIT_AGENCY,
  GENERATE_REPORT
}

export enum InternalSubcodes {
  FORCED_LOGOUT = 100
}