import { LoadingButton } from '@mui/lab'
import {
  Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Switch, Typography
} from '@mui/material'
import { AxiosError } from 'axios'
import { Field, FieldArray, Form, Formik, FormikHelpers } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { GenerateReportFormData } from '../../api/data/types'
import FormikSelectField from '../../components/CustomMui/Fields/FormikSelectField'
import TextField from '../../components/CustomMui/Fields/TextField'
import GoogleAutocomplete from '../../components/CustomMui/GoogleAutocomplete/GoogleAutocomplete'
import useReportProceed from '../../hooks/data/useReportProceed'
import useGaPageView from '../../hooks/google/useGaPageView'
import { mapGenerateReportFormData } from '../../mapping/form'
import { AuthSubCodes, Codes } from '../../services/errors/consts'
import { getUserPackageData, isPremiumAccount, isReportCreator } from '../../services/storage/StorageService'
import { defaultFormTextFieldSx } from '../../styles/common'
import { sectionTitle } from '../../styles/generateReport'
import { HttpError } from '../../types/common'
import { PermissionName, buildingStateSelectOptions, floorNumberSelectOptions, formAdditionalParamsCheckboxOptions, marketTypeSelectOptions, maxFloorNumberSelectOptions, roomNumberData } from '../../utils/const'
import { preventNonDigit } from '../../utils/input'
import { generateReportValidation } from '../../utils/validations/generateReportValidation'
import LoadingReport from './LoadingRaport'
import SelectData from './SelectData'
import useGenerateReport from '../../hooks/data/useGenerateReport'

enum Stage {
  FORM,
  SELECT_DATA,
  GENERATING
}

const initialValues: GenerateReportFormData = {
  location: null,
  params: {
    area: undefined,
    floor: undefined,
    rooms: undefined,
    maxFloor: undefined,
    marketType: undefined,
    includePollution: true,
    additionalParams: [],
    clientName: '',
    minBuildYear: undefined,
    maxBuildYear: undefined,
    buildingState: undefined
  }
}

export default function GenerateReport() {
  const texts = useTranslation().t
  const navigate = useNavigate()

  useGaPageView('ReportGeneration')

  // allows autofill of google input on returning from generate report error view
  const [googleLocation, setGoogleLocation] = useState<google.maps.places.AutocompletePrediction | null>(null)
  const [formData, setFormData] = useState<GenerateReportFormData>(initialValues)
  const [reportUuid, setReportUuid] = useState<string | null>(null)
  const [currentStage, setCurrentStage] = useState<Stage>(Stage.FORM)
  const [reportFileUrl, setReportFileUrl] = useState<string | null>(null)
  const [isPremiumUser, setIsPremiumUser] = useState<boolean>(isPremiumAccount())

  const proceedMutation = useReportProceed()
  const generateMutation = useGenerateReport()

  useEffect(() => {
    if (!(getUserPackageData() != null
      && getUserPackageData()!.reportsCount < getUserPackageData()!.maxReportsCount
      && moment(getUserPackageData()!.expiredAt).isAfter(moment())
      && isReportCreator())) {
      navigate('/', {
        replace: true,
        state: {
          showNoReportsLeftDialog: true
        }
      })
    }
  }, [])

  const handleSubmit = (data: GenerateReportFormData, formikHelpers: FormikHelpers<GenerateReportFormData>) => {   
    proceedMutation.mutate(mapGenerateReportFormData(data), {
      onSuccess: (response) => {
        setFormData(data)
        setReportUuid(response.uuid)
        setCurrentStage(Stage.SELECT_DATA)
      },
      onError: (error: AxiosError<HttpError>) => {
        if (
          error.response?.status === 403 
          && error.response?.data.code === Codes.AUTH 
          && error.response?.data.subcode === AuthSubCodes.FORBIDDEN 
          && (error.response.data.missingPermissions ?? []).includes(PermissionName.ADD_CLIENT_NAME)
        ) {
          setIsPremiumUser(false)
          formikHelpers.setFieldValue('params.clientName', '')
        }
      }
    })
  }

  if (currentStage === Stage.SELECT_DATA && formData != null && reportUuid != null) {
    return (
      <SelectData 
        reportUuid={reportUuid}
        onSubmit={(selectedAdvertUuids) => {
          setCurrentStage(Stage.GENERATING)
          generateMutation.mutate({
            uuid: reportUuid,
            selectedAdvertUuids: selectedAdvertUuids
          }, {
            onSuccess: (response) => {
              setReportFileUrl(response.data.fileUrl)
            }
          })
        }}
        onBackToFormClick={() => {
          setReportUuid(null)
          setReportFileUrl(null)
          setCurrentStage(Stage.FORM)
        }}
      />
    )
  }

  if (currentStage === Stage.GENERATING && formData != null) {
    return (
      <LoadingReport 
        values={{ 
          ...formData,
          fileUrl: reportFileUrl
        }}
        isError={generateMutation.isError}
        isSuccess={generateMutation.isSuccess}
        onBackToFormClick={() => {
          setReportUuid(null)
          setReportFileUrl(null)
          setCurrentStage(Stage.FORM)
        }}
      />
    )
  }

  return (
    <>
      <Typography
        variant={'h2'}
        fontSize={'1.125rem'}
        mb={'1rem'}
      >
        {texts('report:generate_report_title')}
      </Typography>
      <Formik<GenerateReportFormData>
        enableReinitialize
        initialValues={formData}
        onSubmit={handleSubmit}
        validationSchema={generateReportValidation(texts)}
      >
        {(formikProps) => (
          <Form>
            <Grid
              item
              container
              flexDirection={'column'}
              mb={'.125rem'}
            >
              <Typography
                variant={'h2'}
                sx={sectionTitle}
              >
                {texts('report:location_section')}
              </Typography>
              <Box>
                <GoogleAutocomplete
                  size={'small'}
                  inputSx={defaultFormTextFieldSx}
                  onChange={(event, details) => {
                    setGoogleLocation(details)
                  }}
                  value={googleLocation}
                  name={'location'}
                  label={texts('report:location_input_label')}
                  sx={{ width: '25rem'}}
                />
              </Box>
            </Grid>
            <Grid
              item
              container
              flexDirection={'column'}
              mb={'.125rem'}
            >
              <Typography
                variant={'h2'}
                sx={sectionTitle}
              >
                {texts('report:flat_section')}
              </Typography>
              <Box
                display={'flex'}
                flexDirection={'row'}
                flexWrap={'wrap'}
                gap={'.5rem'}
              >
                <TextField
                  name={'params.area'}
                  type={'number'}
                  value={formikProps.values.params.area ?? ''}
                  onKeyDown={preventNonDigit}
                  sx={{ 
                    width: '12.5rem',
                    ...defaultFormTextFieldSx
                  }}
                  label={texts('report:flat_area')}
                />
                <FormikSelectField
                  name={'params.rooms'}
                  label={texts('report:flat_rooms')}
                  options={roomNumberData}
                  inputSx={{...defaultFormTextFieldSx}}
                  width={200}
                />
                <FormikSelectField
                  name={'params.floor'}
                  label={texts('report:flat_floor')}
                  options={floorNumberSelectOptions}
                  inputSx={{...defaultFormTextFieldSx}}
                  width={200}
                />
                <FormikSelectField
                  name={'params.maxFloor'}
                  label={texts('report:flat_floor_count')}
                  options={maxFloorNumberSelectOptions}
                  inputSx={{...defaultFormTextFieldSx}}
                  width={200}
                />
                <FormikSelectField
                  name={'params.marketType'}
                  label={texts('report:flat_market')}
                  options={marketTypeSelectOptions}
                  inputSx={{...defaultFormTextFieldSx}}
                  width={200}
                />
                <TextField
                  name={'params.minBuildYear'}
                  type={'number'}
                  value={formikProps.values.params.minBuildYear ?? ''}
                  onKeyDown={preventNonDigit}
                  sx={{ 
                    width: '12.5rem',
                    ...defaultFormTextFieldSx
                  }}
                  label={texts('report:flat_min_build_year')}
                />
                <TextField
                  name={'params.maxBuildYear'}
                  type={'number'}
                  value={formikProps.values.params.maxBuildYear ?? ''}
                  onKeyDown={preventNonDigit}
                  sx={{ 
                    width: '12.5rem',
                    ...defaultFormTextFieldSx
                  }}
                  label={texts('report:flat_max_build_year')}
                />
                <FormikSelectField
                  name={'params.buildingState'}
                  label={texts('report:flat_building_state')}
                  options={buildingStateSelectOptions}
                  inputSx={{...defaultFormTextFieldSx}}
                  width={200}
                />
              </Box>
            </Grid>
            <Grid
              item
              container
              flexDirection={'column'}
              mb={'1rem'}
            >
              <Typography
                variant={'h2'}
                sx={sectionTitle}
              >
                {texts('report:additional_params_section')}
              </Typography>
              <FormGroup row sx={{ marginLeft: '.5rem'}}>
                <FieldArray name={'params.additionalParams'}>
                  {
                    ({ remove, push }) => (
                      <>
                        {
                          formAdditionalParamsCheckboxOptions.map((e, index) => (
                            <FormControlLabel 
                              label={e.i18 ? texts(e.i18.text, e.i18.params) as string : e.label ?? ''}
                              checked={formikProps.values.params.additionalParams.includes(e.value)}
                              sx={{
                                '.MuiCheckbox-root': {
                                  padding: '.5rem'
                                },
                                '.MuiSvgIcon-root': {
                                  width: '18px',
                                  height: '18px'
                                },
                                '.MuiFormControlLabel-label': {
                                  fontFamily: 'AvenirBook',
                                  fontSize: '.875rem',
                                  color: 'RGBA(32, 32, 32, 0.87)'
                                }
                              }}
                              control={
                                <Field
                                  id={`params.additionalParams.${index}`}
                                  component={Checkbox}
                                  label={e.i18 ? texts(e.i18.text, e.i18.params) : e.label}
                                  onChange={() => {
                                    const idx = formikProps.values.params.additionalParams.findIndex(p => p === e.value)
                                    if (idx > -1) {
                                      remove(idx)
                                    } else {
                                      push(e.value)
                                    }
                                  }}
                                  labelPlacement={'end'}
                                  value={e.value}
                                />
                              }
                            />
                          ))
                        }
                      </>
                    )
                  }
                </FieldArray>
              </FormGroup>
            </Grid>
            <Grid
              item
              container
              flexDirection={'column'}
              mb={'.125rem'}
            >
              <Typography
                variant={'h2'}
                sx={sectionTitle}
              >
                {texts('report:contact_info_section')}
              </Typography>
              <TextField
                name={'params.clientName'}
                label={texts('report:contact_name')}
                disabled={!isPremiumUser}
                sx={{
                  width: '25rem',
                  ...defaultFormTextFieldSx
                }}
              />
            </Grid>
            <Grid
              item
              container
              flexDirection={'column'}
              mb={'1rem'}
            >
              <Typography
                variant={'h2'}
                sx={sectionTitle}>
                {texts('report:additional_settings')}
              </Typography>
              <FormGroup row>
                <FormControlLabel
                  name={'params.includePollution'}
                  sx={{
                    '.Mui-checked': {
                      transform: 'translateX(16px)'
                    },
                    '.MuiSwitch-thumb': {
                      width: '1rem',
                      height: '1rem'
                    },
                    '.MuiSwitch-track': {
                      height: '.75rem',
                      width: '1.75rem'
                    },
                    '.MuiFormControlLabel-label': {
                      fontFamily: 'AvenirBook',
                      fontSize: '.875rem',
                      marginBottom: '.125rem',
                      color: 'RGBA(32, 32, 32, 0.87)'
                    }
                  }}
                  onChange={formikProps.handleChange}
                  control={<Switch checked={formikProps.values.params.includePollution ?? true}/>}
                  label={texts('report:include_pollution_data')}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              container
              flexDirection={'row'}
              gap={'.75rem'}
              justifyContent={'flex-end'}
              sx={{ 
                height: '2.25rem'
              }}
            >
              <Button
                disableElevation
                variant={'outlined'}
                onClick={() => {
                  setFormData(initialValues)
                  setGoogleLocation(null)
                  formikProps.resetForm()
                }}
                disabled={proceedMutation.isLoading}
                sx={{
                  border: '1px solid rgb(32, 32, 32)',
                  color: 'black',
                  textTransform: 'none',
                  width: '15.625rem',
                }}
              >
                <Typography fontSize={'.9375rem'} fontFamily={'AvenirMedium'}>
                  {texts('report:reset_label')}
                </Typography>
              </Button>
              <LoadingButton
                type={'submit'}
                disableElevation
                disabled={!formikProps.isValid || (!formikProps.dirty && formData == null)}
                variant={'contained'}
                loading={proceedMutation.isLoading}
                sx={{
                  color: 'white',
                  textTransform: 'none',
                  width: '15.625rem'
                }}
              >
                <Typography fontSize={'.9375rem'} fontFamily={'AvenirHeavy'}>
                  {texts('report:next_button_label_label')}
                </Typography>
              </LoadingButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}
