import {
  Box, Pagination, Typography
} from '@mui/material'
import moment from 'moment-timezone'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import {
  NumberParam,
  StringParam, useQueryParams, withDefault
} from 'use-query-params'
import NoReportsFound from '../../assets/images/NoReportsFound.svg'
import CustomDateRangePicker from '../../components/CustomMui/CustomDateRangePicker/CustomDateRangePicker'
import Loading from '../../components/CustomMui/Loading'
import NoData from '../../components/NoData/NoData'
import ReportsTile from '../../components/ReportsTile/ReportsTile'
import SearchForm from '../../components/SearchForm/SearchForm'
import useFetchAdminReportList from '../../hooks/data/useFetchAdminList'
import { queryNames } from '../../hooks/queries'
import { reportsPerPage } from '../../utils/const'
import ConnectionError from '../Errors/ConnectionError'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function AdminReportList() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const texts = useTranslation().t
  const params = useParams()

  useGaPageView('AdminReportsList')

  const [searchParams, setSearchParams] = useQueryParams({
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined),
    dateFrom: withDefault(StringParam, moment().subtract(3, 'months').format('YYYY-MM-DD')),
    dateTo: withDefault(StringParam, moment().format('YYYY-MM-DD'))
  })

  const { data, isFetching, isError } = useFetchAdminReportList(params.id ?? '', {
    offset: searchParams.page >= 1 ? (searchParams.page - 1) * reportsPerPage : 0,
    limit: reportsPerPage,
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    from: searchParams.dateFrom ? moment(searchParams.dateFrom).startOf('day').format() : undefined,
    to: searchParams.dateTo ? moment(searchParams.dateTo).endOf('day').format() : undefined
  })

  const maxPage = useMemo(() => {
    return Math.ceil((data?.totalCount ?? 0) / reportsPerPage)
  }, [data?.totalCount])

  useEffect(() => {
    const shouldRedirect = 
      !Number.isInteger(searchParams.page) 
      || (maxPage !== 0 && searchParams.page > maxPage)
      || (!isFetching && maxPage === 0 && searchParams.page > 1)
      || searchParams.page < 1
      || (searchParams.dateFrom && !moment(searchParams.dateFrom).isValid())
      || (searchParams.dateTo && !moment(searchParams.dateTo).isValid())
    if (shouldRedirect) {
      setSearchParams({
        page: 1,
        search: undefined,
        dateFrom: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD')
      }, 'replaceIn')
    }

    window.scrollTo(0,0)
  }, [searchParams, navigate, isFetching, maxPage, setSearchParams])

  if (isError) {
    return <ConnectionError />
  }

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        mb={'2rem'}
      >
        <SearchForm
          placeholder={texts('report:search_report_label')}
          onSubmit={(data) => {
            setSearchParams({ search: data.search ? encodeURI(data.search) : '', page: 1 }, 'replaceIn')
            queryClient.invalidateQueries([queryNames.fetchReportAdminList, params.id, (searchParams.page - 1) * reportsPerPage, searchParams.search])
          }}
          searchValue={searchParams.search ? decodeURI(searchParams.search) : undefined}
        />
      </Box>
      <Box>
        <Typography
          variant={'h2'}
          fontSize={'1.125rem'}
          color={'#202020'}
        >
          {texts('report:report_list_title')}
        </Typography>
      </Box>
      <Box 
        display={'flex'} 
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography
          fontFamily={'AvenirMedium'}
          fontSize={'.875rem'}
          color={'rgba(32, 32, 32, 0.87)'}
        >
          {texts('report:report_list_count', {count: data?.totalCount?? 0})}
        </Typography>
        <Box 
          display={'flex'} 
          flexDirection={'row'}
          gap={'.5rem'}
        >
          <CustomDateRangePicker
            mask={'__/__/____'}
            inputFormat={'dd/MM/yyyy'}
            maxDate={new Date()}
            value={
              [
                searchParams.dateFrom ? moment(searchParams.dateFrom).toDate() : null,
                searchParams.dateTo ? moment(searchParams.dateTo).toDate() : null
              ]
            }
            onChange={(newValue) => {
              setSearchParams({
                dateFrom: newValue[0] != null ? moment(newValue[0]).format('YYYY-MM-DD') : '',
                dateTo: newValue[1] != null ? moment(newValue[1]).format('YYYY-MM-DD') : '',
                page: 1
              }, 'replaceIn')
            }}
            labels={{
              before: texts('report:generate_from_label'),
              middle: texts('report:generate_to_label')
            }}
          />
        </Box>
      </Box>
      {
        isFetching
        ? <Loading height={'30rem'}/>
        : data?.totalCount && data.totalCount > 0 && data.totalCount > (searchParams.page - 1) * reportsPerPage
          ? <Box
              display={'flex'}
              flexDirection={'column'}
              gap={'.75rem'}
              mt={'1rem'}
            >
              {
                data?.reports.map((report, i) => (
                  <ReportsTile
                    key={i}
                    adminView
                    data={report}
                  />
                ))
              }
              {
                data!.totalCount < reportsPerPage
                  ? null
                  : <Pagination
                      count={Math.ceil((data?.totalCount ? data.totalCount + 1 : 1) / reportsPerPage)}
                      shape={'rounded'}
                      color={'primary'}
                      page={searchParams.page}
                      onChange={(_pagination, pageNum) => {
                        setSearchParams({ page: pageNum }, 'replaceIn')
                      }}
                      sx={{
                        marginTop: '.25rem'
                      }}
                    />
              }
            </Box>
          : <NoData 
              imgSrc={NoReportsFound}
              mainText={texts('report:no_data_placeholder')}
              subText={texts('report:admin_no_data_subtext_placeholder')}
            />
      }
    </>
  )
}
