import * as Yup from 'yup'
import { TFunction, Namespace } from 'i18next'

export function editAgencyValidation(t: TFunction<Namespace>) {
  return Yup.object({
    contactEmail: Yup.string()
      .required(t('validations:field_required'))
      .max(32, t('validations:max_32_signs_validation')),
    address: Yup.string()
      .required(t('validations:field_required'))
      .max(32, t('validations:max_32_signs_validation')),
    reportName: Yup.string()
      .required(t('validations:field_required'))
      .max(32, t('validations:max_32_signs_validation')),
    contactPhone: Yup.string()
      .required(t('validations:field_required'))
      .max(32, t('validations:max_32_signs_validation')),
    town: Yup.string()
      .required(t('validations:field_required'))
      .max(32, t('validations:max_32_signs_validation')),
    postalCode: Yup.string()
      .required(t('validations:field_required'))
      .max(6, t('validations:incorrect_postal_code_length'))
      .min(6, t('validations:incorrect_postal_code_length'))
  })
}