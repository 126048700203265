import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { EditAgencyDto, OrganizationDetailsDto } from '../../api/data/types'
import { Page } from '../../services/errors/consts'
import { getUserOrgUuid, getUserUuid } from '../../services/storage/StorageService'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useUpdateOrganization(page: Page) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((data: EditAgencyDto) => dataApi.updateOrganization(getUserOrgUuid()!, data), {
    onSuccess: (data) => {
      const keys = [queryNames.fetchOrgDetails, getUserOrgUuid(), getUserUuid()]
      const queryData = queryClient.getQueryData<OrganizationDetailsDto>(keys)

      if (data) {
        queryClient.setQueryData(keys, {
          ...queryData,
          info: data
        })
      }
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts, page)
    },
  })
  
  return mutation
}