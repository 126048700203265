import * as Yup from 'yup'
import { TFunction, Namespace } from 'i18next'

export function generateReportValidation(t: TFunction<Namespace>) {
  return Yup.object({
    location: Yup.object().required(t('validations:field_required')).nullable(),
    params: Yup.object({
      area: Yup.number()
        .min(5, t('validations:min_area', { min: 5 }))
        .max(1500, t('validations:max_area', { max: 1500 }))
        .required(t('validations:field_required')),
      floor: Yup.number().optional()
        .test(function (floor: any, ctx: any) {
          let maxFloor = this.resolve(Yup.ref('maxFloor')) as number | null
          if (maxFloor != null && floor != null && floor > maxFloor) {
            return this.createError({
              message: t('validations:floor_less_than_max_floor'),
              path: ctx.path
            })
          }

          return true
        }),
      rooms: Yup.number().positive(t('validations:not_negative')).optional(),
      maxFloor: Yup.number()
        .test(function (maxFloor: any, ctx: any) {
          let floor = this.resolve(Yup.ref('floor')) as number | null
          if (maxFloor != null && floor != null && floor > maxFloor) {
            return this.createError({
              message: t('validations:max_floor_greater_than_floor'),
              path: ctx.path
            })
          }

          return true
        }),
      marketType: Yup.number().optional(),
      includePollution: Yup.boolean().required(t('validations:field_required')),
      clientName: Yup.string()
          .max(32, t('validations:max_32_signs_validation')),
      minBuildYear: Yup.number().optional()
        .min(1, t('validations:higher_than_zero'))
        .test(function (minBuildYear: any, ctx: any) {
          let maxBuildYear = this.resolve(Yup.ref('maxBuildYear')) as number | null
          if (maxBuildYear != null && minBuildYear != null && minBuildYear > maxBuildYear) {
            return this.createError({
              message: t('validations:min_build_year_higher_than_max'),
              path: ctx.path
            })
          }
          return true
        }),
      maxBuildYear: Yup.number().optional()
        .min(1, t('validations:higher_than_zero'))
        .test(function (maxBuildYear: any, ctx: any) {
          let minBuildYear = this.resolve(Yup.ref('minBuildYear')) as number | null
          if (minBuildYear != null && maxBuildYear != null && minBuildYear > maxBuildYear) {
            return this.createError({
              message: t('validations:max_build_year_lower_than_min'),
              path: ctx.path
            })
          }
          return true
        })
    }).required(t('validations:field_required'))
  })
}