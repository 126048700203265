import { Box, SxProps, Typography } from '@mui/material'

type Props = {
  imgSrc: string
  mainText?: string
  subText?: string
  styles?: {
    container?: SxProps
    img?: SxProps
    mainText?: SxProps
    subText?: SxProps
  }
}

function NoData(props: Props) {
  return (
    <Box 
      display={'flex'} 
      flexDirection={'column'}
      alignItems={'center'}
      sx={props.styles?.container ?? { 
        width: '100%',
        marginTop: '4rem',
        gap: '.75rem'
      }}
    >
      <Box
        component={'img'}
        src={props.imgSrc}
        sx={props.styles?.img ?? {
          width: '4rem',
          height: '4rem'
        }}
      >
      </Box>
      <Typography
        variant={'h2'}
        sx={props.styles?.mainText ?? {
          fontSize: '.875rem',
          lineHeight: '1.5rem',
          color: 'rgba(32, 32, 32, 0.87)',
          letterSpacing: '0.13'
        }}
      >
        {props.mainText}
      </Typography>
      <Typography
        variant='body2'
        sx={props.styles?.subText ?? {
          fontFamily: 'AvenirBook',
          fontSize: '.75rem',
          color: 'rgba(32, 32, 32, 0.65)',
          letterSpacing: '0.11'
        }}
      >
        {props.subText}
      </Typography>
    </Box>
  )
}

export default NoData