import { Box, Button, SxProps, Typography } from '@mui/material'

type Props = {
  imageSrc: string
  title: string
  description: string
  button?: {
    label: string
    onClick: () => void
  }
  sx?: {
    container?: SxProps
    img?: SxProps
    title?: SxProps
    description?: SxProps
    button?: SxProps
  }
}

export default function ErrorPage(props: Props) {
  return (
    <Box
      display={'flex'}
      sx={props.sx?.container ?? {
        height: 'calc(100vh - 110px)',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Box
        component={'img'}
        src={props.imageSrc}
        sx={props.sx?.img ?? {
          marginBottom: '2rem',
          width: '15.625rem',
          height: '15rem'
        }}
      >
      </Box>
      <Typography
        variant={'h3'}
        sx={props.sx?.title ?? {
          fontSize: '1rem',
          color: 'rgba(32, 32, 32, 0.87)',
          letterSpacing: '0.13',
          lineHeight: '1.5rem'
        }}
      >
        {props.title}
      </Typography>
      <Typography
        sx={props.sx?.description ?? {
          fontSize: '.75rem',
          color: 'rgba(32, 32, 32, 0.65)',
          letterSpacing: '0.11',
          lineHeight: '1.125rem',
          whiteSpace: 'pre-line',
          textAlign: 'center',
          marginBottom: '2rem'
        }}
      >
        {props.description}
      </Typography>
      {
        props.button && 
          <Button
            disableElevation
            variant={'contained'}
            onClick={() => props.button?.onClick()}
            sx={props.sx?.button ?? {
              color: 'white',
              textTransform: 'none',
              width: '250px',
              fontSize: '1rem',
              fontFamily: 'AvenirHeavy',
              height: '2.25rem'
            }}
          >
            {props.button.label}
          </Button>
      }
      
    </Box>
  )
}
