import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { GenerateReportFormData } from '../../api/data/types'
import { Page } from '../../services/errors/consts'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'

const dataApi = DataApi.getInstance()

export default function useReportProceed() {
  const texts = useTranslation().t

  const mutation = useMutation((data: GenerateReportFormData) => dataApi.reportProceed(data), {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts, Page.GENERATE_REPORT)
    },
  })

  return mutation
}