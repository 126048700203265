import { Namespace, TFunction } from 'i18next'
import { Codes, InternalSubcodes, Page } from '../services/errors/consts'
import toast from 'react-hot-toast'
import { mapHttpErrorMessage } from '../services/errors/errorMapping'
import { FileError } from 'use-file-picker'

export function showErrorToast(error: any, translations: TFunction<Namespace>, page?: Page) {
  const isForcedLogout = error.response?.data.code === Codes.INTERNAL && error.response?.data?.subcode === InternalSubcodes.FORCED_LOGOUT
  if (!isForcedLogout) {
    toast.error(translations(mapHttpErrorMessage(error, page)) as string)
  }
}

export function showImageSelectErrorToast(errors: FileError[], translations: TFunction<Namespace>) {
  if (errors.length > 0) {
    for (const err of errors) {
      if (err.fileSizeToolarge) {
        toast.error(translations('errors:file_too_large'))
      } else if (err.imageNotLoaded) {
        toast.error(translations('errors:invalid_file_format'))
      } else if (errors[0].imageHeightTooSmall || errors[0].imageWidthTooSmall ) {
        toast.error(translations('errors:invalid_image_dimensions'))
      } else {
        toast.error(translations('errors:invalid_file'))
      }
    }
  }
}