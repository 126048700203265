import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { Form, Formik } from 'formik'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { AccountDto } from '../../api/data/types'
import useFetchRoles from '../../hooks/data/useFetchRoles'
import useUpdateAccountType from '../../hooks/data/useUpdateAccountType'
import { UpdateAccountTypeFormData } from '../../types/form'
import { RoleName } from '../../utils/const'
import { mapRoleDtoIntoSelectOptions } from '../../utils/converters'
import CustomDialog from '../CustomMui/CustomDialog/CustomDialog'
import FormikSelectField from '../CustomMui/Fields/FormikSelectField'
import Loading from '../CustomMui/Loading'
import { editAccountTypeValidation } from '../../utils/validations/editAccountTypeValidation'

type Props = {
  open: boolean
  accountData?: AccountDto | null
  onClose: () => void
}

export default function UpdateAccountTypeDialog(props: Props) {
  const texts = useTranslation().t

  const handleClose = () => props.onClose()

  const { data: roles, isError: isRolesError, isFetching: isRolesLoading } = useFetchRoles()

  const { mutate, isLoading } = useUpdateAccountType(props.accountData?.uuid ?? '')

  const handleSubmit = (data: UpdateAccountTypeFormData) => {
    mutate({
      uuid: data.roleUuid
    }, {
      onSuccess: () => {
        toast.success(texts('successes:admin_account_changes_saved'))
        handleClose()
      }
    })
  }

  if (isRolesLoading) {
    return <Loading />
  }

  if (isRolesError) {
    return null
  }

  return (
    <CustomDialog
      title={texts('account:change_type_title')}
      handleClose={handleClose}
      open={props.open} 
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
      >
        <Formik<UpdateAccountTypeFormData>
          initialValues={{ 
            roleUuid: roles?.find(role => 
              role.name === (props.accountData?.roles.filter(r => [RoleName.MAIN_ACCOUNT, RoleName.SYSTEM_ADMIN].includes(r.name))[0]?.name ?? '')
            )?.uuid ?? '' 
          }}
          enableReinitialize
          validationSchema={editAccountTypeValidation(texts)}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form 
              style={{ 
                width: '25.5rem',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem'
              }}
            >
              <FormikSelectField
                name={'roleUuid'}
                label={texts('account:account_type')}
                options={mapRoleDtoIntoSelectOptions(roles ?? [])}
                inputSx={{
                  '.MuiInputBase-input': {
                    fontSize: '.875rem !important',
                    transform: 'translate(0, 1px) scale(1)'
                  },
                  '.MuiInputBase-root': {
                    height: '2.5rem'
                  },
                  '.MuiInputLabel-root': {
                    fontSize: '1rem !important',
                    lineHeight: '1.5rem'
                  },
                  '.MuiInputLabel-shrink': {
                    fontSize: '.75rem !important',
                    lineHeight: '1.75rem'
                  }
                }}
              />
              <Box 
                display={'flex'}
                height={'2.25rem'}
                gap={'.5rem'}
                justifyContent={'flex-end'}
              >
                <Button
                  variant={'outlined'}
                  onClick={() => handleClose()}
                  sx={{
                    color: 'rgb(2, 13, 50)',
                    borderColor: 'rgb(2, 13, 50)',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirMedium',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('agency:cancel')}
                </Button>
                <LoadingButton
                  variant={'contained'}
                  disableElevation
                  loading={isLoading}
                  type={'submit'}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  sx={{
                    color: 'white',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirHeavy',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('account:save')}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CustomDialog>
  )
}