import * as Yup from 'yup'
import { TFunction, Namespace } from 'i18next'

export function filterOfferListValidation(t: TFunction<Namespace>) {
  return Yup.object({
    minPrice: Yup.number().optional()
      .min(1, t('validations:higher_than_zero'))
      .test(function (minPrice: any, ctx: any) {
        let maxPrice = this.resolve(Yup.ref('maxPrice')) as number | null
        if (maxPrice != null && minPrice != null && minPrice > maxPrice) {
          return this.createError({
            message: t('validations:min_price_higher_than_max'),
            path: ctx.path
          })
        }
        return true
      }),
    maxPrice: Yup.number().optional()
      .min(1, t('validations:higher_than_zero'))
      .test(function (maxPrice: any, ctx: any) {
        let minPrice = this.resolve(Yup.ref('minPrice')) as number | null
        if (minPrice != null && maxPrice != null && minPrice > maxPrice) {
          return this.createError({
            message: t('validations:max_price_year_lower_than_min'),
            path: ctx.path
          })
        }
        return true
      }),
    minPricePerArea: Yup.number().optional()
      .min(1, t('validations:higher_than_zero'))
      .test(function (minPricePerArea: any, ctx: any) {
        let maxPricePerArea = this.resolve(Yup.ref('maxPricePerArea')) as number | null
        if (maxPricePerArea != null && minPricePerArea != null && minPricePerArea > maxPricePerArea) {
          return this.createError({
            message: t('validations:min_price_per_area_year_higher_than_max'),
            path: ctx.path
          })
        }
        return true
      }),
    maxPricePerArea: Yup.number().optional()
      .min(1, t('validations:higher_than_zero'))
      .test(function (maxPricePerArea: any, ctx: any) {
        let minPricePerArea = this.resolve(Yup.ref('minPricePerArea')) as number | null
        if (minPricePerArea != null && maxPricePerArea != null && minPricePerArea > maxPricePerArea) {
          return this.createError({
            message: t('validations:max_price_per_area_lower_than_min'),
            path: ctx.path
          })
        }
        return true
      }),
  })
}