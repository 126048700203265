import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'
import DataApi from '../../api/data/DataApi'
import { UpdateAccountTypeDto } from '../../api/data/types'

const dataApi = DataApi.getInstance()

export default function useUpdateAccountType(uuid: string) {
  const texts = useTranslation().t
  const queryClient = useQueryClient()
  const mutation = useMutation((data: UpdateAccountTypeDto) => dataApi.updateAccountRole(uuid, data), {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryNames.fetchAdminAccountList])
    }
  })
  return mutation
}