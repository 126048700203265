import i18n from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'
import auth from './translations/auth.json'
import common from './translations/common.json'
import validations from './translations/validations.json'
import successes from './translations/successes.json'
import errors from './translations/errors.json'
import report from './translations/report.json'
import account from './translations/account.json'
import agency from './translations/agency.json'
import packages from './translations/packages.json'

export const defaultNS = 'common'

export const resources = {
  pl: {
    auth,
    common,
    validations,
    successes,
    errors,
    report,
    account,
    agency,
    packages
  },
} as const

i18n
  .use(ICU)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pl',
    fallbackLng: 'pl',
    keySeparator: false,
    ns: [
      'auth',
      'common',
      'validations',
      'successes',
      'errors',
      'report',
      'account',
      'agency',
      'packages'
    ],
    react: {
      transKeepBasicHtmlNodesFor: ['sup']
    },
    interpolation: {
      escapeValue: false
    },
    returnNull: false
  })

export default i18n