import { GridSortModel, GridFilterModel, GridSortDirection } from '@mui/x-data-grid-pro'
import { AccountStatus } from '../api/data/types'

export function mapAccountListSortModel(order: string): GridSortModel {
  if (!order.length || order.split(':').length !== 2) {
    return []
  }

  return order.split(',').map(pair => {
    const sort = pair.split(':')
    return {
      field: sort[0],
      sort: (['asc', 'desc'].includes(sort[1].toLowerCase()) ? sort[1] : 'desc') as GridSortDirection
    }
  })
}

export function mapAccountListFilterModel(status?: number): GridFilterModel {
  if (status == null || !Object.values(AccountStatus).includes(status)) {
    return { items: [] }
  }
  return {
    items: [
      {
        columnField: 'status',
        operatorValue: 'is',
        value: status
      }
    ]
  }
}