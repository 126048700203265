import { ReportFlatAdditionalParam, RoleDto, PackageDto } from '../api/data/types'
import { SelectOption } from '../types/common'
import { noPackageOption } from './const'

export enum AccountTypeToColor {
  'rgb(255, 160, 52)',
  'rgb(1, 13, 50)'
}

export enum StatusToColor {
  'rgb(224, 32, 32)',
  'rgb(11, 93, 156)',
  'rgb(0, 128, 0)'
}

export function sortAdditionalParams(data: ReportFlatAdditionalParam[]): ReportFlatAdditionalParam[] {
  return [
    data.find(d => d === ReportFlatAdditionalParam.GARAGE),
    data.find(d => d === ReportFlatAdditionalParam.KITCHEN),
    data.find(d => d === ReportFlatAdditionalParam.GARDEN),
    data.find(d => d === ReportFlatAdditionalParam.ELEVATOR),
    data.find(d => d === ReportFlatAdditionalParam.BASEMENT),
    data.find(d => d === ReportFlatAdditionalParam.BALCONY)
  ].filter(e => e != null) as ReportFlatAdditionalParam[]
}

export function mapRoleDtoIntoSelectOptions (roles: RoleDto[]): SelectOption[] {
  return roles.map<SelectOption>(r => {
    return {
      value: r.uuid,
      i18: {
        text: `account:main_account_type_label`,
        params: {
          name: r.name
        }
      }
    }
  })
}

export function mapPackageDtoIntoSelectOptions (packages: PackageDto[]): SelectOption[] {
  return [
    ...packages.map<SelectOption>(r => {
      return {
        value: r.uuid,
        i18: {
          text: 'packages:package_label',
          params: {
            name: r.name
          }
        }
      }
    }),
    noPackageOption
  ]
}