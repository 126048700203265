import { Form, Formik } from 'formik'
import { SearchQueryDto } from '../../api/data/types'
import FormikSearchInput from '../CustomMui/FormikSearchInput/FormikSearchInput'

type Props = {
  onSubmit: (data: SearchQueryDto) => void
  searchValue?: string
  placeholder?: string
}

export default function SearchForm(props: Props) {
  return (
    <Formik<SearchQueryDto>
      onSubmit={props.onSubmit}
      enableReinitialize
      initialValues={{
        search: props.searchValue
      }}
    >
      {() => (
        <Form>
          <FormikSearchInput
            name={'search'}
            submitOnClear
            placeholder={props.placeholder}
            sx={{ 
              width: '460px',
              '.MuiInputBase-input': {
                fontSize: '14px !important',
                transform: 'translate(0, 1px) scale(1)'
              },
              '.MuiInputBase-root': {
                height: '2.25rem'
              } 
            }}
          />
        </Form>
      )}
    </Formik>
  )
}