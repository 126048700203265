import { Box, Button, Grid, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { Form, Formik } from 'formik'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReportFlatAdditionalParam } from '../../api/data/types'
import { DataGrid } from '../../components/CustomMui/DataGrid/DataGrid'
import TextField from '../../components/CustomMui/Fields/TextField'
import useFetchSelectedAdverts from '../../hooks/data/useFetchSelectedAdverts'
import { defaultFormTextFieldSx } from '../../styles/common'
import { SelectedAdvert } from '../../types/data'
import { FilterOfferListFormData } from '../../types/form'
import { pageSizes } from '../../utils/const'
import { mapPrice } from '../../utils/formatters'
import { preventNonDigit } from '../../utils/input'
import { filterOfferListValidation } from '../../utils/validations/filterOfferListValidation'
import { useGridApiRef } from '@mui/x-data-grid-pro'

type Props = {
  onSubmit: (selectedAdvertUuids: string[]) => void
  reportUuid: string
  onBackToFormClick: () => void
}

export default function SelectData(props: Props) {
  const texts = useTranslation().t
  const apiRef = useGridApiRef()
 
  const [filteredRows, setFilteredRows] = useState<SelectedAdvert[]>([])
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  const [pageSize, setPageSize] = useState<number>(pageSizes[0])

  const selectedAdverts = useFetchSelectedAdverts({
    reportUuid: props.reportUuid ?? '',
    enabled: Boolean(props.reportUuid),
    onSuccess: (data) => {
      setFilteredRows(data.adverts)
      setSelectedRowIds(data.adverts.map(advert => advert.uuid))
    }
  })

  const columns: { field: string, name: string }[] = texts('report:offer_list_columns', { returnObjects: true }) as { field: string, name: string }[]

  const offersListColumns: GridColDef<SelectedAdvert>[] = useMemo(() => [
    {
      field: columns[0].name,
      headerName: columns[0].field,
      flex: 6,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (props) => (
        <>
          {props.row.town}
          {props.row.street && `,\u00A0${props.row.street}`}
        </>
      )
    },
    {
      field: columns[1].name,
      headerName: columns[1].field,
      flex: 2,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (props) => (
        <Grid
          width='100%'
          textAlign='center'
        >
          <Trans
            i18nKey='report:details_params'
            values={{
              area: props.row.area.toFixed(2).replace('.', ','),
            }}
          />
        </Grid>
      )
    },
    {
      field: columns[2].name,
      headerName: columns[2].field,
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      align: 'center',
      valueGetter: (props) => props.row.rooms
    },
    {
      field: columns[3].name,
      headerName: columns[3].field,
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      align: 'center',
      valueGetter: (props) =>
        texts('report:fraction', {
          left: props.row.floor != null ? texts('report:floor_count_labels', { floor: props.row.floor }) : '-',
          right: props.row.maxFloors != null ? texts('report:max_floor_count_labels', { floor: props.row.maxFloors }) : '-',
        })
    },
    {
      field: columns[4].name,
      headerName: columns[4].field,
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      align: 'center',
      valueGetter: (props) => props.row.pricePerSquareMeter,
      renderCell: (props) => mapPrice(props.row.pricePerSquareMeter)
    },
    {
      field: columns[5].name,
      headerName: columns[5].field,
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      align: 'center',
      valueGetter: (props) => props.row.price,
      renderCell: (props) => mapPrice(props.row.price)
    },
    {
      field: columns[6].name,
      headerName: columns[6].field,
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      align: 'center',
      valueGetter: (props) => texts('report:market_type_label', { marketType: props.row.market })
    },
    {
      field: columns[7].name,
      headerName: columns[7].field,
      flex: 6,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      align: 'center',
      renderCell: (props) => 
        <Box sx={{ textAlign: 'center' }}>
          {props.row.additionalParams.length > 0
            ? props.row.additionalParams
                .sort()
                .map((p: ReportFlatAdditionalParam) => texts('report:additional_param_label', { param: p }))
                .join(',')
            : '-'}
        </Box>
    }
  ], [columns, texts])

  const handleFilter = (data: FilterOfferListFormData) => {
    const filteredAdverts = filterOffers(data, selectedAdverts.data?.adverts ?? []) 
    setFilteredRows(filteredAdverts)
    setSelectedRowIds(filteredAdverts.map(advert => advert.uuid))
    apiRef.current.setPage(0)
  }

  const filterOffers = (filters: FilterOfferListFormData, offers: SelectedAdvert[]) => {
    return offers.filter(o => 
      (!filters.minPrice || o.price >= filters.minPrice)
      && (!filters.maxPrice || o.price <= filters.maxPrice)
      && (!filters.minPricePerSquareMeter || o.pricePerSquareMeter >= filters.minPricePerSquareMeter)
      && (!filters.maxPricePerSquareMeter || o.pricePerSquareMeter <= filters.maxPricePerSquareMeter)
    )
  }

  return (
    <Box
      display='flex'
      gap='.75rem'
      flexDirection='column'
    >
      <Typography
        variant='h1'
        fontFamily='AvenirHeavy'
        fontSize='1.125rem'
        color='rgb(32, 32, 32)'
        mb='.25rem'
      >
        {texts('report:generate_report_title')}
      </Typography>
      <Grid>
        <Typography
          variant='h2'
          color='rgba(32, 32, 32, 0.75)'
          fontSize='.875rem'
          mb='.5rem'
          textTransform='uppercase'
        >
          {texts('report:filter_offers_section_label')}
        </Typography>
        <Formik<FilterOfferListFormData>
          enableReinitialize
          initialValues={{}}
          onSubmit={handleFilter}
          validationSchema={filterOfferListValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <Grid
                display={'flex'}
                flexDirection={'row'}
                flexWrap={'wrap'}
                gap={'.5rem'}
              >
                <TextField
                  name={'minPrice'}
                  type={'number'}
                  value={formikProps.values.minPrice ?? ''}
                  onKeyDown={preventNonDigit}
                  sx={{ 
                    width: '12.5rem',
                    ...defaultFormTextFieldSx
                  }}
                  label={texts('report:min_price_label')}
                />
                <TextField
                  name={'maxPrice'}
                  type={'number'}
                  value={formikProps.values.maxPrice ?? ''}
                  onKeyDown={preventNonDigit}
                  sx={{ 
                    width: '12.5rem',
                    ...defaultFormTextFieldSx
                  }}
                  label={texts('report:max_price_label')}
                />
                <TextField
                  name={'minPricePerSquareMeter'}
                  type={'number'}
                  value={formikProps.values.minPricePerSquareMeter ?? ''}
                  onKeyDown={preventNonDigit}
                  sx={{ 
                    width: '12.5rem',
                    ...defaultFormTextFieldSx
                  }}
                  label={texts('report:min_price_per_square_meter_label')}
                />
                <TextField
                  name={'maxPricePerSquareMeter'}
                  type={'number'}
                  value={formikProps.values.maxPricePerSquareMeter ?? ''}
                  onKeyDown={preventNonDigit}
                  sx={{ 
                    width: '12.5rem',
                    ...defaultFormTextFieldSx
                  }}
                  label={texts('report:max_price_per_square_meter_label')}
                />

              </Grid>
              <Grid
                item
                container
                flexDirection={'row'}
                gap={'.75rem'}
                justifyContent={'flex-end'}
                sx={{ 
                  height: '2.25rem'
                }}
              >
                <Button
                  type={'submit'}
                  variant={'outlined'}
                  disableElevation
                  sx={{
                    border: '1px solid rgb(32, 32, 32)',
                    color: 'black',
                    textTransform: 'none',
                    width: '15.625rem',
                  }}
                >
                  <Typography fontSize={'.9375rem'} fontFamily={'AvenirMedium'}>
                    {texts('report:submit_filter_button_label')}
                  </Typography>
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid>
        <Grid
          container
          gap='3rem'
        >
          <Grid>
            <Typography
              variant='h2'
              color='rgba(32, 32, 32, 0.75)'
              fontSize='.875rem'
              mb='.5rem'
              textTransform='uppercase'
            >
              {texts('report:selected_offer_list')}
            </Typography>
            <Typography
              color='rgba(32, 32, 32, 0.75)'
              fontSize='.875rem'
              mb='1rem'
            >
              {texts('report:selected_count_label', { count: selectedRowIds.length })}
            </Typography>
          </Grid>
          <Button
            disableElevation
            variant={'outlined'}
            sx={{
              fontFamily: 'AvenirMedium',
              fontSize: '.9375rem',
              textTransform: 'none',
              height: '2.25rem',
              width: '15.625rem',
              color: 'rgb(2, 13, 50)',
              borderColor: 'rgb(2, 13, 50)'
            }}
            onClick={props.onBackToFormClick}
          >
            {texts('report:back_to_form')}
          </Button>
        </Grid>
        <DataGrid<string>
          apiRef={apiRef}
          rows={filteredRows}
          loading={selectedAdverts.isLoading}
          columns={offersListColumns}
          rowHeight={40}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={(num) => { setPageSize(num) }}
          rowsPerPageOptions={pageSizes}
          getRowId={(row) => row.uuid}
          pagination
          checkboxSelection
          selectionModel={selectedRowIds}
          onSelectedRowsChange={(rowIds) => { setSelectedRowIds(rowIds) }}
          onSortModelChange={() => {
            apiRef.current.setPage(0)
          }}
          disableColumnSelector 
          disableColumnPinning
          getRowHeight={() => 'auto'}
        />
      </Grid>
      <Grid
        item
        container
        flexDirection='row'
        gap='.75rem'
        justifyContent='flex-end'
        sx={{ 
          height: '2.25rem'
        }}
      >
        <Button
          variant='contained'
          disableElevation
          disabled={selectedRowIds.length === 0}
          sx={{
            color: 'white',
            textTransform: 'none',
            width: '15.625rem'
          }}
          onClick={() => {
            props.onSubmit(selectedRowIds)
          }}
        >
          <Typography fontSize='.9375rem' fontFamily='AvenirHeavy'>
            {texts('report:generate_button_label_label')}
          </Typography>
        </Button>
      </Grid>
    </Box>
  )
}
