import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { Form, Formik } from 'formik'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useCreateAccount from '../../hooks/data/useCreateAccount'
import useFetchPackages from '../../hooks/data/useFetchPackages'
import useFetchRoles from '../../hooks/data/useFetchRoles'
import { queryNames } from '../../hooks/queries'
import { CreateAccountFormData } from '../../types/form'
import { noPackageOption } from '../../utils/const'
import { mapPackageDtoIntoSelectOptions, mapRoleDtoIntoSelectOptions } from '../../utils/converters'
import { createAccountValidation } from '../../utils/validations/createAccountValidation'
import CustomDialog from '../CustomMui/CustomDialog/CustomDialog'
import FormikSelectField from '../CustomMui/Fields/FormikSelectField'
import TextField from '../CustomMui/Fields/TextField'
import Loading from '../CustomMui/Loading'

const initialValues: CreateAccountFormData = {
  name: '',
  email: '',
  roleUuid: '',
  packageUuid: null
}

type Props = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export function CreateAccountDialog(props: Props) {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const handleClose = () => props.onClose()

  const { mutate, isLoading } = useCreateAccount()

  const { data: roles, isError: isRolesError, isFetching: isRolesLoading } = useFetchRoles()
  const { data: packages, isError: isPackagesError, isFetching: isPackagesLoading } = useFetchPackages()

  const handleSubmit = (data: CreateAccountFormData) => {
    mutate({
      name: data.name,
      email: data.email,
      roleUuid: data.roleUuid,
      packageUuid: data.packageUuid !== noPackageOption.value ? data.packageUuid : null
    }, {
      onSuccess: () => {
        toast.success(texts('successes:sub_account_created'))
        queryClient.invalidateQueries([queryNames.fetchAdminAccountList])
        props.onSuccess()
        handleClose()
      }
    })
  }

  if (isRolesLoading || isPackagesLoading) {
    return <Loading />
  }

  if (isRolesError || isPackagesError) {
    return null
  }

  return (
    <CustomDialog
      title={texts('agency:create_access_title')}
      handleClose={handleClose}
      open={props.open} 
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
      >
        <Formik<CreateAccountFormData>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={createAccountValidation(texts)}
        >
          {(formikProps) => (
            <Form 
              style={{ 
                width: '25.5rem',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem'
              }}
            >
              <TextField
                name={'name'}
                label={texts('agency:name_input')}
              />
              <TextField
                name={'email'}
                label={texts('agency:email_input')}
              />
              <FormikSelectField
                name={'roleUuid'}
                label={texts('account:account_type')}
                options={mapRoleDtoIntoSelectOptions(roles ?? [])}
                inputSx={{
                  '.MuiInputBase-input': {
                    fontSize: '.875rem !important',
                    transform: 'translate(0, 1px) scale(1)'
                  },
                  '.MuiInputBase-root': {
                    height: '2.5rem'
                  },
                  '.MuiInputLabel-root': {
                    fontSize: '1rem !important',
                    lineHeight: '1.5rem'
                  },
                  '.MuiInputLabel-shrink': {
                    fontSize: '.75rem !important',
                    lineHeight: '1.75rem'
                  }
                }}
              />
              <FormikSelectField
                name={'packageUuid'}
                label={texts('account:packet')}
                options={mapPackageDtoIntoSelectOptions(packages?.packages ?? [])}
                inputSx={{
                  '.MuiInputBase-input': {
                    fontSize: '.875rem !important',
                    transform: 'translate(0, 1px) scale(1)'
                  },
                  '.MuiInputBase-root': {
                    height: '2.5rem'
                  },
                  '.MuiInputLabel-root': {
                    fontSize: '1rem !important',
                    lineHeight: '1.5rem'
                  },
                  '.MuiInputLabel-shrink': {
                    fontSize: '.75rem !important',
                    lineHeight: '1.75rem'
                  }
                }}
              />
              <Box 
                display={'flex'}
                height={'2.25rem'}
                gap={'.5rem'}
                marginTop={'.5rem'}
                justifyContent={'flex-end'}
              >
                <Button
                  variant={'outlined'}
                  onClick={() => handleClose()}
                  sx={{
                    color: 'rgb(2, 13, 50)',
                    borderColor: 'rgb(2, 13, 50)',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirMedium',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('agency:cancel')}
                </Button>
                <LoadingButton
                  type={'submit'}
                  variant={'contained'}
                  disableElevation
                  loading={isLoading}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  sx={{
                    color: 'white',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirHeavy',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('agency:create')}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CustomDialog>
  )
}