import { useQuery, useQueryClient } from 'react-query'
import AuthApi from '../../api/auth/AuthApi'
import { sessionCheck } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { queryNames } from '../queries'

const authApi = AuthApi.getInstance()

type Options = {
  refetchInterval?: number
  refetchIntervalInBackground?: boolean
  enabled?: boolean
}

export default function useSessionCheck(options: Options) {
  const queryClient = useQueryClient()
  const queryKey = [queryNames.sessionCheck]

  const query = useQuery(queryKey, async ({ signal }) => {
    queryClient.cancelQueries(queryKey)

    return authApi.sessionCheck({
      abortSignal: signal
    })
  }, {
    ...options,
    onError: () => {},
    onSuccess: (data) => {
      store.dispatch(sessionCheck(data))
    }
  })

  return query
}