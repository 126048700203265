import { Coordinates, ListOf } from '../../types/common'
import { MaxFloorNumber, FloorNumber, RoomNumber, RoleName } from '../../utils/const'

export enum ReportFlatAdditionalParam {
  KITCHEN,
  GARDEN,
  GARAGE,
  BALCONY,
  ELEVATOR,
  BASEMENT
}

export enum BuildingState {
  UNDEFINED,
  FOR_RENOVATION,
  FOR_FINISH,
  BAD,
  NOT_BAD,
  GOOD,
  EXCELLENT,
  NOT_SPECIFIED,
  UNFINISHED_OPEN,
  UNFINISHED_CLOSE,
  DEVELOPMENT,
  FOR_LIVING
}

export enum MarketType {
  UNDEFINED,
  PRIMARY,
  SECONDARY
}

export type GenerateReportFormData = {
  location: undefined | {
    point: {
      latitude: number
      longitude: number
    }
    town: string
    street: string
    streetNumber: string
    region: string
    subRegion?: string | null
    district?: string | null
    plusCode?: string | null
  } | null
  params: {
    area: number | undefined
    floor: FloorNumber | undefined
    rooms: RoomNumber | undefined
    maxFloor: MaxFloorNumber | undefined
    marketType?: MarketType
    includePollution: boolean
    additionalParams: ReportFlatAdditionalParam[]
    clientName: string | undefined | null
    minBuildYear: number | undefined
    maxBuildYear: number | undefined
    buildingState: BuildingState | undefined
  }
}

export type ReportProceedDto = {
  uuid: string
  inputParams: {
    area: number | undefined
    floor: FloorNumber | undefined
    rooms: RoomNumber | undefined
    maxFloor: MaxFloorNumber | undefined
    marketType?: MarketType
    includePollution: boolean
    additionalParams: ReportFlatAdditionalParam[]
    clientName: string | undefined | null
    minBuildYear: number | undefined
    maxBuildYear: number | undefined
    buildingState: BuildingState | undefined
  }
}

export type ReportDto = {
  fileUrl: string | null
  uuid: string
  location: {
    point: {
      latitude: number
      longitude: number
    }
    town: string
    street: string
    streetNumber: string
    region: string
    subRegion?: string | null
    district?: string | null
  }
  inputParams: {
    clientName?: string | null
    area: number
    floor?: number | null
    rooms?: number | null
    maxFloor?: number | null
    marketType?: MarketType | null
    includePollution: boolean
    additionalParams: ReportFlatAdditionalParam[]
    minBuildYear?: number
    maxBuildYear?: number
    buildingState?: BuildingState
  }
  user: {
    uuid: string
    name: string
    email: string
    roles: RoleDto[]
  }
  generatedAt: string
  removed: boolean
}

export type ReportListQueryDto = {
  from?: string
  to?: string
} & SearchQueryDto & BaseQueryDto

export type OrderedListQueryDto = {
  order: string
} & SearchQueryDto & BaseQueryDto

export type AccountListQueryDto = {
  status?: AccountStatus
} & OrderedListQueryDto

export type SearchQueryDto = {
  search?: string
}

export type BaseQueryDto = {
  limit?: number
  offset?: number
}
export type GenerateReportDto = {
  uuid: string
  selectedAdvertUuids: string[]
}

export type GenerateReportResponseDto = {
  success: boolean
  data: { 
    uuid: string
    fileUrl: string
  }
}

export type ContactDetailsDto = {
  email: string | null
  phone: string | null
  address: {
    firstAddressLine: string
    secondAddressLine: string | null
    town: string
    postalCode: string
  }
}

export type OrganizationInfoDto = {
  name: string
  uuid: string
  contactDetails: ContactDetailsDto | null
  logoUrl: string | null
  props: {
    report: {
      colors: [string, string, string, string, string, string],
      name?: string
    }
  }
}

export type OrganizationDetailsDto = {
  uuid: string
  info: OrganizationInfoDto
  currentPackage: {
    name: string
    availableUntil: number
    reportsLeft: number
    maxReportsCount: number
  } | null
}

export type OrganizationMemberDto = {
  uuid: string
  name: string
  email: string
  reportsCount: number
  lastActivity: string
  status: AccountStatus
  roles: RoleDto[]
}

export type EditAgencyDto = {
  contactDetails: ContactDetailsDto | null
  report: {
    name: string | null
    colors: string[]
  }
  removeLogo: boolean
  logo?: File
}

export type AccountDto = {
  uuid: string
  name: string
  email: string
  lastActivity: string | null
  status: AccountStatus
  reportsCount: number | null
  organization: {
    uuid: string
    name: string
    mainAccountsCount: number
    subAccountsCount: number
  }
  roles: RoleDto[]
  package: {
    expiredAt: string
    reportsCount: number
    maxReportsCount: number
  } 
}

export type RoleDto = {
  uuid: string
  name: RoleName
}

export type PackageDto = {
  uuid: string
  name: string
  durationDays: number
  maxReportsCount: number
}

export type CreateAccountDto = {
  email: string
  name: string
  roleUuid: string
  packageUuid?: string | null
}

export type UpdateAccountDto = {
  name?: string
  active?: boolean
}

export type EditMyAccountDto = {
  contactDetails?: {
    name: string | null
    email: string | null
    phone: string | null
  } | null
  password?: {
    old: string
    new: string
  } | null
}

export type UpdateAccountTypeDto = {
  uuid: string
}

export type UpdateOrgPackageDto = {
  packageUuid: string
}
//Lists:
export type OrganizationMemberListDto = ListOf<OrganizationMemberDto, 'accounts'>
export type ReportListDto = ListOf<ReportDto, 'reports'>
export type AccountListDto = ListOf<AccountDto, 'accounts'>
export type PackagesListDto = ListOf<PackageDto, 'packages'>

export enum AccountStatus {
  INACTIVE,
  WAITING_FOR_ACTIVATION,
  ACTIVE
}

export type SelectedAdvertDto = {
  uuid: string
  town: string
  street: string | null
  area: number
  rooms: number | null
  floor: number | null
  maxFloors: number | null
  price: number
  pricePerSquareMeter: number
  activeFor: number
  coordinates: Coordinates
  market: MarketType
  additionalParams: ReportFlatAdditionalParam[]
}

export type SelectedAdvertListDto = ListOf<SelectedAdvertDto, 'adverts'>