import { SxProps } from '@mui/material'

export const sectionTitle: SxProps = {
  fontSize: '.875rem',
  textTransform: 'uppercase',
  color: 'rgba(32, 32, 32, 0.75)',
  marginBottom: '.75rem'
}

export const loadingReportIcon: SxProps = {
  marginRight: '.5rem',
  marginTop: '-0.125rem',
  color: 'primary.main',
  width: '1.5rem',
  height: '1.5rem'
}

export const loadingReportPointLabel: SxProps = {
  fontSize: '.875rem',
  color: 'rgb(32, 32, 32)'
}