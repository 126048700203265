import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import NotFoundImage from '../../assets/images/NotFound.png'
import ErrorPage from './ErrorPage'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function NotFoundError() {
  const texts = useTranslation().t
  const navigate = useNavigate()

  useGaPageView('404')

  return (
    <ErrorPage
      imageSrc={NotFoundImage}
      title={texts('errors:page_not_found_title')}
      description={texts('errors:page_not_found_subtitle')}
      button={{
        label: texts('report:go_to_list'),
        onClick: () => navigate('/', { replace: true })
      }}
      sx={{
        img: {
          marginBottom: '2rem',
          width: '16.25rem',
          height: '15rem'
        }
      }}
    />
  )
}
