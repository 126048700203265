import * as Yup from 'yup'
import { passwordRules, englishCharactersAllowedPassword } from './validationRegexps'
import { TFunction, Namespace } from 'i18next'

export function settingsValidation(t: TFunction<Namespace>) {
  return Yup.object({
    oldPassword: Yup.string()
      .test(function (oldPassword: any, ctx: any) {
        let newPassword = this.resolve(Yup.ref('newPassword')) as string | null
        if (newPassword != null && newPassword !== '' && (oldPassword == null || oldPassword === '')) {
          return this.createError({
            message: t('validations:field_required'),
            path: ctx.path
          })
        }
        return true
      }),
    newPassword: Yup.string()
      .optional()
      .matches(englishCharactersAllowedPassword, t('validations:special_characters_password_validation'))
      .min(8, t('validations:pwd_min_8_signs_validation'))
      .matches(passwordRules, t('validations:password_rules_validation'))
      .max(32, t('validations:pwd_max_32_signs_validation'))
      .test(function (newPassword: any, ctx: any) {
          let oldPassword = this.resolve(Yup.ref('oldPassword')) as string | null
          if (oldPassword != null && oldPassword !== '' && (newPassword == null || newPassword === '')) {
            return this.createError({
              message: t('validations:field_required'),
              path: ctx.path
            })
          }
          return true
      }),
    reportName: Yup.string()
      .max(32, t('validations:max_32_signs_validation'))
      .nullable(),
    contactEmail: Yup.string()
      .max(32, t('validations:max_32_signs_validation'))
      .nullable(),
    contactPhone: Yup.string()
      .max(32, t('validations:max_32_signs_validation'))
      .nullable()
  })
}