export const queryNames = {
  fetchReportDetails: 'fetchReportDetails',
  fetchReportList: 'fetchReportList',
  fetchReportAdminList: 'fetchReportAdminList',
  fetchOrgDetails: 'fetchOrgDetails',
  fetchMembersList: 'fetchOrgMembers',
  fetchAdminAccountList: 'fetchAdminAccountList',
  fetchRoles: 'fetchRoles',
  fetchSelectedAdverts: 'fetchSelectedAdverts',
  fetchPackages: 'fetchPackages',
  sessionCheck: 'sessionCheck',
}
