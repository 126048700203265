import { LoadingButton } from '@mui/lab'
import { Box, Grid, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import PasswordField from '../../components/CustomMui/Fields/PasswordField'
import TextField from '../../components/CustomMui/Fields/TextField'
import useUpdateMyAccount from '../../hooks/data/useUpdateSettings'
import {
  getUserContactDetails,
  getUserEmail, getUserName, setUserContactDetails
} from '../../services/storage/StorageService'
import { sectionTitle } from '../../styles/settings'
import { AccountSettingsFormData } from '../../types/form'
import { settingsValidation } from '../../utils/validations/settingsValidation'
import toast from 'react-hot-toast'
import { isPremiumAccount } from '../../services/storage/StorageService'
import useGaPageView from '../../hooks/google/useGaPageView'

function getUserInfo(): AccountSettingsFormData {
  return {
    name: getUserName() ?? '',
    email: getUserEmail() ?? '',
    oldPassword: undefined,
    newPassword: undefined,
    reportName: getUserContactDetails()?.name ?? null,
    contactPhone: getUserContactDetails()?.phone ?? null,
    contactEmail: getUserContactDetails()?.email ?? null
  }
}

export default function AccountSettings() {
  const texts = useTranslation().t

  useGaPageView('MyAccount')

  const { mutate, isLoading } = useUpdateMyAccount()

  const handleSubmit = (data: AccountSettingsFormData, helpers: FormikHelpers<AccountSettingsFormData>) => {
    mutate({
      contactDetails: isPremiumAccount()
        ? {
            name: data.reportName ? data.reportName : null,
            email: data.contactEmail ? data.contactEmail : null,
            phone: data.contactPhone ? data.contactPhone : null
          } 
        : null,
      password: data.newPassword && data.oldPassword
        ? {
            old: data.oldPassword,
            new: data.newPassword
          }
        : null
    }, {
      onSuccess: () => {
        setUserContactDetails({
          email: data.contactEmail,
          phone: data.contactPhone,
          name: data.reportName
        })
        helpers.resetForm({
          values: {
            name: getUserName() ?? '',
            email: getUserEmail() ?? '',
            oldPassword: '',
            newPassword: '',
            reportName: data.reportName,
            contactPhone: data.contactPhone,
            contactEmail: data.contactEmail
          }
        })
        toast.success(texts('successes:my_account_updated'))
      },
      onError: () => {
        helpers.resetForm({
          values: {
            name: getUserName() ?? '',
            email: getUserEmail() ?? '',
            oldPassword: '',
            newPassword: '',
            reportName: data.reportName,
            contactPhone: data.contactPhone,
            contactEmail: data.contactEmail
          }
        })
      }
    })
  }

  return (
    <>
      <Box>
        <Typography
          variant={'h1'}
          color={'#202020'}
          fontFamily={'AvenirHeavy'}
          fontSize={'1.125rem'}
          mb={'1rem'}
        >
          {texts('account:my_account')}
        </Typography>
        <Formik<AccountSettingsFormData>
          initialValues={getUserInfo()}
          onSubmit={handleSubmit}
          validationSchema={settingsValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <Box width={'25.5rem'}>
                <Typography 
                  variant={'h2'} 
                  sx={sectionTitle}
                >
                  {texts('account:account_data')}
                </Typography>
                <Grid 
                  container 
                  item
                >
                  <TextField
                    name={'name'}
                    fullWidth
                    label={texts('account:account_name')}
                    disabled
                  />
                  <TextField
                    name={'email'}
                    type={'email'}
                    fullWidth
                    label={texts('account:account_mail')}
                    disabled
                  />
                </Grid>
                <Typography 
                  variant={'h2'} 
                  sx={sectionTitle}
                >
                  {texts('account:password_change')}
                </Typography>
                <Grid 
                  container 
                  item
                >
                  <PasswordField
                    name={'oldPassword'}
                    label={texts('account:old_password')}
                    autoComplete='off'
                    fullWidth
                  />
                  <PasswordField
                    name={'newPassword'}
                    label={texts('account:new_password')}
                    fullWidth
                    autoComplete='off'
                  />
                </Grid>
                <Typography 
                  variant={'h2'} 
                  sx={sectionTitle}
                >
                  {texts('account:contact_info')}
                </Typography>
                <Grid 
                  container 
                  item
                >
                  <TextField
                    name={'reportName'}
                    fullWidth
                    label={texts('account:report_name')}
                    disabled={!isPremiumAccount()} 
                  />
                  <TextField
                    name={'contactPhone'}
                    fullWidth
                    label={texts('account:contact_phone')}
                    disabled={!isPremiumAccount()}
                  />
                  <TextField
                    name={'contactEmail'}
                    fullWidth
                    label={texts('account:contact_email')}
                    disabled={!isPremiumAccount()}
                  />
                </Grid>
                <Box 
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'flex-end'}
                  marginTop={'16px'}
                >
                  <LoadingButton
                    loading={isLoading}
                    disableElevation
                    type={'submit'}
                    variant={'contained'}
                    disabled={!formikProps.isValid || !formikProps.dirty}
                    sx={{ 
                      width: '15.625rem',
                      height: '2.25rem',
                      fontSize: '.9375rem',
                      color: 'white',
                      textTransform: 'none',
                      lineHeight: '1.4'
                    }}
                    >
                    {texts('agency:save_changes')}
                  </LoadingButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box >
    </>
  )
}