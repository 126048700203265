import { useTranslation } from 'react-i18next'
import { SxProps } from '@mui/material'
import noDataImage from '../../../assets/images/NoReportsFound.svg'
import Box from '@mui/material/Box'
import NoData from '../../NoData/NoData'

type Props = {
  sx?: {
    container?: SxProps
    img?: SxProps
    label?: SxProps
  }
}

export default function NoDataGridPlaceholder (props: Props) {
  const texts = useTranslation().t
  return (
    <Box display={'flex'} sx={{ flexDirection: 'column' }}>
      <NoData
        imgSrc={noDataImage}
        mainText={texts('account:no_data_found')}
        subText={texts('account:no_data_found_subtext')}
        styles={{
          container: {
            marginTop: '1rem'
          },
          img: {
            width: '40px',
            height: '40px',
            marginBlock: '1rem'
          },
          mainText: {
            fontSize: '.75rem',
            color: 'rgba(32, 32, 32, 0.65)',
            letterSpacing: '0.11',
            lineHeight: '1.125rem'
          },
          subText: {
            fontSize: '.75rem',
            color: 'rgba(32, 32, 32, 0.65)',
            letterSpacing: '0.11',
            lineHeight: '1.125rem'
          }
        }}
      />
    </Box>
  )
}
