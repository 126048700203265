import { SxProps } from '@mui/material'

export const defaultFormTextFieldSx: SxProps = {
  '.MuiInputBase-input': {
    fontSize: '.875rem !important',
    transform: 'translate(0, 1px) scale(1)'
  },
  '.MuiInputBase-root': {
    height: '2.25rem'
  },
  '.MuiInputLabel-root': {
    fontSize: '.975rem !important',
    lineHeight: '1.25rem'
  },
  '.MuiInputLabel-shrink': {
    fontSize: '.75rem !important',
    lineHeight: '1.75rem'
  }
}