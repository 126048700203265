import { DeleteOutline, UploadRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Paper, Typography } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useFilePicker } from 'use-file-picker'
import { OrganizationDetailsDto } from '../../api/data/types'
import Logo from '../../assets/images/AuthIcon.svg'
import CustomColorPicker from '../../components/CustomColorPicker/CustomColorPicker'
import TextField from '../../components/CustomMui/Fields/TextField'
import Loading from '../../components/CustomMui/Loading'
import useFetchOrgDetails from '../../hooks/data/useFetchOrg'
import useUpdateOrganization from '../../hooks/data/useUpdateOrganization'
import { Page } from '../../services/errors/consts'
import { EditAgencyFormData } from '../../types/form'
import { defaultReportColors } from '../../utils/const'
import { showImageSelectErrorToast } from '../../utils/error'
import { editAgencyValidation } from '../../utils/validations/editAgencyValidation'
import ConnectionError from '../Errors/ConnectionError'
import useGaPageView from '../../hooks/google/useGaPageView'

function getContactData(data: OrganizationDetailsDto): EditAgencyFormData {
  return {
    reportName: data.info.props?.report.name ?? '',
    address: data.info.contactDetails?.address.firstAddressLine ?? '',
    contactPhone: data.info.contactDetails?.phone ?? '',
    contactEmail: data.info.contactDetails?.email ?? '',
    postalCode: data.info.contactDetails?.address.postalCode ?? '',
    town: data.info.contactDetails?.address.town ?? '',
    colors: data.info.props.report.colors ?? defaultReportColors
  }
}

export default function EditOrganization() {
  const texts = useTranslation().t

  useGaPageView('UpdateAgencyData')

  const { data, isFetching, isError } =  useFetchOrgDetails()
  const updateMutation = useUpdateOrganization(Page.EDIT_AGENCY)

  const formRef = useRef<FormikProps<EditAgencyFormData>>(null)

  const handleSubmit = (data: EditAgencyFormData) => {
    updateMutation.mutate({
      contactDetails: {
        email: data.contactEmail,
        phone: data.contactPhone,
        address: {
          firstAddressLine: data.address,
          secondAddressLine: null,
          town: data.town,
          postalCode: data.postalCode
        }
      },
      report: {
        name: data.reportName,
        colors: data.colors
      },
      removeLogo: resetLogo,
      ...(plainFiles.length > 0 ? {
        logo: plainFiles[0]
      } : {})
    }, {
      onSuccess: () => {
        toast.success(texts('successes:changes_saved'))
      }
    })
  }

  const [resetLogo, setResetLogo] = useState<boolean>(false)

  const [openFileSelector, { filesContent, plainFiles, clear, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['.png', '.jpg', '.jpeg', '.webp'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    imageSizeRestrictions: {
      minHeight: 30,
      minWidth: 115,
    },
    maxFileSize: Number.parseInt(process.env.REACT_APP_LOGO_FILE_MAX_SIZE_MB!)
  })

  const logo = useMemo(() => {
    if (filesContent[0] && !resetLogo) {
      return `${filesContent[0].content}`
    }

    return !resetLogo ? (data?.info.logoUrl ?? Logo) : Logo
  }, [data, filesContent, resetLogo])

  useEffect(() => {
    showImageSelectErrorToast(errors, texts)
  }, [errors, texts])

  // handles file uploaded "event" which filePicker does not fire
  useEffect(() => {
    if (plainFiles.length > 0) {
      setResetLogo(false)
    }
  }, [setResetLogo, plainFiles])

  useEffect(() => {
    if (data?.info?.props.report.colors) {
      formRef?.current?.setFieldValue('colors', data.info.props.report.colors)
    }
  }, [data])

  if (isError) {
    return <ConnectionError />
  }
  if (isFetching) {
    return <Loading />
  }

  return (
    <Box width={'100%'}>
      <Typography
        variant={'h1'}
        color={'#202020'}
        fontFamily={'AvenirHeavy'}
        fontSize={'1.125rem'}
        mb={'2rem'}
      >
        {texts('account:config_report')}
      </Typography>
      <Formik<EditAgencyFormData>
        initialValues={getContactData(data!)}
        innerRef={formRef}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={editAgencyValidation(texts)}
      >
        {(formikProps) => (
          <Form>
            <Box 
              display={'flex'}
              sx={{ flexDirection: 'column' }}
            >
              <Box 
                display={'flex'} 
                mb={'1rem'}
                sx={{ flexDirection: 'column' }}
              >
                <Typography
                  fontFamily={'AvenirHeavy'}
                  color={'rgba(32, 32, 32, 0.75)'}
                  fontSize={'14px'}
                >
                  {texts('agency:contact_info')}
                </Typography>
              </Box>
              <Box
                display={'flex'}
                width={'30%'}
                minWidth={'500px'}
                flexDirection={'column'}
              >
                <TextField
                  size='small'
                  name='reportName'
                  fullWidth
                  label={texts('agency:contact_name')}
                />
                <TextField
                  size='small'
                  name='address'
                  label={texts('agency:contact_address')}
                />
                <Box>
                  <TextField
                    size='small'
                    name='postalCode'
                    label={texts('agency:postal_code')}
                    sx={{ width: '39.5%' }}
                  />
                  <TextField
                    size='small'
                    name='town'
                    label={texts('agency:town')}
                    sx={{ width: '59.5%', marginLeft: '1%' }}
                  />
                </Box>
                <TextField
                  size='small'
                  name='contactPhone'
                  label={texts('agency:contact_number')}
                />
                <TextField
                  size='small'
                  name='contactEmail'
                  label={texts('agency:contact_email')}
                />
              </Box>
              <Box 
                display={'flex'} 
                mb={'1rem'}
                sx={{ flexDirection: 'column' }}
              >
                <Typography
                  fontFamily={'AvenirHeavy'}
                  color={'rgba(32, 32, 32, 0.75)'}
                  fontSize={'14px'}
                >
                  {texts('agency:logo')}
                </Typography>
              </Box>
              <Box
                gap={'1rem'}
                display={'flex'}
                mb={'1rem'}
              >
                <Paper
                  sx={{
                    display: 'flex',
                    width: 'fit-content',
                    maxWidth: '31%',
                    height: 'fit-content',
                    position: 'relative',
                    borderRadius: '0.25rem'
                  }}
                >
                  <Box
                    component={'img'}
                    alt={'logo'}
                    src={logo}
                    maxWidth={'100%'}
                    maxHeight={(data?.info.logoUrl == null && plainFiles.length === 0) || resetLogo ? '36px' : '56px'}
                    sx={{
                      borderRadius: '0.25rem',
                      margin: (data?.info.logoUrl == null && plainFiles.length === 0) || resetLogo ? '10px 33px' : '0'
                    }}
                  />
                </Paper>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'0.25rem'}
                >
                  <Button
                    variant={'outlined'}
                    sx={{
                      color: '#020D32',
                      borderColor: '#020D32',
                      width: '150px',
                      fontSize: '0.75rem',
                      padding: '3px 15px',
                      textTransform: 'unset',
                      fontFamily: 'AvenirMedium'
                    }}
                    onClick={() => openFileSelector()}
                  >
                    <UploadRounded 
                      fontSize={'small'} 
                      sx={{ marginRight: '0.5rem' }}
                    />
                    {texts('common:add_file')}
                  </Button>
                  <Button
                    variant={'outlined'}
                    sx={{
                      color: '#E02020',
                      borderColor: '#E02020',
                      width: '150px',
                      fontSize: '0.75rem',
                      padding: '3px 15px',
                      textTransform: 'unset',
                      display: data?.info.logoUrl == null && plainFiles.length === 0 ? 'none' : 'auto'
                    }}
                    onClick={() => {
                      if (plainFiles.length > 0) {
                        clear()
                      }
                      if (data?.info.logoUrl) {
                        setResetLogo(true)
                      }
                    }}
                  >
                    <DeleteOutline 
                      fontSize={'small'}
                      sx={{
                        marginRight: '0.5rem'
                      }}
                    />
                    {texts('common:remove_logo')}
                  </Button>
                </Box>
              </Box>
              <Box 
                display={'flex'} 
                sx={{ flexDirection: 'column' }}
                mb={'1rem'}
              >
                <Typography
                  fontFamily={'AvenirHeavy'}
                  color={'rgba(32, 32, 32, 0.75)'}
                  fontSize={'14px'}
                >
                  {texts('agency:colors')}
                </Typography>
              </Box>
              <Box 
                display={'flex'}
                gap={'12px'}
                mb={'1rem'}
              >
                <CustomColorPicker name={'colors[0]'}/>
                <CustomColorPicker name={'colors[1]'}/>
                <CustomColorPicker name={'colors[2]'}/>
                <CustomColorPicker name={'colors[3]'}/>
                <CustomColorPicker name={'colors[4]'}/>
                <CustomColorPicker name={'colors[5]'}/>
              </Box>
              <Box 
                display={'flex'}
                justifyContent={'flex-end'}
                width={'30%'}
                minWidth={'500px'}
              >
                <LoadingButton
                  loading={updateMutation.isLoading}
                  disableElevation
                  variant={'contained'}
                  type={'submit'}
                  disabled={!(formikProps.isValid && (formikProps.dirty || (resetLogo && data?.info.logoUrl) || plainFiles.length > 0)) }
                  sx={{
                    width: '15.625rem',
                    height: '2.25rem',
                    fontSize: '.9375rem',
                    color: 'white',
                    textTransform: 'none',
                    lineHeight: '1.4'
                  }}
                >
                  {texts('agency:save_changes')}
                </LoadingButton>
              </Box>
            </Box>
          </Form >
        )}
      </Formik>
    </Box>
  )
}