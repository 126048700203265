import { Grid, Typography } from '@mui/material'
import { ClipLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'

type Props = {
  color?: string
  backgroundColor?: string
  width?: string
  height?: string
  size?: number
}

export default function Loading(props: Props) {
  const texts = useTranslation().t

  return (
    <Grid
      container
      width={props.width ?? '100%'}
      height={props.height ?? 'calc(100vh - 160px)'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Grid
        item
        container
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'1.25rem'}
        my={'.5rem'}
      >
        <ClipLoader
          size={props.size ?? 60}
          speedMultiplier={0.75}
          color={props.color ?? '#000'}

        />
        <Typography 
          fontStyle={'AvenirBook'}
          fontSize={'2rem'}
          sx={{ color: '#000' }}
        >
          {texts('common:loading')}
        </Typography>
      </Grid>
    </Grid>
  )
}