import * as Yup from 'yup'
import { emailRegexp } from './validationRegexps'
import { TFunction, Namespace } from 'i18next'

export function createAccountValidation(t: TFunction<Namespace>) {
  return Yup.object({
    email: Yup.string()
      .matches(emailRegexp, t('validations:invalid_email_validation'))
      .required(t('validations:field_required')),
    name: Yup.string().required(t('validations:field_required'))
      .max(80, t('validations:max_80_signs_validation')),
    roleUuid: Yup.string().required(t('validations:field_required')),
    packageUuid: Yup.string().nullable().required(t('validations:field_required'))
  })
}