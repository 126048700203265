import { styled, Theme } from '@mui/material/styles'
import { DataGridPro as MuiDataGrid, DataGridProProps, GridLinkOperator } from '@mui/x-data-grid-pro'
import { JSXElementConstructor } from 'react'

const StyledDataGrid = styled(MuiDataGrid)(() => ({
  borderRadius: '.5rem',
  '.MuiDataGrid-root': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '.MuiDataGrid-cell': {
    fontSize: '.75rem',
    minHeight: '2.5rem !important',
    height: '2.5rem',
    '&:focus-within': { outline: 'none' }
  },
  '.MuiDataGrid-columnHeaderTitleContainer': {
    fontSize: '.875rem',
    fontFamily: 'AvenirMedium',
    color: 'rgba(255,255,255, 0.7)',
    paddingLeft: '.25rem'
  },
  '.MuiDataGrid-columnHeaderTitleContainer > .MuiDataGrid-iconButtonContainer > .MuiButtonBase-root': {
    paddingRight: 0
  },
  '.MuiDataGrid-filterIcon': {
    color: 'rgba(255,255,255, 0.7)'
  },
  '.MuiDataGrid-menuIconButton': {
    color: 'rgba(255,255,255, 0.7)'
  },
  '.MuiDataGrid-sortIcon': {
    color: 'rgba(255,255,255, 0.7)'
  },
  '.MuiDataGrid-virtualScroller': {
    marginTop: '2.75rem !important',
    minHeight: '25.125rem'
  },
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.75rem'
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: 'rgb(1, 13, 50)',
    borderTopLeftRadius: '.5rem',
    borderTopRightRadius: '.5rem',
    height: '2.75rem',
    minHeight: '2.75rem !important'
  },
  '.MuiDataGrid-columnHeader': {
    backgroundColor: 'rgb(1, 13, 50)',
    '&:focus-within': { outline: 'none' },
    '.MuiCheckbox-root': {
      '.MuiSvgIcon-root': {
        color: 'rgba(255,255,255, 0.7)'
      }
    }
  },
  '.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none'
  },
  '.MuiTablePagination-selectLabel': {
    fontSize: '0.875rem'
  },
  '.MuiDataGrid-main': {
    minHeight: '25rem'
  },
  '.MuiDataGrid-row': {
    minHeight: '2.5rem !important',
    height: '2.5rem'
  },
  '.MuiDataGrid-selectedRowCount': {
    display: 'none'
  },
  '.MuiDataGrid-footerContainer': {
    justifyContent: 'flex-end'
  },
}))

type Props<R> = {
  noRowsOverlay?: JSXElementConstructor<any>
  columnMenu?: JSXElementConstructor<any>
  onSelectedRowsChange?: (rowIds: R[]) => void
} & Omit<DataGridProProps, 'disableSelectionOnClick' | 'components'>

export const DataGrid = <R = string | number>(props: Props<R>) => {
  return (
    <StyledDataGrid
      components={{
        NoRowsOverlay: props.noRowsOverlay,
        ColumnMenu: props.columnMenu
      }}

      componentsProps={{
        panel: {
          placement: 'bottom-end'
        },
        filterPanel: {
          linkOperators: [GridLinkOperator.And],
          filterFormProps: {
            linkOperatorInputProps: {
              variant: 'outlined',
              size: 'small',
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' },
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small',
              },
            }
          },
          sx: {
            '& .MuiDataGrid-filterForm': { p: 2 },
            '& .MuiDataGrid-filterForm:nth-child(even)': {
              backgroundColor: (theme: Theme) =>
                theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
            },
            '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
            '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
            '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
            '& .MuiDataGrid-filterFormValueInput': { width: 200 },
          },
        },
      }}
      {...props}
      disableSelectionOnClick
      disableMultipleColumnsFiltering
      {
        ...(
          props.checkboxSelection
          ? {
              onSelectionModelChange: (selectionModel) => {
                props.onSelectedRowsChange?.(selectionModel as R[])
              }
            }
          : {}
        )
      }
    />
  )
}
