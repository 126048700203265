import { Typography } from '@mui/material'
import { ContactDetailsDto } from '../../api/data/types'

type Props = {
  name: string
  contactDetails: ContactDetailsDto
}

export default function ContactDetails(props: Props) {
  return (
    <>
      <Typography 
        variant={'h3'}
        fontFamily={'AvenirHeavy'}
        fontSize={'.875rem'}
        lineHeight={'1.25rem'}
        color={'#020D32'}
      >
        {props.name}
      </Typography>
      <Typography fontSize={'.875rem'}>
        {props.contactDetails.address.firstAddressLine} <br />
        {props.contactDetails.address.postalCode}, {props.contactDetails.address.town} <br />
      </Typography>
      {
        props.contactDetails.phone &&
        <Typography fontSize={'.875rem'}>
          {props.contactDetails.phone}
        </Typography>
      }
      {
        props.contactDetails.email &&
        <Typography fontSize={'.875rem'}>
          {props.contactDetails.email}
        </Typography>
      }
    </>
  )
}

