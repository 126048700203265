import { TextField, InputAdornment, SxProps, TextFieldProps, IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useField, useFormikContext } from 'formik'

type Props = {
  name: string
  disabled?: boolean
  label?: React.ReactNode
  placeholder?: string
  submitOnClear?: boolean
  sx?: SxProps
  size?: 'small' | 'medium'
  variant?: 'standard' | 'filled' | 'outlined'
}

export default function FormikSearchInput(props: Props) {
  const { setFieldValue, submitForm } = useFormikContext()
  const [field, meta] = useField(props.name)

  const configTextField: TextFieldProps = {
    ...field,
    name: props.name,
    fullWidth: true,
    variant: 'standard',
  }
  
  if (meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  const clearIcon = (
    <InputAdornment position='end'>
      <IconButton
        onClick={() => {
          setFieldValue(props.name, undefined)
          if (props.submitOnClear) {
            submitForm()
          }
        }}
        sx={{
          padding: 0
        }}
      >
        <ClearIcon
          sx={{
            '&:hover': {
              cursor: 'pointer',
              borderRadius: '50%',
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              animationDuration: '1s',
              animationTimingFunction: 'ease-in-out',
              animationName: 'rotateAnim'
            }
          }}
        />
      </IconButton>
    </InputAdornment>
  )

  return (
    <TextField
      {...configTextField}
      value={field.value ?? ''}
      disabled={props.disabled}
      label={props.label}
      size={props.size ?? 'small'}
      variant={props.variant ?? 'outlined'}
      sx={props.sx}
      InputProps={{
        placeholder: props.placeholder,
        endAdornment: field.value ? clearIcon : null,
        startAdornment: 
          <InputAdornment position={'start'} sx={{height: 5}}>
            <IconButton
              onClick={() => { submitForm() }}
              sx={{
                padding: 0
              }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
      }}
    />
  )
}
