import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { getUserUuid } from '../../services/storage/StorageService'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useFetchRoles() {
  const texts = useTranslation().t
  const query = useQuery([queryNames.fetchRoles, getUserUuid()], () => dataApi.getMainRoles(), {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    },
    staleTime: Infinity,
    refetchOnMount: 'always',
  })
  return query
}