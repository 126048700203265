import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthDto } from '../api/auth/types'
import { setUserData, clearUserCache, getUserUuid, setUserSessionCheckData } from '../services/storage/StorageService'

export enum LoginState {
  LOGGED,
  LOGGED_OUT,
  FORCED_LOGGED_OUT
}

const defaultState = {
  loginState: getUserUuid() != null ? LoginState.LOGGED : LoginState.LOGGED_OUT
}

const slice = createSlice({
  name: 'storage',
  initialState: defaultState,
  reducers: {
    logIn: (state, action: PayloadAction<AuthDto>) => {
      setUserData(action.payload)
      state.loginState = LoginState.LOGGED
    },
    sessionCheck: (state, action: PayloadAction<AuthDto>) => {
      setUserSessionCheckData(action.payload)
      state.loginState = LoginState.LOGGED
    },
    logOut: state => {
      clearUserCache()
      state.loginState = LoginState.LOGGED_OUT
    },
    forceLogOut: state => {
      clearUserCache()
      state.loginState = LoginState.FORCED_LOGGED_OUT
    }
  }
})

export const {
  logIn,
  logOut,
  sessionCheck,
  forceLogOut
} = slice.actions

export default slice.reducer