import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { Form, Formik } from 'formik'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useCreateAccount from '../../hooks/data/useCreateAccount'
import { queryNames } from '../../hooks/queries'
import { getUserOrgUuid, getUserUuid } from '../../services/storage/StorageService'
import { CreateSubAccountFormData } from '../../types/form'
import { createAccessValidation } from '../../utils/validations/createAccessValidation'
import CustomDialog from '../CustomMui/CustomDialog/CustomDialog'
import TextField from '../CustomMui/Fields/TextField'

type Props = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export default function CreateSubAccountDialog(props: Props) {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const handleClose = () => props.onClose()

  const { mutate, isLoading } = useCreateAccount()

  const handleSubmit = (data: CreateSubAccountFormData) => {
    mutate({
      ...data,
      packageUuid: null,
      roleUuid: process.env.REACT_APP_SUB_ACCOUNT_UUID!
    }, {
      onSuccess: () => {
        toast.success(texts('successes:sub_account_created'))
        queryClient.invalidateQueries([queryNames.fetchMembersList, getUserOrgUuid(), getUserUuid()])
        props.onSuccess()
        handleClose()
      }
    })
  }

  return (
    <CustomDialog
      title={texts('agency:create_access_title')}
      handleClose={handleClose}
      open={props.open} 
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
      >
        <Formik<CreateSubAccountFormData>
          initialValues={{ name: '', email: '' }}
          onSubmit={handleSubmit}
          validationSchema={createAccessValidation(texts)}
        >
          {(formikProps) => (
            <Form 
              style={{ 
                width: '25.5rem',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem',
              }}
            >
              <TextField
                name={'name'}
                label={texts('agency:name_input')}
              />
              <TextField
                name={'email'}
                label={texts('agency:email_input')}
              />
              <Box 
                display={'flex'}
                height={'2.25rem'}
                gap={'.5rem'}
                justifyContent={'flex-end'}
              >
                <Button
                  variant={'outlined'}
                  onClick={() => handleClose()}
                  sx={{
                    color: 'rgb(2, 13, 50)',
                    borderColor: 'rgb(2, 13, 50)',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirMedium',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('agency:cancel')}
                </Button>
                <LoadingButton
                  variant='contained'
                  type='submit'
                  disableElevation
                  loading={isLoading}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  sx={{
                    color: 'white',
                    fontSize: '.875rem',
                    fontFamily: 'AvenirHeavy',
                    textTransform: 'none',
                    width: '10rem',
                    height: '100%'
                  }}
                >
                  {texts('agency:create')}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CustomDialog>
  )
}