import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import AuthApi from '../../api/auth/AuthApi'
import { sessionCheck } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'

const authApi = AuthApi.getInstance()

export default function useFetchSessionData() {
  const texts = useTranslation().t

  const query = useMutation(async () => authApi.sessionCheck(), {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    },
    onSuccess: (data) => {
      store.dispatch(sessionCheck(data))
    }
  })

  return query
}