import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { GenerateReportDto } from '../../api/data/types'
import { Page } from '../../services/errors/consts'
import { getUserUuid } from '../../services/storage/StorageService'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'
import { queryNames } from '../queries'

const dataApi = DataApi.getInstance()

export default function useGenerateReport() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((data: GenerateReportDto) => dataApi.generateReport(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryNames.fetchReportList, getUserUuid()])
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts, Page.GENERATE_REPORT)
    },
  })

  return mutation
}