import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import DataApi from '../../api/data/DataApi'
import { CreateAccountDto } from '../../api/data/types'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/error'

const dataApi = DataApi.getInstance()

export default function useCreateAccount() {
  const texts = useTranslation().t
  const mutation = useMutation((data: CreateAccountDto) => dataApi.createAccount(data), {
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}