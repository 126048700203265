import { AppBar as MuiAppBar, Divider, Box, Avatar } from '@mui/material'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/AuthIcon.svg'
import Logout from '../../assets/images/Logout.svg'

type Props = {
  onLogoutClick: () => void
  userName: string
  userEmail: string
  onlyLogo: boolean
}

function toAvatarLogo(name: string): string {
  const words = name.split(' ')

  return words.length > 1
    ? `${words[0][0]}${words[1][0]}`.toUpperCase()
    : name[0].toUpperCase()
}

export default function AppBar(props: Props) {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const [anchorElUser, setAnchorElUser] = useState<EventTarget & HTMLButtonElement | null>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <MuiAppBar
      position='static'
      sx={{
        backgroundColor: '#FAFAFA',
        boxShadow: '0px 2px 10px 8px RGBA(0, 0, 0, 0.1)',
        maxHeight: '48px !important',
        minHeight: '48px !important',
        paddingRight: '8px'
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          maxHeight: '48px !important',
          minHeight: '48px !important'
        }}>
        <Box
          component={'img'}
          alt='logo'
          src={Logo}
          style={{
            width: '102px',
            height: '28px',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '32px',
            marginRight: 'auto',
            display: props.onlyLogo ? undefined : 'none'
          }} 
        />
        <Button
          onClick={handleOpenUserMenu}
          disableRipple
          sx={{
            height: '32px',
            border: 'none',
            marginLeft: 'auto',
            paddingTop: '0px',
            paddingBottom: '0px',
            minWidth: '0',
            width: 'fit-content',
            borderRadius: '0 !important'
          }}
        >
          <Avatar
            variant='square'
            sx={{
              backgroundColor: '#010D32',
              width: '36px',
              height: '36px',
              borderRadius: '6px',
            }}
          >
            <Typography
              fontSize={'16px'}
              letterSpacing={'2px'}
              textAlign={'center'}
              width={'100%'}
              paddingLeft={'2px'}
            >
              {props.userName != null && toAvatarLogo(props.userName)}
            </Typography> 
          </Avatar>
        </Button>
        <Menu
          PaperProps={{
            sx: {
              borderRadius: '10px'
            }
          }}
          sx={{
            mt: '2.813rem',
            mx: '1rem',
          }}
          id='menu-appbar-settings'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem 
            sx={{
              pointerEvents: 'none',
              ':hover': {
                cursor: 'default',
                backgroundColor: 'white'
              },
            }}
          >
            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
              <Typography
                variant='h2'
                noWrap
                sx={{
                  fontSize: '1rem',
                  textTransform: 'none'
                }}
              >
                {props.userName}
              </Typography>
              <Typography
                variant='body1'
                fontFamily={'AvenirMedium'}
                noWrap
                sx={{
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  color: 'rgba(32, 32, 32)',
                  opacity: '65%'
                }}
              >
                {props.userEmail}
              </Typography>
            </Box>
          </MenuItem>
          {!props.onlyLogo && <Divider sx={{ mx: '0.5rem' }} />}
          {
            !props.onlyLogo &&
            <MenuItem
              sx={{
                minWidth: '12rem',
              }}
              onClick={() => {
                navigate('/settings')
                handleCloseUserMenu()
              }}
              >
              <AccountCircleIcon />
              <Typography
                variant='body1'
                fontFamily={'AvenirMedium'}
                fontSize={'14px'}
                marginLeft={'0.313rem'}
                >
                {texts('common:settings')}
              </Typography>
            </MenuItem>
          }
          <Divider sx={{ mx: '0.5rem' }} />
          <MenuItem
            sx={{
              minWidth: '12rem'
            }}
            onClick={() => {
              props.onLogoutClick()
              handleCloseUserMenu()
            }}
          >
            <Box 
              component={'img'}
              src={Logout}
              height={'20px'}
              width={'20px'}
            />
            <Typography
              variant='body1'
              fontFamily={'AvenirMedium'}
              fontSize={'14px'}
              marginLeft={'0.313rem'}
            >
              {texts('common:logout')}
            </Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </MuiAppBar>
  )
}
