import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { forceLogOut } from '../redux/storageToolkit'
import store from '../redux/store'
import { AuthSubCodes, Codes, InternalSubcodes } from '../services/errors/consts'
import { SessionTokenDto } from './auth/types'

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}
export type BaseResponse = {
  success: 0 | 1
}
export type CustomAxiosRes<T = any> = {
  data: AxiosResponse<T>
  axiosRequestConfig: AxiosRequestConfig
}
export type AdditionalConfig = {
  originalUrl?: string
}

export abstract class Api {
  protected api: AxiosInstance
  protected refreshingToken: Promise<SessionTokenDto> | null = null

  public constructor (config: AxiosRequestConfig) {
    this.api = axios.create({
      ...config,
      timeout: 20 * 1000,
      withCredentials: true,
    })
    this.api.defaults.timeout = 240 * 1000 
    
    this.api.interceptors.response.use(
      this.handleResponse,
      this.handleError
    )
  }
  public getUri (config?: AxiosRequestConfig): string {
    return this.api.getUri(config)
  }
  public request<T, D = any> (config: AxiosRequestConfig<D> & AdditionalConfig): Promise<T> {
    return this.api.request(config)
  }
  public get<T, D = any> (url: string, config?: AxiosRequestConfig<D> & AdditionalConfig, manualTimeout: boolean = false): Promise<T> {
    if (manualTimeout) {
      const abort = axios.CancelToken.source()
      const id = setTimeout(
        () => abort.cancel(`Timeout of ${(config?.timeout ?? 240 * 1000) + (60 * 1000)} ms.`),
        (config?.timeout ?? 240 * 1000) + (60 * 1000)
      )
      return this.api.get(url, {
        ...config,
        cancelToken: abort.token
      }).then(response => {
        clearTimeout(id)
        return response
      }, (error) => {
        clearTimeout(id)
        throw error
      })
    }
    return this.api.get(url, config)
  }
  public delete<T, D = any> (url: string, config?: AxiosRequestConfig<D> & AdditionalConfig): Promise<T> {
    return this.api.delete(url, config)
  }
  public head<T, D = any> (url: string, config?: AxiosRequestConfig<D>): Promise<T> {
    return this.api.head(url, config)
  }
  public post<T, D = any> (url: string, data?: D, config?: AxiosRequestConfig<D> & AdditionalConfig, manualTimeout: boolean = false): Promise<T> {
    if (manualTimeout) {
      const abort = axios.CancelToken.source()
      const id = setTimeout(
        () => abort.cancel(`Timeout of ${(config?.timeout ?? 240 * 1000) + (60 * 1000)} ms.`),
        (config?.timeout ?? 240 * 1000) + (60 * 1000)
      )
      return this.api.post(url, data, {
        ...config,
        cancelToken: abort.token
      }).then(response => {
        clearTimeout(id)
        return response
      }, (error) => {
        clearTimeout(id)
        throw error
      })
    }
    return this.api.post(url, data, config)
  }
  public put<T, D = any> (url: string, data?: D, config?: AxiosRequestConfig<D> & AdditionalConfig): Promise<T> {
    return this.api.put(url, data, config)
  }
  public patch<T, D = any> (url: string, data?: D, config?: AxiosRequestConfig<D> & AdditionalConfig): Promise<T> {
    return this.api.patch(url, data, config)
  }

  protected refreshToken(originalUrl?: string): Promise<SessionTokenDto> {
    return this.post<SessionTokenDto>('/refresh', undefined, {
      baseURL: `${process.env.REACT_APP_BASE_AUTH}/api/v1/auth/account`,
      originalUrl: originalUrl
    })
  }

  private handleError = (error: any): Promise<Error> => {
    if (this.isForceLogout(error)) {
      store.dispatch(forceLogOut())
      error.response.data.code = Codes.INTERNAL
      error.response.data.subcode = InternalSubcodes.FORCED_LOGOUT
    }
    if (this.shouldRefreshToken(error)) {
      return this.handleRefreshToken(error)
    }
    return Promise.reject(error)
  }
  private handleResponse = ({ data }: AxiosResponse) => data

  private async handleRefreshToken(e: any) {
    const config: AxiosRequestConfig & { _retry?: boolean, originalUrl?: string } = e.config
    config._retry = true
    try {
      this.refreshingToken = this.refreshingToken ?? this.refreshToken(config.url)
      await this.refreshingToken
      return this.api(config)
    } catch(e) {
      return Promise.reject(e)
    } finally {
      this.refreshingToken = null
    }
  }

  private shouldRefreshToken(e: any) {
    const httpStatus = e.response?.data.httpStatus
    const code = e.response?.data?.code
    const subcode = e.response?.data?.subcode
    const config: AxiosRequestConfig & { _retry: boolean } = e.config
    return httpStatus === 401 && code === Codes.AUTH && subcode === AuthSubCodes.UNAUTHORIZED && !config._retry
  }

  private isForceLogout = (e: any): boolean => {
    const config: AxiosRequestConfig & { _retry?: boolean, originalUrl?: string } = e.config
    const httpStatus = e.response?.data.httpStatus
    const code = e.response?.data?.code
    const subcode = e.response?.data?.subcode
    if ( config.originalUrl !== '/account/session/check' 
      && httpStatus === 401
      && code === Codes.AUTH 
      && (((subcode === AuthSubCodes.USER_INACTIVE || subcode === AuthSubCodes.ACCOUNT_REMOVED) && !config.url?.includes('login'))
        || (subcode === AuthSubCodes.UNAUTHORIZED && config.url?.includes('refresh')))
    ) {
      return true
    } else {
      return false
    }
  }
}